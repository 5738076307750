import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import moment from 'moment'
import { adminActions } from '../../../../store/actions';
import { toast } from 'react-toastify';
import '../Menu.css'
import { getMaxMintCount } from '../../../../Utils/utils';
import { configPath } from '../../../../config';
import { adminConstants } from '../../../../constants';

function AddEvents(props) {


    const navigate = useNavigate() // page redirection 
    const dispatch = useDispatch() //dispatch action

    //define states
    const [name, setName] = useState('')
    const [desc, setDesc] = useState('')
    const [submitted, setSubmitted] = useState(false)
    const [onstart, setOnstart] = useState('')
    const [endat, setEndat] = useState('')
    const [venueAddress, setVenueAddress] = useState('')
    const [googleMapLink, setGoogleMapLink] = useState('')
    const [appleMapLink, setAppleMapLink] = useState('')

    //get states from store
    const addedEvent = useSelector(state => state.adminreducer.addEvent)
    const eventDetail = useSelector(state => state.adminreducer.eventDetail)
    const updatedEvent = useSelector(state => state.adminreducer.updatedEvent)


    const gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }



    //successfully created/updated slot
    //navigate to slots page

    useEffect(() => {
        if (addedEvent && addedEvent.statusCode === 201) {
            dispatch(adminActions.resetAddEvent())
            navigate('/creatoradmin/events')
        }
        if (updatedEvent && updatedEvent.statusCode === 200) {
            dispatch(adminActions.resetupdateEvent())
            navigate('/creatoradmin/events')
        }

    })
    //componentDidMount()
    useEffect(() => {
        gotoTop()
        if ((props && props.params && props.params.id)) {
            dispatch(adminActions.getEventDetail((props.params.id)))
        }
    }, [])

    useEffect(() => {
        //update state when Edit
        if (eventDetail && eventDetail.statusCode === 200) {
            dispatch(adminActions.resetGetEventDetail())
            if (eventDetail && eventDetail.data && eventDetail.data.event) {
            
                //update other states
                setName(eventDetail.data.event.name && eventDetail.data.event.name)
                setOnstart(eventDetail.data.event.startsAt && moment(eventDetail.data.event.startsAt).format('YYYY-MM-DD'))
                setEndat(eventDetail.data.event.endsAt && moment(eventDetail.data.event.endsAt).format('YYYY-MM-DD'))
                setDesc(eventDetail.data.event.description && eventDetail.data.event.description)
                setGoogleMapLink(eventDetail.data.event.googleMapLink && eventDetail.data.event.googleMapLink)
                setAppleMapLink(eventDetail.data.event.appleMapLink && eventDetail.data.event.appleMapLink)
                setVenueAddress(eventDetail.data.event.venueAddress && eventDetail.data.event.venueAddress)
            }

        }
    }, [eventDetail])




    const gotoBack = () => {
        navigate(-1)
    }
    const onFullNameChange = (e) => {
        setName(e.target.value)
    }
    const onDescChange = (e) => {
        setDesc(e.target.value)
    }

    const checkname = (value) => {
        if ((value && value.length) > 100) {
            return true
        } else {
            return false
        }
    }
    const checkFullName = (value) => {
        if (((value && value.length) <= 100) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }
    const checkDesc = (value) => {
        if (((value && value.length) <= 250) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }
    const validNumber = (value) => {
        if (isNaN(value)) {
            return false
        } else {
            return true
        }
    }

    const handleOnstart = (e) => {
        setOnstart(e.target.value)
    }
    const handleEndTime = (e) => {
        setEndat(e.target.value)
    }


    const checkValidDate = () => {
        if (new Date(endat) > new Date(onstart)) {
            return true
        } else {
            return false
        }
    }
    const checkmaxInput = (value) => {
        if ((value && value.length) <= 100) {
            return true
        } else {
            return false
        }
    }
    const checkInput = (value) => {
        if ((value && value.length) >= 3) {
            return true
        } else {
            return false
        }
    }
    const checkMaxDescInput = (value) => {
        if ((value && value.length) <= 250) {
            return true
        } else {
            return false
        }
    }
    const validURL = (str) => {
        var re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

        if (re.test(str)) {
            return true
        } else {
            return false
        }
    }
    const checkLength = (value) => {
        if (((value && value.length) <= 120) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }
    const validLinkLength = (value) => {
        if (((value && value.length) <= 120)) {
            return true
        } else {
            return false
        }
    }
    const handleLinkChanges = (e) => {
        setGoogleMapLink(e.target.value)
    }
    const handleappleLinkChanges = (e) => {
        setAppleMapLink(e.target.value)
    }
    const onAddresschange = (e) => {
        setVenueAddress(e.target.value)
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)

        if (name && checkFullName(name)) {
            if ( desc && checkDesc(desc)) {
                if (onstart) {
                    if (endat) {
                        if (checkValidDate()) {
                            if (venueAddress && checkDesc(venueAddress)) {

                                if (googleMapLink && validURL(googleMapLink)) {
                                    if (appleMapLink && validURL(appleMapLink)) {


                                        let obj = {
                                            name,
                                            startsAt: moment(onstart).toISOString(),
                                            endsAt: moment(endat).toISOString(),
                                            venueAddress : venueAddress,
                                            googleMapLink : googleMapLink,
                                            appleMapLink : appleMapLink,
                                            description : desc
                                        }
                                        if (props && props.params && props.params.id) {
                                            obj["id"] = Number(props && props.params && props.params.id)
                                            dispatch(adminActions.updateEvent((JSON.stringify(obj))))
                                        } else {
                                            dispatch(adminActions.addEvents((JSON.stringify(obj))))
                                        }


                                    } else {
                                        toast.error("Please provide your valid apple map link")
                                    }
                                } else {
                                    toast.error("Please provide your valid google map link")

                                }
                            } else {
                                toast.error("Venue address must be contain atlease 3 characters and atmost 250 characters long")
                            }

                        } else {
                            toast.error("End date and time should be greater than start date and time")
                        }
                    } else {
                        toast.error("Please select end date and time")
                    }
                } else {
                    toast.error("Please select start date and time")
                }
            } else {
                toast.error("Description must be contain atlease 3 characters and atmost 250 characters long")
            }

        } else {
            toast.error("Event name mandatory and should be atleast 3 characters and atmost 100 characters")
        }
    }

    return (
        <Fragment>
            <div className="smallilac px-3">
                <div className='ca-card mb-3 '>
                    <div className="row admintitle align-items-center">
                        <div className="col-sm-6 "><h2 className="font-weight-bold">
                            {(props && props.params && props.params.id) ? "Edit Event" : "Add Event"}</h2>  </div>
                        <div className="col-sm-6 text-right"><button className=" btn btn-outline-primary" type="button" onClick={gotoBack}><i className="fas fa-angle-left"></i> Back</button>  </div>
                    </div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className='ca-card mb-3 '>
                        <div className="row">
                            <div className="form-group col-md-4 mb-4">
                                <label htmlFor="name " className="font-weight-bold" >Event name &nbsp;*</label>
                                <input type="text" className={checkname(name) ? "form-control descdan" : "form-control"} id="name" aria-describedby="name" placeholder="Enter event name" value={name} onChange={onFullNameChange} />
                                {<div className="mandatory-req small">{"Min 3 characters and max 100 characters"}</div>}
                                {submitted && !name && <div className="mandatory small">{"Name required"}</div>}
                                {submitted && name && !checkInput(name) && <div className="mandatory small">{"Name must be at least 3 characters"}</div>}
                                {submitted && name && !checkmaxInput(name) && <div className="mandatory small">Name should be no more than 100 characters</div>}
                            </div>
                            <div className="form-group col-md-8 mb-4">
                                <label htmlFor="desc " className="font-weight-bold" >Description &nbsp;*</label>
                                <textarea className="form-control textareaexapand" id="desc" aria-describedby="desc" placeholder="Enter event description" value={desc} onChange={onDescChange} />
                                {<div className="mandatory-req small">{"Min 3 characters and max 250 characters"}</div>}
                                {submitted && !desc && <div className="mandatory small">{"Description required"}</div>}
                                {submitted && desc && !checkInput(desc) && <div className="mandatory small">{"Description must be at least 3 characters"}</div>}
                                {submitted && desc && !checkMaxDescInput(desc) && <div className="mandatory small">Description should be no more than 250 characters</div>}

                            </div>
                            <div className="form-group col-md-6 mb-4">
                                <div className="">
                                    <label className="font-weight-bold" htmlFor="onstart" >Starts from&nbsp;*</label>

                                    <input id="onstart" name="onstart" type="date" value={onstart} onChange={handleOnstart} className="form-control" min={moment().format('YYYY-MM-DD')} />
                                    {submitted && !onstart && <div className="mandatory mt-2 small">{"Please select your event start date"}</div>}
                                </div>
                            </div>
                            <div className="form-group col-md-6 mb-4">
                                <div className="">
                                    <label className="font-weight-bold" htmlFor="endat" >Ends at&nbsp;*</label>

                                    <input id="endat" name="endat" type="date" value={endat} onChange={handleEndTime} className="form-control" min={moment(onstart).format('YYYY-MM-DD')} disabled={!onstart} />
                                    {submitted && !endat && <div className="mandatory mt-2 small">{"Please select your event end date"}</div>}
                                </div>
                            </div>

                           
                            <div className="form-group col-md-12 mb-4">
                                <label htmlFor="venueAddress " className="font-weight-bold" >Venue Address &nbsp;*</label>
                                <textarea className="form-control textareaexapand" id="venueAddress" aria-describedby="venueAddress" placeholder="Enter venue address" value={venueAddress} onChange={onAddresschange} />
                                {<div className="mandatory-req small">{"Min 3 characters and max 250 characters"}</div>}
                                {submitted && !venueAddress && <div className="mandatory small">{"Venue address required"}</div>}
                                {submitted && venueAddress && !checkInput(venueAddress) && <div className="mandatory small">{"Address must be at least 3 characters"}</div>}
                                {submitted && venueAddress && !checkMaxDescInput(venueAddress) && <div className="mandatory small">Address should be no more than 250 characters</div>}

                            </div>
                            <div className="form-group col-md-6 mb-4">
                                <label htmlFor="googleMapLink " className="font-weight-bold" >Google map link&nbsp;*</label>
                                <input className={"form-control"} value={googleMapLink} name="googleMapLink" onChange={handleLinkChanges} placeholder="Enter google map link" />
                                {submitted && googleMapLink && !validURL(googleMapLink) && <div className="mandatory">Please provide a proper link</div>}
                                {<div className="mandatory-req">Ex: https://goo.gl/maps/14UkaD1xUKVRQRHt6</div>}

                                {/* {submitted && googleMapLink && validURL(googleMapLink) && !checkLength(this.state.masterpiece) && <div className="mandatory">{"Max 120 characters"}</div>} */}
                                {/* {!submitted && googleMapLink && validURL(googleMapLink) && !checkLength(this.state.masterpiece) && <div className="mandatory-req">{"Max 120 characters"}</div>} */}

                            </div>
                            <div className="form-group col-md-6 mb-4">
                                <label htmlFor="appleMapLink " className="font-weight-bold" >Apple map link&nbsp;*</label>
                                <input className={"form-control"} value={appleMapLink} name="appleMapLink" onChange={handleappleLinkChanges} placeholder="Enter apple map link" />
                                {submitted && appleMapLink && !validURL(appleMapLink) && <div className="mandatory">Please provide a proper link</div>}
                                {<div className="mandatory-req">Ex: https://goo.gl/maps/14UkaD1xUKVRQRHt6</div>}

                                {/* {submitted && googleMapLink && validURL(googleMapLink) && !checkLength(this.state.masterpiece) && <div className="mandatory">{"Max 120 characters"}</div>} */}
                                {/* {!submitted && googleMapLink && validURL(googleMapLink) && !checkLength(this.state.masterpiece) && <div className="mandatory-req">{"Max 120 characters"}</div>} */}

                            </div>
                            {<div className="form-group col-md-12 text-right">
                                <hr />
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>}
                        </div>
                    </div>

                </form>

            </div>

        </Fragment>
    )

}

export { AddEvents }