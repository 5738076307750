import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";


export async function purchaseTokenss(listingResourceID, storefrontAddress, expectedPrice) {

  console.log("purchase tokenns");
  console.log(listingResourceID);
    console.log(storefrontAddress);

const script1 =`//v31
import FungibleToken from 0xf233dcee88fe0abe
import NonFungibleToken from 0x1d7e57aa55817448
import DapperUtilityCoin from 0xead892083b3e2c6c
import NFTStorefront from 0x4eb8a10cb9f87357
import DisruptArt from 0xcd946ef9b13804c6
import MetadataViews from 0x1d7e57aa55817448

// This transaction purchases an NFT on a peer-to-peer marketplace (i.e. **not** directly from a dapp). This transaction
// will also initialize the buyer's NFT collection on their account if it has not already been initialized.
transaction(dapperAddress: Address, storefrontAddress: Address, listingResourceID: UInt64, expectedPrice: UFix64) {
    let paymentVault: @FungibleToken.Vault
    let nftCollection: &DisruptArt.Collection{NonFungibleToken.Receiver}
    let storefront: &NFTStorefront.Storefront{NFTStorefront.StorefrontPublic}
    let listing: &NFTStorefront.Listing{NFTStorefront.ListingPublic}
    let salePrice: UFix64
    let balanceBeforeTransfer: UFix64
    let mainDapperUtilityCoinVault: &DapperUtilityCoin.Vault

    prepare(dapper: AuthAccount, buyer: AuthAccount) {
        // Initialize the buyer's collection if they do not already have one
        if buyer.borrow<&DisruptArt.Collection>(from: DisruptArt.disruptArtStoragePath) == nil {
            let collection <- DisruptArt.createEmptyCollection() as! @DisruptArt.Collection
            buyer.save(<-collection, to: DisruptArt.disruptArtStoragePath)
            buyer.link<&DisruptArt.Collection{NonFungibleToken.CollectionPublic, MetadataViews.ResolverCollection, DisruptArt.DisruptArtCollectionPublic}>(
                DisruptArt.disruptArtPublicPath,
                target: DisruptArt.disruptArtStoragePath,
            )
        }

        // Get the storefront reference from the seller
        self.storefront = getAccount(storefrontAddress)
            .getCapability<&NFTStorefront.Storefront{NFTStorefront.StorefrontPublic}>(
                NFTStorefront.StorefrontPublicPath
            )!
            .borrow()
            ?? panic("Could not borrow Storefront from provided address")

        // Get the listing by ID from the storefront
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No Offer with that ID in Storefront")
        self.salePrice = self.listing.getDetails().salePrice

        // Get a DUC vault from Dapper's account
        self.mainDapperUtilityCoinVault = dapper.borrow<&DapperUtilityCoin.Vault>(from: /storage/dapperUtilityCoinVault)
            ?? panic("Cannot borrow DapperUtilityCoin vault from account storage")
        self.balanceBeforeTransfer = self.mainDapperUtilityCoinVault.balance
        self.paymentVault <- self.mainDapperUtilityCoinVault.withdraw(amount: self.salePrice)

        // Get the collection from the buyer so the NFT can be deposited into it
        self.nftCollection = buyer.borrow<&DisruptArt.Collection{NonFungibleToken.Receiver}>(
            from: DisruptArt.disruptArtStoragePath
        ) ?? panic("Cannot borrow NFT collection receiver from account")
    }

    // Check that the price is right
    pre {
        self.salePrice == expectedPrice: "unexpected price"
    }

    execute {
        let item <- self.listing.purchase(
            payment: <-self.paymentVault
        )

        self.nftCollection.deposit(token: <-item)

        // Remove listing-related information from the storefront since the listing has been purchased.
        self.storefront.cleanup(listingResourceID: listingResourceID)
    }

    // Check that all dapperUtilityCoin was routed back to Dapper
    post {
        self.mainDapperUtilityCoinVault.balance == self.balanceBeforeTransfer: "DapperUtilityCoin leakage"
    }
}`;


    console.log("-===================================-");
    console.log(script1);
    console.log("-===================================-");

    try {
        const blockResponse = await fcl.send([
            fcl.getBlock(),
        ])
        const block = blockResponse.block;
        const blockHeight = block && block.height;
  
   /*
 
         const res =  await fcl.mutate({
           cadence: script1,
           args: (arg, t) => [arg(listingResourceID, t.UInt64),arg(storefrontAddress, t.Address),arg("1.0", t.UFix64)],
           proposer: fcl.currentUser().authorization
          // authorizations: [fcl.currentUser().authorization, fcl.currentUser().authorization],
           // limit:1000
        })

        console.log(block);
        console.log(res);
        var updateTxStatus = await fcl.tx(res).onceSealed()
        console.log(updateTxStatus)
     */
      
     const res = await fcl.send([
            fcl.transaction(script1),
            fcl.args([
                fcl.arg("0x0feb0de15fff8a89", t.Address),
                fcl.arg("0x95cca0eeb03dbfff", t.Address),
                fcl.arg(listingResourceID, t.UInt64),
                fcl.arg("150.0", t.UFix64),
            ]),
            fcl.proposer(fcl.currentUser().authorization),
            fcl.authorizations([
               fcl.currentUser().authorization, fcl.currentUser().authorization
            ]),
            fcl.payer(fcl.currentUser().authorization),
            fcl.ref(blockResponse["block"].id),
            fcl.limit(9999),
        ]) 

        console.log(res)
       

        const { transactionId } = res;

        console.log(transactionId);

        const result = new Promise((resolve, reject) => {
            fcl.tx(transactionId)
                .subscribe((transaction) => {
                    if (fcl.tx.isSealed(transaction)) {
                        const {errorMessage} = transaction;
                        if(errorMessage && errorMessage !== ''){
                            reject({
                                status: 400,
                                data:{
                                    "message": "Transaction failed",
                                    "Error": String(errorMessage)
                                }
                            })
                        }
                        resolve({
                            status: 200,
                            data: {
                                "message": "Disrupt Transfer Raised",
                                "transactionId": transactionId,
                                "blockHeight": blockHeight,
                                "transaction": transaction
                            }
                        });
                    }
                });
        });
        return result;
    }
    catch (e) {
        console.error('Failed to move tokens',e);
        return {
            status: 400,
            data: { "message": "Exception happens", "Error": String(e) }
        };
    }
}
