import React from "react";
import { useNavigate } from 'react-router'

function SuccessPayment() {
    const navigate = useNavigate()
    const gotoHome = () => {
        navigate('/')
    }
    return (
        <div>
            <div className="container text-center pagenotfound py-5">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-12 text-center">
                            <div className="babpsuccess">
                                <div class="success-checkmark">
                                    <div class="check-icon">
                                        <span class="icon-line line-tip"></span>
                                        <span class="icon-line line-long"></span>
                                        <div class="icon-circle"></div>
                                        <div class="icon-fix"></div>
                                    </div>
                                </div>
                                <h4 class="display-5 d-block my-3">Thank you for buying Badazz Bears.</h4>
                                <button className="btn btn-primary px-5 mt-3" type="button" onClick={gotoHome}>Back to Home</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export { SuccessPayment }