import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { adminActions, babActions } from '../../../../store/actions';

function Settings() {

    const dispatch = useDispatch() //dispatch action
    const settings = useSelector(state => state.adminreducer.getSettings)
    const updateSetting = useSelector(state => state.adminreducer.updateSettings)
    const [isChecked,setIsChecked] = useState(false)
    const [callBack,setCallBack] = useState(false)
    useEffect(() => {
        dispatch(adminActions.getSettings())
    },[])

    useEffect(() => {
        if((settings && settings.statusCode) === 200){
            dispatch(adminActions.resetSettings())
            if(settings && settings.data && settings.data.settings){
                if(settings.data.settings.is_private === "true"){
                    setIsChecked(true)
                }else{
                    setIsChecked(false)
                }
            }
        }
    },[settings])
    const handleChecked = () => {
        setCallBack(true)
        if(isChecked){
            setIsChecked(false)
        }else{
            setIsChecked(true)
        }
    }
    useEffect(() => {
        if(callBack){
            updateSettings()
            setCallBack(false)
        }else{
            return;
        }
    },[callBack])
    useEffect(() => {
        if((updateSetting && updateSetting.statusCode) === 200){
            dispatch(adminActions.resetUpdateSettings())
            toast.success("Settings updated")
            dispatch(adminActions.getSettings())
        }
    },[updateSetting])
    const updateSettings = () => {
        let obj = {
            is_private: isChecked.toString(),
            purchase_limit : '20'
        }
        dispatch(adminActions.updateSettings(JSON.stringify(obj)))
    }
    return (
        <Fragment>
            <div className="smallilac px-3">
                <div className='ca-card mb-3 '>
                    <div className="row admintitle align-items-center">
                        <div className="col-md-6"><h2 className="font-weight-bold">Settings </h2></div>
                        
                    </div>
                </div>
                <div className="ca-card mb-3">
                    <div className="card-body">
                    
                        <div className="row justify-content-center">

                            <div className="col-md-4" >
                            <div className="form-group my-5">
                                <input type="checkbox" className="form-check-input mr-3" id="exampleCheck1" checked={isChecked} onClick={handleChecked} />
                                <label className="form-check-label" htmlFor="exampleCheck1">Bearcave Waitlist emails only</label>
                            </div>
                            </div>

                     
                        
                        
                        </div>
                    </div>
                </div>


            </div>

        </Fragment>
    )

}


export { Settings }