import React, { Component, Fragment } from 'react';

function Bears() {
    return (
        <Fragment>
            <div className="collection orangebg">
                <div className="container-fluid">
                    <div className="bb-page-title">
                        <h1>The Badazz Behind The Bears</h1>
                    </div>

                    <div class="bab-speakers-list">
    <div class="row">
        <div class="col-sm-6 col-md-3">
            <div class="bab-speaker">
                <div class="bab-propic"><img src="/assets/img/David-Bianchi.png" alt="" class="mw-100" /></div>
                <div class="bab-proname">jimjonescapo</div>
                <div class="bab-prodetail">Leader of the Bears & rapping Badazz</div>
            </div>
        </div>
        <div class="col-sm-6 col-md-3">
            <div class="bab-speaker">
                <div class="bab-propic"><img src="/assets/img/Tal-Navarro.png" alt="" class="mw-100" /></div>
                <div class="bab-proname">darklarcs</div>
                <div class="bab-prodetail">Artist behind the Bears & expert on iguanas</div>
            </div>
        </div>
        <div class="col-sm-6 col-md-3">
            <div class="bab-speaker">
                <div class="bab-propic"><img src="/assets/img/Jessica-Greenwalt.png" alt="" class="mw-100" /></div>
                <div class="bab-proname">RobforOHIO</div>
                <div class="bab-prodetail">Full-time Disruptor & CEO of Disrupt Art</div>
            </div>
        </div>
        <div class="col-sm-6 col-md-3">
            <div class="bab-speaker">
                <div class="bab-propic"><img src="/assets/img/Kimbo.png" alt="" class="mw-100" /></div>
                <div class="bab-proname">Evan Erpenbeck</div>
                <div class="bab-prodetail">Is a long established fact that a reader will be distracted</div>
            </div>
        </div>
        <div class="col-sm-6 col-md-3">
            <div class="bab-speaker">
                <div class="bab-propic"><img src="/assets/img/Mayor-Aftab-Pureval.png" alt="" class="mw-100" /></div>
                <div class="bab-proname">Jason Martin</div>
                <div class="bab-prodetail">Full Stack Developer-CTO Co founder of Disrupt Art</div>
            </div>
        </div>
        <div class="col-sm-6 col-md-3">
            <div class="bab-speaker">
                <div class="bab-propic"><img src="/assets/img/ian.png" alt="" class="mw-100" /></div>
                <div class="bab-proname">Name</div>
                <div class="bab-prodetail">Is a long established fact that a reader will be distracted</div>
            </div>
        </div>
        <div class="col-sm-6 col-md-3">
            <div class="bab-speaker">
                <div class="bab-propic"></div>
                <div class="bab-proname">Name</div>
                <div class="bab-prodetail">Is a long established fact that a reader will be distracted</div>
            </div>
        </div>
        <div class="col-sm-6 col-md-3">
            <div class="bab-speaker">
                <div class="bab-propic"><img src="/assets/img/Charlie-Shrem.png" alt="" class="mw-100" /></div>
                <div class="bab-proname">Name</div>
                <div class="bab-prodetail">Is a long established fact that a reader will be distracted</div>
            </div>
        </div>
    </div>
   
</div>


                </div>
            </div>
        </Fragment>
    )
}
export { Bears }