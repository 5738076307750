import React, { Component, Fragment } from 'react'
import { AdminHeader } from '../Header';
import { Sidebar } from '../Sidebar.js';

class AdminLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }
    openSidebar = () => {
        this.setState({ open: !this.state.open })
    }
    
    render() {
        const { children } = this.props
        return (
            <Fragment>
                <div className="creatoradmin">
                    <AdminHeader openSidebar={this.openSidebar}/>
                    <main className="content-not-shift">{children}</main>
                    <Sidebar open={this.state.open}/>
                </div>
            </Fragment>
        )
    }
}

export { AdminLayout }