export const adminConstants = {
    GET_ALL_ATTRIBUTES : 'GET_ALL_ATTRIBUTES',
    ADMIN_AUTH : 'ADMIN_AUTH',
    ADMIN_AUTH_FAILED : 'ADMIN_AUTH_FAILED',
    ADMIN_LOGOUT : 'ADMIN_LOGOUT',
    ADMIN_GET_SLOTS : 'ADMIN_GET_SLOTS',
    ADMIN_ADD_SLOTS : 'ADMIN_ADD_SLOTS',
    ADMIN_GET_SLOT_DETAIL : 'ADMIN_GET_SLOT_DETAIL',
    ADMIN_UPDATE_SLOT : 'ADMIN_UPDATE_SLOT',
    ADMIN_FREEZE_SLOT : 'ADMIN_FREEZE_SLOT',
    ADMIN_FILTER_ATTRIBUTES : 'ADMIN_FILTER_ATTRIBUTES',
    ADMIN_UPDATE_ATTRIBUTES : 'ADMIN_UPDATE_ATTRIBUTES',
    ADMIN_GET_ARTS : 'ADMIN_GET_ARTS',
    ADMIN_GET_SETTINGS : 'ADMIN_GET_SETTINGS',
    ADMIN_PUT_SETTINGS : 'ADMIN_PUT_SETTINGS',
    ADMIN_REJECT_ARTS : 'ADMIN_REJECT_ARTS',
    ADMIN_UNREJECT_ARTS : 'ADMIN_UNREJECT_ARTS',
    ADMIN_ADD_WAITLIST_EMAIL : 'ADMIN_ADD_WAITLIST_EMAIL',
    ADMIN_ADD_WAITLIST_EMAIL_FAILED : 'ADMIN_ADD_WAITLIST_EMAIL_FAILED',
    ADMIN_GET_WAITLIST_COUNT : 'ADMIN_GET_WAITLIST_COUNT',
    ADMIN_GET_SOLD_COUNT : 'ADMIN_GET_SOLD_COUNT',
    ADMIN_BEAR_VALIDATION : 'ADMIN_BEAR_VALIDATION',
    ADMIN_BEAR_VALIDATION_FAILED : 'ADMIN_BEAR_VALIDATION_FAILED',
    ADMIN_SEND_BEAR_TOADDRESS : 'ADMIN_SEND_BEAR_TOADDRESS',
    ADMIN_SEND_BEAR_FAILED : 'ADMIN_SEND_BEAR_FAILED',
    ADMIN_BEAR_VERIFICATION : 'ADMIN_BEAR_VERIFICATION',
    ADMIN_BEAR_VERIFICATION_FAILED : 'ADMIN_BEAR_VERIFICATION_FAILED',
    ADMIN_GET_EVENTS : 'ADMIN_GET_EVENTS',
    ADMIN_ADD_EVENTS : 'ADMIN_ADD_EVENTS',
    ADMIN_GET_EVENT_DETAIL : 'ADMIN_GET_EVENT_DETAIL',
    ADMIN_UPDATE_EVENT : 'ADMIN_UPDATE_EVENT',
    ADMIN_ADD_EVENT_GALLERY : 'ADMIN_ADD_EVENT_GALLERY',
    ADMIN_GET_EVENT_GALLERY : 'ADMIN_GET_EVENT_GALLERY',
    ADMIN_DELETE_EVENT_GALLERY : 'ADMIN_DELETE_EVENT_GALLERY'
}