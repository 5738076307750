
import React, { Fragment, useEffect, useState } from 'react'
import {
    ComposedChart, Bar, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line, PieChart, Pie, Cell
} from 'recharts';
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from '../../../../store/actions';
import { configPath } from '../../../../config';

const data = [
    {
        "name": "12/05/22",
        "sale Count": 20,
        "pv": 20,
        "amount": 19.9
    },
    {
        "name": "13/05/22",
        "sale Count": 30,
        "pv": 98,
        "amount": 10
    },
    {
        "name": "14/05/22",
        "sale Count": 10,
        "pv": 90,
        "amount": 22.9
    },
    {
        "name": "16/05/22",
        "sale Count": 30,
        "pv": 30,
        "amount": 5
    },
    {
        "name": "17/05/22",
        "sale Count": 50,
        "pv": 48,
        "amount": 102.5
    },
    {
        "name": "18/05/22",
        "sale Count": 10,
        "pv": 38,
        "amount": 0.1
    },
    {
        "name": "19/05/22",
        "sale Count": 15,
        "pv": 43,
        "amount": 30
    }
]
const data01 = [
    {
        "name": "Slot 1",
        "value": 400
    },
    {
        "name": "Slot 2",
        "value": 300
    },
    {
        "name": "Slot 3",
        "value": 300
    },
    {
        "name": "Slot 4",
        "value": 200
    },
    {
        "name": "Slot 5",
        "value": 278
    },

];
const COLORS = ['#cc7700', '#b88038', '#FFBB28', '#FF8042', '#937b5c'];

function Dashboard() {

    const [adminAddress, setAdminAddress] = useState('0xa0a4e8975f98cbdd')
    const [soldcount,setSoldCount] = useState(0)
    const [callBack, setCallBack] = useState(false)
    const [options, setOptions] = useState([{slot : "Select",value:0}])
    const [slotType, setSlotType] = useState(1)
    const [slots, setSlots] = useState([]);

    const handleRefresh = () => { }

    const dispatch = useDispatch() //dispatch action
    const count = useSelector(state => state.adminreducer.soldCount)
    const getSlots = useSelector(state => state.adminreducer.slots)


    useEffect(() => {
        let obj = {}
        dispatch(adminActions.getSlots(obj,(configPath.pfpId)))
    },[])
    const fetchData = () => {
        dispatch(adminActions.getSoldCount((configPath.pfpId),(slotType)))
    }
    useEffect(() => {
        if (callBack) {
            fetchData()
            setCallBack(false)
        } else {
            return;
        }
    }, [callBack])

    useEffect(() => {
        if((count && count.statusCode) === 200){
            dispatch(adminActions.resetSoldCount())
            setSoldCount(count && count.data && count.data.soldArts)
        }
    },[count])

    const handleSlotDropDown = (e) => {
        setSlotType(e.target.value)
        setCallBack(true)
    }
    const slotDropdown = options.map((filType) => {
        return <option value={filType.value}>{filType.slot}</option>
    });
    useEffect(() => {
        let array = []
        if(getSlots && getSlots.statusCode === 200){
            dispatch(adminActions.resetGetSlot())
            fetchData()
            if(getSlots && getSlots.data && getSlots.data.slots){
                setSlots(getSlots.data.slots)
                getSlots.data.slots && getSlots.data.slots.map(item => {
                    let obj = {
                        slot : item && item.name,
                        value : item && item.id
                    }
                    array.push(obj)
                })
                setOptions(array)
            }
        }
    },[getSlots])

    return (
        <Fragment>

            <div className="smallilac px-3">
                <div className='ca-card mb-3 '>
                    <div className="row admintitle align-items-center">
                        <div className="col-sm-6"><h2 className="font-weight-bold">Dashboard</h2>  </div>
                        <div className="form-group mb-0 col-md-6">
                            <div className='ca-lablerow'>
                            <label htmlFor="name " className="font-weight-bold mb-0" for="name">Slots</label>
                            <select className="form-control" value={slotType} onChange={handleSlotDropDown}>
                                {slotDropdown}
                            </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">


                    <div className="col-md-6 text-center">
                        <div className="ca-card dash-card mb-3 ">
                            <p>Reserved Count</p>
                            <h4 className="usernamelilac text-primary mt-2">{"22"}</h4>
                            
                        </div>
                        
                    </div>
                    <div className="col-md-6 text-center">
                        <div className="ca-card dash-card mb-3 ">
                            <p>VIP Count</p>
                            <h4 className="usernamelilac text-primary mt-2">{5}</h4>
                        </div>
                    </div>
                    <div className="col-md-6 text-center">
                        <div className="ca-card dash-card mb-3 ">
                            <p>Celebrity Count</p>
                            <h4 className="usernamelilac text-primary mt-2">{17}</h4>
                        </div>
                    </div>
                    <div className="col-md-6 text-center">
                        <div className="ca-card dash-card mb-3 ">
                            <p>Sold Count</p>
                            <h4 className="usernamelilac text-primary mt-2">{soldcount}</h4>
                        </div>
                    </div>
                    {/* <div className="col-md-4">
                        
                        <div className='ca-card mb-3 '>
                            <h5 className="" >Slots <span className="small">vs</span> Mint count</h5>
                            <div className="mt-5 d-flex align-items-center justify-content-center">
                                <PieChart width={330} height={300}>

                                    <Pie data={data01} color="#000000" dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={120} fill="#8884d8" innerRadius={50} label wrapperStyle={{ bottom: 15 }}>
                                        {
                                            data01.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                        }
                                    </Pie>

                                    <Legend width={330} />

                                    <Tooltip />


                                </PieChart>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="col-md-8">
                        <div className='ca-card mb-3 '>
                            <h5 className="mb-4" >Purchased at <span className="small">vs</span> Sale count <span className="small">vs</span> Sale amount</h5>
                            <div className="my-5 d-flex align-items-center justify-content-center">
                                <ComposedChart width={830} height={350} data={data}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <CartesianGrid stroke="#f5f5f5" />
                                    <Area type="monotone" dataKey="sale Count" fill="#cc7700" stroke="#cc7700" />
                                    <Line type="monotone" dataKey="amount" stroke="#000000" />
                                </ComposedChart>
                            </div>
                        </div>
                    </div> */}

                </div>

            </div>

        </Fragment>
    )


}

export { Dashboard }