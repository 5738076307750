import React from "react";
import moment from 'moment';
import { Container } from "react-bootstrap";
import { SiDiscord } from "react-icons/si";
import { FaTwitter, FaInstagram } from "react-icons/fa";
import FtLogo from "../../Assets/images/dev-icon.png";
import DisruptLogo from "../../Assets/images/disrupt_logo.png";
import Flow from "../../Assets/images/onflow.png";
import Dapper from "../../Assets/images/dapper_logo.png";
import Perma from "../../Assets/images/pinata.png";
import Blaze from "../../Assets/images/blaze-logo.png";
import "./Footer.css";

const Footer = () => {
      const currentYear = moment().year()
      return (
            <footer>
                  <div className="footer_content">
                        <Container>
                              <div className="ft_logo">
                                    {/* <a href={`${process.env.REACT_APP_BASENAME}/home`}> */}
                                    <a href="https://disrupt.art" target={'_blank'}>
                                          <img src={FtLogo} alt="" />
                                    </a>
                              </div>
                              <div className="buttons">
                                    <div className="social_media">
                                          <ul>
                                                <li>
                                                      <a href="https://twitter.com/badazz_bears?s=21&t=NZEXVmWNZROom1NLfnZLxw" target={'_blank'}>
                                                            <FaTwitter />
                                                      </a>
                                                </li>
                                                <li>
                                                      <a href="https://instagram.com/badazzbears?igshid=YmMyMTA2M2Y=" target={'_blank'}>
                                                            <FaInstagram />
                                                      </a>
                                                </li>
                                                <li>
                                                      <a href="https://discord.io/badazzbears" target={'_blank'}>
                                                            <SiDiscord />
                                                      </a>
                                                </li>
                                          </ul>
                                    </div>
                                    {/* <div className="join_button">
                                          <a href={`${process.env.REACT_APP_BASENAME}/bearcave`}>Join Bear Cave</a>
                                    </div> */}
                              </div>
                              <div className="copy_rights">

                                    <div className="text">
                                          <p>Copyright © 2021 - 2022 All Rights Reserved by DISRUPT ART, INC.  | <a href="https://disrupt.art/nft/terms/" target="_blank">Terms &amp; Conditions</a> | <a href="https://disrupt.art/nft/privacy/" target="_blank">Privacy Policy</a> </p>
                                    </div>
                                    
                                    {/* <div className="img">
                                          <a href="https://disrupt.art">
                                                <img src={DisruptLogo} alt="" />
                                          </a>
                                    </div> */}

                                    <div className="walletlinks">
                                          <ul className="footer-links builtwallet">
                                                <li><a target="_blank" href="https://www.onflow.org/"><img src={Flow} alt="" className="onflowicon" /> Built on Flow</a></li>
                                                <li><a target="_blank" href="https://www.meetdapper.com/"><img src={Dapper} alt="" className="onflowicon" /> Dapper Wallet</a></li>
                                                <li><a target="_blank" href="https://www.pinata.cloud/"><img src={Perma} alt="" className="onflowicon" /> Pinata</a></li>
                                                <li className="developlink"><a target="_blank" href="https://blaze.ws/"><img src={Blaze} alt="" className="onflowicon" /> Developed at 
                                                <span className="devlotxt"> BLA<span className="text-danger">Z</span>E</span></a></li></ul>

                                    </div>

                              </div>
                        </Container>
                  </div>
            </footer>
      );
}

export default Footer;