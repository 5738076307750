import React, { Component, Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from '../../../../store/actions';
import { configPath } from '../../../../config';
import { ReactPagination } from '../../../../helpers/paginate';
import moment from 'moment'
import PubSub from 'pubsub-js';
import { adminServices } from '../../../../services';
import { toast } from 'react-toastify';


function FetchPurchasers() {

    const navigate = useNavigate()
    const dispatch = useDispatch() //dispatch action
    const getAllArts = useSelector(state => state.adminreducer.getArts)
    const getSlots = useSelector(state => state.adminreducer.slots)

    const [slotType, setSlotType] = useState(1)
    const [options, setOptions] = useState([{slot : "Select",value:0}])
    const [artsList, setArtsList] = useState([]);
    const [offSet, setOffSet] = useState(0)
    const [recordLimit,setRecordLimit] = useState(40)
    const [pageCount,setPageCount] = useState('')
    const [callBack, setCallBack] = useState(false)
    const [slots, setSlots] = useState([]);
    

    useEffect(() => {
     
        setOptions(options)
        fetchAllPurchasers()
        let obj = {}
        dispatch(adminActions.getSlots(obj,(configPath.pfpId)))

    }, [])
    useEffect(() => {
        if((getAllArts && getAllArts.statusCode) === 200){
            dispatch(adminActions.resetGetArts())
            if(getAllArts.data && getAllArts.data.arts){
                setPageCount(getAllArts && getAllArts.data && getAllArts.data.count / recordLimit)
                setArtsList(getAllArts.data.arts)
            }
        }
    })
    const fetchAllPurchasers = () => {
        let obj = {
            offSet: offSet,
            recordLimit : recordLimit,
            // paymentDetail:true,
            sortBy : "id",
            sortOrder : "ASC",
            isPurchased : true,
            isOwnerEmail : true
        }
        dispatch(adminActions.getAllArts((configPath.pfpId),(slotType),obj))
    }
    const handleSlotDropDown = (e) => {
        setSlotType(e.target.value)
        setCallBack(true)
        setOffSet(0)
    }
    const onPageChange = (data) => {
        let offSet = recordLimit * (data && data.selected)
        setOffSet(offSet)
        setCallBack(true)
    }
    useEffect(() => {
        if (callBack) {
            fetchAllPurchasers()
            setCallBack(false)
        } else {
            return;
        }
    }, [callBack])
    useEffect(() => {
        let array = []
        if(getSlots && getSlots.statusCode === 200){
            dispatch(adminActions.resetGetSlot())
            if(getSlots && getSlots.data && getSlots.data.slots){
                // setSlotCount(getSlots.data && getSlots.data.count)
                setSlots(getSlots.data.slots)
                getSlots.data.slots && getSlots.data.slots.map(item => {
                    let obj = {
                        slot : item && item.name,
                        value : item && item.id
                    }
                    array.push(obj)
                })
                setOptions(array)
            }
        }
    },[getSlots])
    const slotDropdown = options.map((filType) => {
        return <option value={filType.value}>{filType.slot}</option>
    });
    const purchaseStatus = (items) => {
        if(((items && items.isPurchased === 1) && (items.ownerEmail && items.ownerEmail != ''))){
            return "Purchased"
        }else if((items && items.isPurchased === 1)){
            return "Reserved"
        }else{
            return "Active"
        }
    }
    const showEmail = (items) => {
        if(((items.ownerEmail && items.ownerEmail != ''))){
            // const email = items.ownerEmail 
            // const firstEmail = email && email.split(',')
            // if(firstEmail && firstEmail.length > 1){
            //     return (firstEmail.shift() + ",")
            // }else{
                return items.ownerEmail
            // }
        }else{
            return '-'
        }
    }
    const showPurchasedAt = (items) => {
        if(((items.updatedAt))){
            
            return (moment(items.updatedAt).format('MMM/DD/YYYY hh:mm:ss A'))
        }else{
            return '-'
        }
    }
    const showPrice = (items) => {
        if(((items && items.isPurchased === 1) && (items.ownerEmail && items.ownerEmail != '') && (items.purchasedDate))){
            return '$154.79'
        }else{
            return '-'
        }
    }
    function showLoader() {
        PubSub.publish('msg', true);
    }
    
    function hideLoader() {
        PubSub.publish('msg', false);
    }
    const downloadPurchasers = () => {
        showLoader()
        adminServices.exportPurchaseArts((configPath.pfpId),slotType).then(res => {
            if((res && res.ok) && (res.status && res.status === 200)){
            res && res.text().then(text => {
                const url = window.URL.createObjectURL(
                    new Blob([text]),
                  );
                
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute(
                    'download',
                    `purchasers slot ${slotType}.csv`,
                  );
              
                  // Append to html link element page
                  document.body.appendChild(link);
              
                  // Start download
                  link.click();
              
                  // Clean up and remove the link
                  link.parentNode.removeChild(link);
            
            })
        }else{
            toast.error(res && res.statusText)
        }
            hideLoader()
        }).catch(e => {
            hideLoader()
            toast.error(e)
        }) 
    }
    const getModeOfPurchase = (items) => {
        if((items && items.purchaseMode && items.purchaseMode === 1)){
            return 'Celebrity'
        }else if((items && items.purchaseMode && items.purchaseMode === 2)){
            return 'VIP'
        }else if((items && items.purchaseMode && items.purchaseMode === 3)){
            return 'Give away'
        }else if((items && items.purchaseMode && items.purchaseMode === 4)){
            return 'Admin transfer'
        }else if((items && items.purchaseMode && items.purchaseMode === 5)){
            return 'Stripe'
        }else if((items && items.purchaseMode && items.purchaseMode === 6)){
            return 'Test'
        }else{
            return 'Dapper'
        }
    }
    
    const AccountList = artsList && artsList.map((items) => {
        
        return <tr>
            <td>{items && items.id} </td>
            <td>{items && items.tokenId }</td>
            <td>{showEmail(items)}</td>
            <td>{items && items.ownerWalletAddress}</td>
            <td>{getModeOfPurchase(items)}</td>
            <td>{showPurchasedAt(items)}</td>

        </tr>
        
    })
    return (
        <Fragment>
            <div className="smallilac px-3">
                <div className='ca-card mb-3 '>
                    <div className="row admintitle align-items-center">
                        <div className="col-md-4"><h2 className="font-weight-bold">Purchased Arts</h2></div>
                        
                        

                        <div className="form-group mb-0 col-md-6">
                            <div className='ca-lablerow'>
                            <label htmlFor="name " className="font-weight-bold mb-0" for="name">Slots</label>
                            <select className="form-control" value={slotType} onChange={handleSlotDropDown}>
                                {slotDropdown}
                            </select>
                            </div>
                        </div>
                        <div className="form-group mb-0 col-md-2">
                            <div className='ca-lablerow'>
                            <button className='btn btn-primary' type='button' onClick={downloadPurchasers} disabled={(artsList && artsList.length == 0)}>Export</button>
                            </div>
                        </div>
                        {/* <div className="form-group mb-0 col-md-4">
                            <div className='ca-lablerow'>
                            <label htmlFor="name " className="font-weight-bold mb-0" for="name">Slots</label>
                            <select className="form-control" value={slotType} onChange={handleSlotDropDown}>
                                <option>Purchased</option>
                                <option>Active</option>
                                <option>Reserved</option>
                            </select>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className='ca-card'>
                    <div className="table-responsive">
                        <table className="table table-bordered ca-table-custom">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Token Id</th>
                                    <th>Purchased by</th>
                                    <th>Owner address</th>
                                    <th>Purchase Mode</th>
                                    <th>Purchased at</th>
                                </tr>
                            </thead>
                            <tbody>
                                {AccountList}
                            </tbody>
                        </table>
                        {(artsList && artsList.length > 0) ?  '': <div className="col-md-12 text-center">
                                        No Arts
                                    </div>}
                    </div>
                </div>
                {artsList && artsList.length > 0  &&  <ReactPagination pageDetails={{ pageCount: pageCount, perPage: recordLimit, onPageChange }} />}

            </div>
        </Fragment>
    )

}


export { FetchPurchasers }