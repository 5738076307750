import React, { Component, Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router'
import Select from 'react-select';
import { toast } from 'react-toastify';
import { configPath } from '../../../../config';
import { adminActions } from '../../../../store/actions';


function Attributes() {

    const navigate = useNavigate()
    const dispatch = useDispatch() //dispatch action
    const [selectType, setSelectType] = useState([])
    const [options, setOptions] = useState([])
    const [filteredArrayAttr,setFilteredArrayAttr] = useState([])
    const allTypes = [];

    //get attributes from response
    const attributes = useSelector(state => state.adminreducer.attributes);
    const filteredAttributes = useSelector(state => state.adminreducer.filteredAttr);
    const updateFilter = useSelector(state => state.adminreducer.updateAttr)

    useEffect(() => {
        dispatch(adminActions.getAllAttributes(configPath.pfpId))
        
    }, [])
    //fetch all attributes
    useEffect(() => {
        if (attributes && attributes.statusCode === 200) {
            dispatch(adminActions.getFilterAttribute(configPath.pfpId))
            dispatch(adminActions.resetAllAttributes())
            const allAttributes = (attributes && attributes.data && attributes.data.attributes)
            allAttributes && allAttributes.length > 0 && allAttributes.map(items => {
                allTypes.push({
                    label: items && items.name,
                    value: items && items.id
                })
                setOptions(allTypes)

            })
        }
    }, [attributes])
    useEffect(() => {
        if(filteredAttributes && filteredAttributes.statusCode === 200){
            dispatch(adminActions.resetGetFilteredAttribute())
            const filteredArray = filteredAttributes.data && filteredAttributes.data.attributeIds&& filteredAttributes.data.attributeIds.split(',')
            const attrFiltered = options.filter((el) => {
                return filteredArray && filteredArray.length > 0 && filteredArray.some((f) => {
                  return Number(f) === el.value ;
                });
              });
              setFilteredArrayAttr(attrFiltered)
        }
    })
    useEffect(() => {
        if((updateFilter && updateFilter.statusCode === 200)){
            toast.success("Attribute filter updated")
            setSelectType([])
            dispatch(adminActions.resetUpdateFilterAttribute())
            dispatch(adminActions.getFilterAttribute(configPath.pfpId))

        }
    },[updateFilter])

    const handleSlotDropDown = (option) => {
        setSelectType(option)
    }
    const submitAttributes = (e) => {
        e.preventDefault()
        let attributeIds = []
        selectType && selectType.length > 0 && selectType.map(el => {
            attributeIds.push(el.value)
        })
        let obj = {
            attributeIds
        }
        if(selectType && selectType.length === 3){
            dispatch(adminActions.updateFilterAttribute((configPath.pfpId),(JSON.stringify(obj))))
        }else{
            toast.error("Must be select 3 attributes")
        }
        console.log(attributeIds)
    }
    return (
        <Fragment>
            <div className="smallilac px-3">
                <div className='ca-card mb-3 '>
                    <div className="row admintitle align-items-center">
                        <div className="col-md-6"><h2 className="font-weight-bold">Attributes</h2></div>
                        <div className='col-md-6'>
                            <div className='attlist'>
                                {filteredArrayAttr && filteredArrayAttr.map(item => (
                                    <span className='attr' key={item.value}>{item && item.label}</span>
                                ))}               
                            </div> 
                        </div>
                    </div>
                </div>
                <div className="ca-card mb-3">
                    <div className="card-body">                    
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <label htmlFor="name" className="font-weight-bold mb-3" >Select Attributes</label>
                                <Select
                                    value={selectType}
                                    onChange={handleSlotDropDown}
                                    options={options}
                                    isMulti
                                    isOptionDisabled={() => selectType.length >= 3}
                                    placeholder="Select attributes"
                                    
                                />
                                {(selectType.length != 3) && <div className='mandatory-req small'>Info : Must be select 3 attributes</div>}
                            </div>
                        </div>
                        {(selectType.length >= 3) && <div className='attr-err mt-2'>Max limit achieved</div>}
                        <div  className='text-center mt-3'>
                            <button type='button' className='btn btn-primary text-center' onClick={submitAttributes}>Add</button>                        
                        </div>
                    </div>
                </div>


            </div>

        </Fragment>
    )

}


export { Attributes }