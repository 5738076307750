import React, { Fragment, useEffect, useState } from 'react'
import { useLocation ,useParams} from 'react-router-dom'

import '../Menu.css'
import { AddEvents } from './AddEvent'
function EditEvents (props){
  
       const params = useParams()

        return(
            <Fragment>
              
             <AddEvents params={params}/>
            </Fragment>
        )
    
}

export {EditEvents}