import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import moment from 'moment'
import { adminActions } from '../../../../store/actions';
import { toast } from 'react-toastify';
import '../Menu.css'
import { getMaxMintCount } from '../../../../Utils/utils';
import { configPath } from '../../../../config';
import { config } from '@onflow/fcl';
import { VideoPreview } from './VideoPreview';
var dataURL = {}
var maxCaptionLimit = 50

function AddEventGallery(props) {


    const navigate = useNavigate() // page redirection 
    const dispatch = useDispatch() //dispatch action

    //define states
    const [caption, setCaption] = useState('')
    const [desc, setDesc] = useState('')
    const [submitted, setSubmitted] = useState(false)
    const [selectType, setSelectType] = useState(0)
    const [getEve, setGetEve] = useState([]) // for event dropdown
    const [localImgPreview, setLocalImgPreview] = useState('')
    const [localVideoPreview, setLocalVideoPreview] = useState('')
    const [localArtType, setLocalArtType] = useState('')
    const [localImageName, setLocalImageName] = useState('')
    const [localFileUpload, setLocalFileUpload] = useState(false)
    const [localFileData, setLocalFileData] = useState([])
    //get states from store
    const getEvents = useSelector(state => state.adminreducer.getEvents)
    const galleryAdded = useSelector(state => state.adminreducer.e_galleryAdded)


    const allTypes = [{ event: "Select event", value: 0 }];

    const gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }



    //successfully created/updated slot
    //navigate to slots page

    useEffect(() => {
        if (galleryAdded && galleryAdded.statusCode === 200) {
            dispatch(adminActions.resetAddEventGallery())
            navigate('/creatoradmin/eventgalleryList')
        }
    })
    //componentDidMount()
    useEffect(() => {
        gotoTop()

        dispatch(adminActions.getAllEvents({}))
    }, [])



    //fetch all events name
    useEffect(() => {
        if (getEvents && getEvents.statusCode === 200) {
            dispatch(adminActions.resetGetEvents())
            const allevents = (getEvents && getEvents.data && getEvents.data.events)

            allevents && allevents.length > 0 && allevents.map(items => {
                let obj = {
                    event: items && items.name,
                    value: items && items.id
                }
                allTypes.push(obj)

            })
            setGetEve(allTypes)

        }
    }, [getEvents])


    const eventDropDown = getEve.map((filType) => {
        return <option value={filType.value} key={filType.value}>{filType.event}</option>
    });

    const gotoBack = () => {
        navigate(-1)
    }

    const onFullNameChange = (e) => {
        setCaption(e.target.value)
    }
    const onDescChange = (e) => {
        setDesc(e.target.value)
    }

    const checkname = (value) => {
        if ((value && value.length) > maxCaptionLimit) {
            return true
        } else {
            return false
        }
    }
    const checkFullName = (value) => {
        if (((value && value.length) <= maxCaptionLimit) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }


    const handleEventSelect = (e) => {

        setSelectType(e.target.value) //selected rarity type
    }
    const checkmaxInput = (value) => {
        if ((value && value.length) <= maxCaptionLimit) {
            return true
        } else {
            return false
        }
    }
    const checkmaxDescInput = (value) => {
        if ((value && value.length) <= 250) {
            return true
        } else {
            return false
        }
    }
    const checkInput = (value) => {
        if ((value && value.length) >= 3) {
            return true
        } else {
            return false
        }
    }
    const getLocalPreview = () => {
        if (localImgPreview !== '') {

            return (
                <img src={localImgPreview} className="no-images-vertical createartimg" alt="preview" />
            );
        } else if (localVideoPreview !== '') {

            const video = {
                source: localVideoPreview,
                mime: localArtType
            };
            return (
                <VideoPreview source={video.source} mimeType={video.mime} showControls={false} />
            );
        } else if (localImgPreview === '' && localVideoPreview === '') {
            return (
                <img src={localImgPreview} className="no-images-vertical createartimg" alt="" />
            );
        }
    }
    const handleChangeFile = () => {
        setLocalArtType('')
        setLocalFileUpload(false)
        setLocalImageName('')
        setLocalImgPreview('')
        setLocalVideoPreview('')
        setLocalFileData([])
    }
    const previewFileProgress1 = (e) => {
        let uploadFile = e.target.files[0]

        const types = ['image/png', 'image/jpeg', 'image/gif', 'image/webp', 'video/mp4', 'video/webm']

        let err = '';
        const ImagsizeLimit = 2 * (1e+6);
        const VidesizeLimit = 20 * (1e+6);


        if (uploadFile == undefined || uploadFile.type == undefined) {
            return false
        }
        else if ((types.every(type => uploadFile && uploadFile.type !== type))) {

            err += uploadFile.type + ' is not a supported format\n';
            toast.error(err)
            setLocalFileUpload(false)

        } else {
            setLocalImageName(e.target.files[0] && e.target.files[0].name)

            if (uploadFile) {
                if ((uploadFile && uploadFile.type && ((uploadFile.type === "image/png") || (uploadFile.type === ("image/jpeg")) || (uploadFile.type === ("image/webp")) || (uploadFile.type === ("image/jpg"))))) {
                    if (uploadFile && uploadFile.size >= ImagsizeLimit) {
                        err += `Maximum supported file size is ${2} MB`;
                        toast.error(err)
                        setLocalFileUpload(false)
                    } else {
                        const resp = uploadFile
                        let reader = new FileReader();
                        window.URL.createObjectURL(resp);
                        reader.onloadend = async (theFile) => {
                            var fileData = await resp

                            dataURL = await reader.result
                            setLocalImgPreview(window.URL.createObjectURL(resp))
                            setLocalFileData(resp)
                            setLocalImageName(resp.name)
                            setLocalArtType(resp.type)
                            setLocalFileUpload(true)

                        }
                        if (resp) {
                            reader.readAsDataURL(resp)
                        }
                    }
                } else if (
                    (uploadFile && uploadFile.type &&
                        ((uploadFile.type === "video/mp4") || (uploadFile.type === "video/webm")))
                ) {
                    if (uploadFile && uploadFile.size >= VidesizeLimit) {
                        err += `Maximum supported file size is ${20} MB`;
                        toast.error(err)
                        setLocalFileUpload(false)

                    } else {
                        const file = uploadFile
                        const localVideoPreview = URL.createObjectURL(file);
                        setLocalVideoPreview(localVideoPreview)
                        setLocalFileData(file)
                        setLocalImageName(file.name)
                        setLocalArtType(file.type)
                        setLocalFileUpload(true)
                        setLocalImgPreview('')

                    }
                } else {
                    if (err == '') {
                        if (uploadFile && uploadFile.size >= ImagsizeLimit) {
                            err += `Maximum supported file size is ${2} MB`;
                            toast.error(err)
                            setLocalFileUpload(false)
                        } else {
                            let reader = new FileReader();
                            let file = uploadFile
                            reader.onloadend = () => {
                                setLocalImgPreview(reader.result)
                                setLocalFileData(file)
                                setLocalImageName(file.name)
                                setLocalArtType(file.type)
                                setLocalFileUpload(true)

                            }

                            if (uploadFile && uploadFile) {
                                reader.readAsDataURL(file)
                            }
                        }
                    }
                }
            }
        }

    }
    const checkDesc = (value) => {
        if (((value && value.length) <= 250) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let formData = new FormData()
        if (selectType) {
            if (caption && checkFullName(caption)) {
                if (localImageName && localFileData && localFileData.name ) {
                    formData.append("caption", caption)
                    formData.append("media", localFileData)
                    if (desc) {
                        if (checkDesc(desc)) {
                            formData.append("description", desc)
                            dispatch(adminActions.addEventGallery(selectType, (formData)))

                        } else {
                            toast.error("Description must be contain atlease 3 characters and atmost 250 characters long")
                        }
                    } else {
                        dispatch(adminActions.addEventGallery(selectType, (formData)))
                    }
                } else {
                    toast.error("Please select your gallery file")

                }

            } else {
                toast.error("Caption mandatory and should be atleast 3 characters and atmost 50 characters")
            }
        } else {
            toast.error("Please select event for this gallery")

        }
    }


    return (
        <Fragment>
            <div className="smallilac px-3">
                <div className='ca-card mb-3 '>
                    <div className="row admintitle align-items-center">
                        <div className="col-sm-6 "><h2 className="font-weight-bold">
                            {(props && props.params && props.params.id) ? "Edit Event Gallery" : "Add Event Gallery"}</h2>  </div>
                        <div className="col-sm-6 text-right"><button className=" btn btn-outline-primary" type="button" onClick={gotoBack}><i className="fas fa-angle-left"></i> Back</button>  </div>
                    </div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className='ca-card mb-3 '>
                        <div className="row">
                            <div className="form-group col-md-6 mb-4">
                                <label htmlFor="events " className="font-weight-bold" >Select Event &nbsp;*</label>
                                {getEve && getEve.length > 0 ?
                                    <select className="form-control" value={selectType} onChange={handleEventSelect} >
                                        {eventDropDown}
                                    </select> : <div className="col-md-12 text-center">
                                        <div className="loader"></div>
                                    </div>}
                                    {submitted && !(selectType) && <div className="mandatory small">{"Please select event type"}</div>}

                            </div>
                            <div className="form-group col-md-6 mb-4">
                                <label htmlFor="caption " className="font-weight-bold" >Caption &nbsp;*</label>
                                <input type="text" className={checkname(caption) ? "form-control descdan" : "form-control"} id="caption" aria-describedby="name" placeholder="Enter caption" value={caption} onChange={onFullNameChange} />
                                {<div className="mandatory-req small">{"Min 3 characters and max 50 characters"}</div>}

                                {submitted && !caption && <div className="mandatory small">{"Caption required"}</div>}
                                {submitted && caption && !checkInput(caption) && <div className="mandatory small">{"Caption must be at least 3 characters"}</div>}
                                {submitted && caption && !checkmaxInput(caption) && <div className="mandatory small">Caption should be no more than 50 characters</div>}
                            </div>


                            <div className="form-group col-md-12 ">
                                <label htmlFor="desc " className="font-weight-bold" >Description</label>
                                <textarea className="form-control textareaexapand" id="desc" aria-describedby="desc" placeholder="Enter description" value={desc} onChange={onDescChange} />
                                {/* {submitted && !desc && <div className="mandatory small">{"Description required"}</div>} */}
                                {<div className="mandatory-req small">{"Min 3 characters and max 250 characters"}</div>}

                                {submitted && desc && !checkInput(desc) && <div className="mandatory small">{"Description must be at least 3 characters"}</div>}
                                {submitted && desc && !checkmaxDescInput(desc) && <div className="mandatory small">Description should be no more than 250 characters</div>}

                            </div>
                            <div className="form-group mb-3 col-md-12">
                                <label className="col-form-label font-weight-bold">Select your media file</label>

                                <div className="row mb-3 nftbtns">
                                    <div className="col-md-12"><div className="mandatory-req mb-3 text-white">{localImageName}</div></div>
                                    <div className="col-md-6">
                                        {localFileUpload && <div onClick={handleChangeFile}><label className="btn btn-secondary w-100">Change file</label></div>}
                                        {!localFileUpload && <div className="choosefile w-100">
                                            <input type="file" id="pfile" name="image" onChange={previewFileProgress1} single="" className="inp-box w-100 p-2" accept="image/jpeg, image/png, image/gif, video/mp4, video/webm" />

                                            <button htmlFor="pfile" className="btn btn-primary w-100 mb-3 p-2" type="button">Browse File</button>
                                            {submitted && !localImageName && <div className="mandatory">{"Gallery File Required"}</div>}
                                        </div>}
                                        <div className="mandatory-req small">Maximum File Size for Image <strong>2 MB</strong><br /> Maximum File Size for Video <strong>20 MB</strong></div>
                                    <div className="mandatory-req brdnft small">Supported format for Image (JPG, JPEG, PNG, GIF, WEBP) , Video (MP4, WEBM)</div>
                                    </div>

                                
                                    <div className="col-md-6">
                                    <div className="previewimg mb-3 form-control">
                                    {getLocalPreview()}
                                </div>
                                    </div>
                                </div>

                                

                                
                            </div>
                        </div>
                    </div>



                    {<div className="form-group col-md-12 text-right">
                        <hr />
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>}
                </form>

            </div>

        </Fragment>
    )

}

export { AddEventGallery }