import React from "react";
import Footer from "../Footer/Footer";

import Header from "../Header/Header";
 class AppLayout extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            
        }
    }
   
    render() {
 
        return (
            <div>
                <Header appPath="" />       

                {this.props.children}
                <Footer/>
            </div>
        )
    }
}

export {AppLayout}
