import React, { Fragment, useState } from "react";
import { Modal, Button } from "react-bootstrap";

const Community = () => {
      const [bcShow, setbcShow] = useState(false);
      const [studShow, setstudShow] = useState(false); // badazz studio
      const [brandShow, setBrandShow] = useState(false) //badazz brands
      const [impactShow, setImpactShow] = useState(false); //impact

      //bearcave
      const handlebcClose = () => setbcShow(false);
      const handlebcShow = () => setbcShow(true);

      //studio
      const handleStudClose = () => setstudShow(false);
      const handleStudShow = () => setstudShow(true);

      //brands
      const handleBrandClose = () => setBrandShow(false);
      const handleBrandShow = () => setBrandShow(true);

      //impact
      const handleImpactClose = () => setImpactShow(false);
      const handleImpactShow = () => setImpactShow(true);
      return (
            <Fragment>

                  <Modal show={bcShow} onHide={handlebcClose} size="lg" className="bab-popup"  aria-labelledby="contained-modal-title-vcenter"
      centered>
                        <Modal.Header >
                              <Modal.Title>BEARCAVE</Modal.Title>
                              <Button variant="primary" onClick={handlebcClose}>
                                    Back to Blueprint <i className="fa fa-times"></i>
                              </Button>
                        </Modal.Header>
                        <Modal.Body>
                              <p className="mb-5">The Bear Cave is a diverse ecosystem, exclusive for Badazz Bear holders, built through robust partnerships in the areas of fashion, film, and music.</p>

                              <h3>BADAZZ BEARS X DISRUPT ART</h3>
                              <p className="mb-5">Disrupt Art is a global NFT marketplace created to revolutionize the impact of art, music, fashion, and film. Disrupt Art is one of Flow’s leading marketplaces, where Bears holders will buy and sell Bears and custom accessories with member-only perks.</p>

                              <h3>IRL BADAZZ BEARS ACTIVATIONS</h3>
                              <p className="mb-5">Real-life activations through fashion shows, musical performances, and flash pop-up stores in cities worldwide creating memorable brand experiences for Bear holders.</p>

                              <h3>KEYNOTES, CONFERENCES, AND MEETUPS</h3>
                              <p className="mb-5">Connect diverse communities across the world in the fields of tech, art, entrepreneurship, and social impact. These events will consist of keynotes, conferences, and meet-ups exclusive to Bear holders and other partnering NFT collections, both online and IRL.</p>
                        </Modal.Body>
                       
                  </Modal>



                  <Modal show={studShow} onHide={handleStudClose} size="lg" className="bab-popup"  aria-labelledby="contained-modal-title-vcenter"
      centered>
                        <Modal.Header >
                              <Modal.Title>BADAZZ STUDIO</Modal.Title>
                              <Button variant="primary" onClick={handleStudClose}>
                                    Back to Blueprint <i className="fa fa-times"></i>
                              </Button>
                        </Modal.Header>
                        <Modal.Body>
                              <p className="mb-5">Throughout Jim Jones's career, he has been a pioneer of innovation in music and business. He was one of the first rappers to enter into crypto and NFTs. We are building music together in the Metaverse and beyond.</p>

                              <h3>FUTURE NFT DROPS</h3>
                              <p className="mb-5">Probably BADAZZ</p>

                              <h3>COMMUNITY REMIXES</h3>
                              <p className="mb-5">Working with Quartine Studios we are putting together a curated collection of music made by the community and published according to how the community votes.</p>

                              <h3>PRODUCE WITH JIM JONES</h3>
                              <p className="mb-5">Behind-the-scenes exclusive access to Jim Jones and Quartine Studios.</p>


                        </Modal.Body>
                      
                  </Modal>
                  <Modal show={brandShow} onHide={handleBrandClose} size="lg" className="bab-popup"  aria-labelledby="contained-modal-title-vcenter"
      centered>
                        <Modal.Header >
                              <Modal.Title>BADAZZ BRANDS</Modal.Title>
                              <Button variant="primary" onClick={handleBrandClose}>
                                    Back to Blueprint <i className="fa fa-times"></i>
                              </Button>

                        </Modal.Header>
                        <Modal.Body>
                        <p className="mb-5">Bringing Badazz Brands to the world with global collaborations in fashion, lifestyle, and sports brands.</p>

                        <h3>Build a Bear</h3>
                              <p className="mb-5">Build your custom Bear with drip and accessories as separate NFTs and access to exclusive drip, accessories and Badazz Bears merch to show the world that we set the trends.</p>

                              <h3>LIMITED-BADAZZ COLLECTIBLES</h3>
                              <p className="mb-5">For Badazz Bears holders 3D images and concept art prints will be made available for purchase strictly for Badazz Bears holders with other general items available for non-holders.</p>

                        </Modal.Body>                       
                  </Modal>
                  <Modal show={impactShow} onHide={handleImpactClose} size="lg" className="bab-popup"  aria-labelledby="contained-modal-title-vcenter"
      centered>
                        <Modal.Header >
                              <Modal.Title>BADAZZ IMPACT</Modal.Title>
                              <Button variant="primary" onClick={handleImpactClose}>
                                    Back to Blueprint <i className="fa fa-times"></i>
                              </Button>
                        </Modal.Header>
                        <Modal.Body>
                        <p className="mb-5">We will continue to make a Badazz impact by working with the Reform Alliance, the Alliance for Safety and Justice, and others to help improve access and opportunities for returning citizens and raise public awareness of the need to reform the criminal justice system in America. 2 percent of all profits will go toward efforts of Reform Alliance to empower returning citizens and organizing criminal justice reform efforts across the country.</p>

                        
                        <h3>COMMUNITY ACTIVATIONS</h3>
                              <p className="mb-5">Both online and IRL community activations that continues to change the outcomes and narratives around returning citizens.</p>

                              <h3>POLICY CHANGES</h3>
                              <p className="mb-5">Increase connections with local communities to change local laws and provide more equitable and just policies that keep communities safe and just.</p>

                              <h3>OUTREACH</h3>
                              <p className="mb-5">A series of initiatives championing thought leadership and innovation to improve job opportunities for returning citizens.</p>

                        </Modal.Body>
                       
                  </Modal>
                  <div className="waitinglist orangebg bab-bpbg">
                        <div className="container-fluid">
                              <h1 className="waitinglist-h mb-3 text-center text-uppercase">Building BADAZZ Community Together</h1>

                              <div className="bab-blueprint">
                                    <div className="row">

                                          <div className="col-sm-12 col-md-6 offset-md-3">
                                                <div className="row">
                                                      <div className="col-md-12">
                                                            <div className="bab-bplist" onClick={handleStudShow}>
                                                                  <button className="btn btn-primary">
                                                                        BADAZZ STUDIO
                                                                  </button>
                                                            </div>
                                                      </div>
                                                      <div className="col-6">
                                                            <div className="bab-bplist" onClick={handleBrandShow}>
                                                                  <button className="btn btn-primary">
                                                                        BADAZZ BRANDS
                                                                  </button>
                                                            </div>
                                                      </div>
                                                      <div className="col-6">
                                                            <div className="bab-bplist" onClick={handleImpactShow}>
                                                                  <button className="btn btn-primary">
                                                                        BADAZZ IMPACT
                                                                  </button>
                                                            </div>
                                                      </div>
                                                </div>
                                                <div className="bab-bplist bab-bp-center" onClick={handlebcShow}>
                                                      <button className="btn btn-primary">
                                                            BEARCAVE
                                                      </button>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                  </div>
            </Fragment>
      );
}

export default Community;