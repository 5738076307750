import { adminConstants } from "../../constants";

const initialState = {
    attributes: [],
    adminUser: [],
    adminuserfailed: [],
    isLoggedOut: false,
    addedslot: [],
    slots: [],
    slotDet: [],
    updateSlot: [],
    freezeSlotRes: [],
    filteredAttr: [],
    updateAttr: [],
    getArts: [],
    getSettings: [],
    updateSettings: [],
    rej_art: [],
    unrej_art: [],
    addedEmail: [],
    addEmailFailed: [],
    emailCount: [],
    soldCount: [],
    validateTransaction : [],
    validationFailed : [],
    sendbearSuccess : [],
    sendBearFailed : [],
    transactionVerified : [],
    verificationFailed : [],
    getEvents : [],
    addEvent : [],
    eventDetail : [],
    updatedEvent : [],
    e_galleryAdded : [],
    deletedEventGallery : []
}
export function adminreducer(state = initialState, action) {
    switch (action.type) {
        case adminConstants.GET_ALL_ATTRIBUTES:
            console.log(action.attributes)
            return {
                attributes: action.attributes
            }
        case adminConstants.ADMIN_AUTH:
            console.log(action.adminUser)
            return {
                adminUser: action.adminUser
            }
        case adminConstants.ADMIN_AUTH_FAILED:
            console.log(action.adminuserfailed)

            return {
                adminuserfailed: action.adminuserfailed
            }
        case adminConstants.ADMIN_LOGOUT:
            return {
                isLoggedOut: action.isLoggedOut
            }
        case adminConstants.ADMIN_GET_SLOTS:
            return {
                ...state,
                slots: action.slots
            }
        case adminConstants.ADMIN_ADD_SLOTS:
            return {
                addedslot: action.addedslot
            }
        case adminConstants.ADMIN_GET_SLOT_DETAIL:
            return {
                slotDet: action.slotDet
            }
        case adminConstants.ADMIN_UPDATE_SLOT:
            return {
                updateSlot: action.updateSlot
            }
        case adminConstants.ADMIN_FREEZE_SLOT:
            return {
                freezeSlotRes: action.freezeSlotRes
            }
        case adminConstants.ADMIN_FILTER_ATTRIBUTES:
            return {
                filteredAttr: action.filteredAttr
            }
        case adminConstants.ADMIN_UPDATE_ATTRIBUTES:
            return {
                updateAttr: action.updateAttr
            }
        case adminConstants.ADMIN_GET_ARTS:
            return {
                ...state,
                getArts: action.getArts
            }
        case adminConstants.ADMIN_GET_SETTINGS:
            return {
                getSettings: action.getSettings
            }
        case adminConstants.ADMIN_PUT_SETTINGS:
            return {
                updateSettings: action.updateSettings
            }
        case adminConstants.ADMIN_REJECT_ARTS:
            return {
                rej_art: action.rej_art
            }
        case adminConstants.ADMIN_UNREJECT_ARTS:
            return {
                unrej_art: action.unrej_art
            }
        case adminConstants.ADMIN_ADD_WAITLIST_EMAIL:
            return {
                addedEmail: action.addedEmail
            }
        case adminConstants.ADMIN_ADD_WAITLIST_EMAIL_FAILED:
            return {
                addEmailFailed: action.addEmailFailed
            }
        case adminConstants.ADMIN_GET_WAITLIST_COUNT:
            return {
                emailCount: action.emailCount
            }
        case adminConstants.ADMIN_GET_SOLD_COUNT:
            return {
                soldCount: action.soldCount
            }
        case adminConstants.ADMIN_BEAR_VALIDATION:
            return {
                ...state,
                validateTransaction : action.validateTransaction
            }
        case adminConstants.ADMIN_BEAR_VALIDATION_FAILED:
            return {
                ...state,
                validationFailed : action.validationFailed
            }
        case adminConstants.ADMIN_SEND_BEAR_TOADDRESS:
            return {
                ...state,
                sendbearSuccess : action.sendbearSuccess
            }
        case adminConstants.ADMIN_SEND_BEAR_FAILED:
            return {
                ...state,
                sendBearFailed : action.sendBearFailed
            }
        case adminConstants.ADMIN_BEAR_VERIFICATION:
            return {
                ...state,
                transactionVerified : action.transactionVerified
            }
        case adminConstants.ADMIN_BEAR_VERIFICATION_FAILED:
            return {
                ...state,
                verificationFailed : action.verificationFailed
            }
        case adminConstants.ADMIN_GET_EVENTS:
            return {
                ...state,
                getEvents : action.getEvents
            }
        case adminConstants.ADMIN_ADD_EVENTS:
            return {
                ...state,
                addEvent : action.addEvent
            }
        case adminConstants.ADMIN_GET_EVENT_DETAIL:
            return {
                ...state,
                eventDetail : action.eventDetail
            }
        case adminConstants.ADMIN_UPDATE_EVENT:
            return {
                ...state,
                updatedEvent : action.updatedEvent
            }
        case adminConstants.ADMIN_ADD_EVENT_GALLERY:
            return {
                ...state,
                e_galleryAdded : action.e_galleryAdded
            }
        case adminConstants.ADMIN_GET_EVENT_GALLERY:
            return {
                ...state,
                eventGallery : action.eventGallery
            }
        case adminConstants.ADMIN_DELETE_EVENT_GALLERY:
            return {
                ...state,
                deletedEventGallery : action.deletedEventGallery
            }

        default:
            return state
    }
}