import React, { Component, Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from '../../../../store/actions';
import moment from 'moment'
import { ReactPagination } from '../../../../helpers/paginate';

function FetchEvents() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const getEvents = useSelector(state => state.adminreducer.getEvents)

    const gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    const [events, setEvents] = useState([]);
    const [offSet, setOffSet] = useState(0)
    const [recordLimit,setRecordLimit] = useState(10)
    const [pageCount,setPageCount] = useState('')
    const [callBack, setCallBack] = useState(false)
    useEffect(() => {
        initialCall()
    },[])
    const initialCall = () => {
        gotoTop()
        let obj = {
            offSet: offSet,
            recordLimit : recordLimit,
            sortBy : "createdAt",
            sortOrder:"DESC"
        }
        dispatch(adminActions.getAllEvents(obj))
    }
    useEffect(() => {

        if(getEvents && getEvents.statusCode === 200){
            dispatch(adminActions.resetGetEvents())
            if(getEvents && getEvents.data && getEvents.data.events){
                setEvents(getEvents.data.events)
                setPageCount(getEvents && getEvents.data && getEvents.data.count / recordLimit)

            }
        }
    },[getEvents])
    const editAccount = (items) => {
        if ((items && items.id)) {
            navigate(`/creatoradmin/event/edit/${items.id}`)
        }
    }
    const deleteAccount = (items) => {
        const filterArray = events && events.filter(el => (el && el.id) != (items && items.id))
        setEvents(filterArray)
    }

    const gotoAddEvent = () => {
        navigate('/creatoradmin/events/add')
    }
    const onPageChange = (data) => {
        let offSet = recordLimit * (data && data.selected)
        setOffSet(offSet)
        setCallBack(true)
    }
    useEffect(() => {
        if (callBack) {
            initialCall()
            setCallBack(false)
        } else {
            return;
        }
    }, [callBack])
    const AccountList = events && events.map((items) => {
        return <tr>
            <td>{items && items.name}</td>
            <td>{items && items.startsAt && moment(items.startsAt).format('DD/MM/YYYY')}</td>
            <td>{items && items.endsAt && moment(items.endsAt).format('DD/MM/YYYY')}</td>
            <td>{items && items.venueAddress}</td>
            <td>{items && items.createdAt && moment(items.createdAt).format('DD/MM/YYYY hh:mm:ss A')}</td>
            <td>
                    <a className="btn btn-outline-primary btn-sm btn-action mr-2" type="button" onClick={() => { editAccount(items) }} data-toggle="tooltip" data-placement="bottom" title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></a>
                </td>
        </tr>
    })
    return (
        <Fragment>
            <div className="smallilac px-3">
                <div className='ca-card mb-3 '>
                    <div className="row admintitle align-items-center">
                        <div className="col-sm-6"><h2 className="font-weight-bold">Events  </h2></div>
                        {<div className="col-sm-6 text-right"><button className=" btn btn-outline-primary" type="button" onClick={gotoAddEvent}><i class="fas fa-plus"></i> Add Event</button>  </div>}
                    </div>
                </div>
                <div className='ca-card'>
                    <div className="table-responsive">
                        <table className="table table-bordered ca-table-custom">
                            <thead>
                                <tr>
                                    <th>EVENT NAME</th>
                                    <th>STARTS AT</th>
                                    <th>ENDS AT</th>
                                    <th>VENUE ADDRESS</th>
                                    <th>CREATED AT</th>
                                    <th>ACTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                {AccountList}
                            </tbody>
                        </table>
                        {(events && events.length > 0) ?  '': <div className="col-md-12 text-center">
                                        No Events
                                    </div>}
                    </div>
                </div>
                {events && events.length > 0  &&  <ReactPagination pageDetails={{ pageCount: pageCount, perPage: recordLimit, onPageChange }} />}

            </div>

        </Fragment>
    )

}


export { FetchEvents }