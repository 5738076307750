import React, { Fragment, useEffect, useState } from 'react'
import { useLocation ,useParams} from 'react-router-dom'

import '../Menu.css'
import { AddSlots } from './AddSlot'
function EditSlots (props){
  
       const location = useLocation()
       const params = useParams()
    useEffect(() => {
        console.log(location,params)
    },[])
   
   
    
        return(
            <Fragment>
              
             <AddSlots params={params}/>
            </Fragment>
        )
    
}

export {EditSlots}