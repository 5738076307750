import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import Img2 from "../../../Assets/images/sec1_img2.png";
import { adminActions } from '../../../store/actions';
import { configPath } from '../../../config';
import { ReactPagination } from '../../../helpers/paginate';
import { toast } from 'react-toastify';

function Gallery() {

    const dispatch = useDispatch() //dispatch action
    const getAllArts = useSelector(state => state.adminreducer.getArts)
    const rejectedArt = useSelector(state => state.adminreducer.rej_art)
    const unRejectedArt = useSelector(state => state.adminreducer.unrej_art)
    const getSlots = useSelector(state => state.adminreducer.slots)

    const [slotType, setSlotType] = useState(1)
    const [options, setOptions] = useState([{slot : "Select",value:0}])
    const [artsList, setArtsList] = useState([]);
    const [offSet, setOffSet] = useState(0)
    const [recordLimit, setRecordLimit] = useState(40)
    const [pageCount, setPageCount] = useState('')
    const [callBack, setCallBack] = useState(false)
    const [slots, setSlots] = useState([]);

    useEffect(() => {
        fetchAllArts()
        let obj = {}
        dispatch(adminActions.getSlots(obj,(configPath.pfpId)))
    }, [])
    const fetchAllArts = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        let obj = {
            offSet: offSet,
            recordLimit: recordLimit,
            isGallery: true,
        }

        dispatch(adminActions.getAllArts((configPath.pfpId), (slotType), obj))
    }
    useEffect(() => {

        if ((getAllArts && getAllArts.statusCode) === 200) {

            dispatch(adminActions.resetGetArts())
            if (getAllArts.data && getAllArts.data.arts) {
                setPageCount(getAllArts && getAllArts.data && getAllArts.data.count / recordLimit)
                setArtsList(getAllArts.data.arts)
            }
        }
    })
    useEffect(() => {
        if ((rejectedArt && rejectedArt.statusCode) === 200) {
            dispatch(adminActions.resetRejectArts())
            toast.success("Bear rejected")
            setCallBack(true)
        }
    }, [rejectedArt])
    useEffect(() => {
        if ((unRejectedArt && unRejectedArt.statusCode) === 200) {
            dispatch(adminActions.resetUnRejectArts())
            toast.success("Bear approved")
            setCallBack(true)
        }
    }, [unRejectedArt])
    const onPageChange = (data) => {
        console.log("data", data)
        let offSet = recordLimit * (data && data.selected)
        setOffSet(offSet)
        setCallBack(true)
    }
    useEffect(() => {
        if (callBack) {
            fetchAllArts()
            setCallBack(false)
        } else {
            return;
        }
    }, [callBack])
    const handleReject = (e, items) => {
        e.preventDefault()
        if ((items && items.id)) {
            dispatch(adminActions.rejectArts((configPath.pfpId), (slotType), (items.id)))
        } else {
            toast.error("Something went wrong")
        }
    }
    const handleUnReject = (e, items) => {
        e.preventDefault()
        if ((items && items.id)) {
            dispatch(adminActions.unRejectArts((configPath.pfpId), (slotType), (items.id)))
        } else {
            toast.error("Something went wrong")
        }
    }
    const handleSlotDropDown = (e) => {
        setSlotType(e.target.value)
        setCallBack(true)
        setOffSet(0)
    }
    useEffect(() => {
        let array = []
        if(getSlots && getSlots.statusCode === 200){
            dispatch(adminActions.resetGetSlot())
            if(getSlots && getSlots.data && getSlots.data.slots){
                // setSlotCount(getSlots.data && getSlots.data.count)
                setSlots(getSlots.data.slots)
                getSlots.data.slots && getSlots.data.slots.map(item => {
                    let obj = {
                        slot : item && item.name,
                        value : item && item.id
                    }
                    array.push(obj)
                })
                setOptions(array)
            }
        }
    },[getSlots])
    const slotDropdown = options.map((filType) => {
        return <option value={filType.value}>{filType.slot}</option>
    });
    return (
        <Fragment>
            <div className="smallilac px-3">
                
                <div className='ca-card mb-3 '>
                    <div className="row admintitle align-items-center">
                        <div className="col-md-4"><h2 className="font-weight-bold">Gallery </h2></div>
                        <div className="form-group mb-0 col-md-8">
                            <div className='ca-lablerow'>
                            <label htmlFor="name " className="font-weight-bold mb-0" for="name">Slots</label>
                            <select className="form-control" value={slotType} onChange={handleSlotDropDown}>
                                {slotDropdown}
                            </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ca-card mb-3">
                    <div className="card-body">

                        <div className="row justify-content-center">
                            {artsList && artsList.length > 0 && artsList.map((items, index) => (
                                <div className="col-sm-6 col-md-3" >
                                    <div class="gallery" title={(items && items.isRejected) ? ("Rejected Bear") : ""}>                                       
                                            <img src={((items && items.localPreviewURL) === null) ? "/assets/images/badazz.jpeg" : (((configPath.baseUrl) + (items && items.localPreviewURL)))} alt="Badazz Bears" crossorigin="anonymous" className={(items && items.isRejected) ? 'mw-100 gallery-opa' : 'mw-100'}  />
                                            {/* {(items && items.isRejected) ? (<button className='btn btn-primary' onClick={(e) => handleUnReject(e, items)} title="Undo reject"><i class="fa fa-check mr-0" aria-hidden="true"></i></button>) : (<button onClick={(e) => handleReject(e, items)} className='btn btn-primary' title='Reject'><i class="fa fa-times mr-0" aria-hidden="true"></i></button>)} */}
                                            <p className={'text-center'+(items && items.id)?"":"d-none"}>{(items && items.id)?"#"+items.id:""}</p>
                                    </div>
                                </div>))}

                        </div>
                    </div>
                </div>
                {artsList && artsList.length > 0 && <ReactPagination pageDetails={{ pageCount: pageCount, perPage: recordLimit, onPageChange }} />}


            </div>

        </Fragment>
    )

}


export { Gallery }