export const babConstants = {
    GET_ARTS : 'GET_ARTS',
    GET_ATTRIBUTE_FILTERS : 'GET_ATTRIBUTE_FILTERS',
    REGISTER_USER : 'REGISTER_USER',
    VERIFY_USER : 'VERIFY_USER',
    REGISTER_USER_FAILED : 'REGISTER_USER_FAILED',
    VERIFY_USER_FAIL : 'VERIFY_USER_FAIL',
    GET_USER_ADDRESS : 'GET_USER_ADDRESS' ,
    EMAIL_WALLET_PURCHASE : 'EMAIL_WALLET_PURCHASE',
    EMAIL_WALLET_PURCHASE_FAILED : 'EMAIL_WALLET_PURCHASE_FAILED',
    PAYMENT_INTENT : 'PAYMENT_INTENT',
    PAYMENT_INTENT_FAILED : 'PAYMENT_INTENT_FAILED'
}