import React, { Component } from 'react';
import { Route, BrowserRouter, Routes,Navigate } from 'react-router-dom'  
import { AdminLayoutRoute } from './admin/Components/Layouts/AdminLayoutRoute';
import { Login } from './admin/Components/Login';
import { FetchArts } from './admin/Components/Menus/Arts';
import { Dashboard } from './admin/Components/Menus/Dashboard/dashboard';
import { FetchEvents } from './admin/Components/Menus/Events';
import { Menu1 } from './admin/Components/Menus/Menu1';
import { Menu2 } from './admin/Components/Menus/Menu2';
import { Menu3 } from './admin/Components/Menus/Menu3';
import { ProcessBlock } from './admin/Components/Menus/Processblock/Processblock';
import { AddSlots, FetchSlots } from './admin/Components/Menus/Slot';
import { EditSlots } from './admin/Components/Menus/Slot/EditSlot';
import About from './Components/About';
import Collection from './Components/collection';
import ComingSoon from './Components/ComingSoon';
import Home from './Components/Home';
import { AppLayoutRoute } from './Components/Layouts/app-layout-route';
import Mint from './Components/Mint';
import WaitingList from './Components/waitingList';
import { Bears } from './Components/Bears';
import PubSub from 'pubsub-js';
import Community from './Components/community';
import { Attributes } from './admin/Components/Menus/Attributes/Attributes';
import { PageNotFound } from './Components/Pagenotfound';
import { Settings } from './admin/Components/Menus/Settings';
import { Gallery } from './admin/Components/Menus/Gallery';
import TestMint from './Components/testmint';
import Payment from './Components/Payment/Payments';
import { SuccessPayment } from './Components/Payment/successPayment';
import { WaitlistEmail } from './admin/Components/Menus/waitlistemail';
import { PurchaseCheck } from './Components/PurchaseCheck';
import DapperMint from './Components/dappermint';
import { FetchPurchasers } from './admin/Components/Menus/Purchasers';
import { BearTransaction } from './admin/Components/Menus/Transfer_bear';
import { AddEvents } from './admin/Components/Menus/Events/AddEvent';
import { EditEvents } from './admin/Components/Menus/Events/EditEvent';
import { FetchEventGallery } from './admin/Components/Menus/EventsGallery/FetchEventGallery';
import { AddEventGallery } from './admin/Components/Menus/EventsGallery/AddEventGallery';

class AuthCluster extends Component {
    constructor(props){
        super(props);
            this.state = {
                loader : false
            }
        
    }
   
    componentDidMount(){
        this.subscribe = PubSub.subscribe('msg', (msg,data) => {
            this.setState({ loader : data})
        });

    }

    render() {
        const pathname = window.location.pathname

        return (
          
               <div>
            <BrowserRouter >
                <Routes>
                <Route exact path="/creatoradmin" element={<Login/>} />

                {/* </Routes>
                <Routes> */}
                <Route exact path="/creatoradmin/slots" element={<AdminLayoutRoute><FetchSlots/></AdminLayoutRoute>}/>
                <Route exact path="/creatoradmin/slots/add" element={<AdminLayoutRoute><AddSlots/></AdminLayoutRoute>}/>
                <Route exact path="/creatoradmin/slots/edit/:id" element={<AdminLayoutRoute><EditSlots/></AdminLayoutRoute>}/>
                <Route exact path="/creatoradmin/dashboard" element={<AdminLayoutRoute><Dashboard/></AdminLayoutRoute>}/>
                <Route exact path="/creatoradmin/events" element={<AdminLayoutRoute><FetchEvents/></AdminLayoutRoute>}/>
                <Route exact path="/creatoradmin/artslist" element={<AdminLayoutRoute><FetchArts/></AdminLayoutRoute>}/>
                <Route exact path="/creatoradmin/processblock" element={<AdminLayoutRoute><ProcessBlock/></AdminLayoutRoute>}/>
                <Route exact path="/creatoradmin/attributes" element={<AdminLayoutRoute><Attributes/></AdminLayoutRoute>}/>
                <Route exact path="/creatoradmin/settings" element={<AdminLayoutRoute><Settings/></AdminLayoutRoute>}/>
                <Route exact path="/creatoradmin/gallerylist" element={<AdminLayoutRoute><Gallery/></AdminLayoutRoute>}/>
                <Route exact path="/creatoradmin/waitlistemail" element={<AdminLayoutRoute><WaitlistEmail/></AdminLayoutRoute>}/>
                <Route exact path="/creatoradmin/purchased-arts" element={<AdminLayoutRoute><FetchPurchasers/></AdminLayoutRoute>}/>
                <Route exact path="/creatoradmin/transfer" element={<AdminLayoutRoute><BearTransaction/></AdminLayoutRoute>}/>
                <Route exact path="/creatoradmin/events/add" element={<AdminLayoutRoute><AddEvents/></AdminLayoutRoute>}/>
                <Route exact path="/creatoradmin/event/edit/:id" element={<AdminLayoutRoute><EditEvents/></AdminLayoutRoute>}/>
                <Route exact path="/creatoradmin/eventgalleryList" element={<AdminLayoutRoute><FetchEventGallery/></AdminLayoutRoute>}/>
                <Route exact path="/creatoradmin/eventgalleryList/add" element={<AdminLayoutRoute><AddEventGallery/></AdminLayoutRoute>}/>
                <Route exact path="/creatoradmin/menu1" element={<AdminLayoutRoute><Menu1/></AdminLayoutRoute>}/>
                <Route exact path="/creatoradmin/menu2" element={<AdminLayoutRoute><Menu2/></AdminLayoutRoute>}/>
                <Route exact path="/creatoradmin/menu3" element={<AdminLayoutRoute><Menu3/></AdminLayoutRoute>}/>

                {/* </Routes>
                
                <Routes> */}
                <Route exact path="/" element={<Navigate to={`/home`}/>} />
                {/* <Route exact path="/mint" element={<AppLayoutRoute><Mint/></AppLayoutRoute>}/> */}
                <Route exact path="/home" element={<AppLayoutRoute><Home/></AppLayoutRoute>} />
                <Route exact path="/collection" element={<AppLayoutRoute><Collection/></AppLayoutRoute>}/>
                {/* <Route exact path="/bearcave" element={<AppLayoutRoute><WaitingList/></AppLayoutRoute>}/> */}
                <Route exact path="/comingsoon" element={<AppLayoutRoute><ComingSoon/></AppLayoutRoute>}/>
                <Route exact path="/about" element={<AppLayoutRoute><About/></AppLayoutRoute>}/>
                <Route exact path="/bears" element={<AppLayoutRoute><Bears/></AppLayoutRoute>}/>
                <Route exact path="/community" element={<AppLayoutRoute><Community/></AppLayoutRoute>}/>
                {/* <Route exact path="/mint" element={<AppLayoutRoute><TestMint/></AppLayoutRoute>}/> */}
                <Route exact path="/checkout" element={<AppLayoutRoute><Payment/></AppLayoutRoute>}/>
                <Route exact path="/paymentsuccess" element={<AppLayoutRoute><SuccessPayment/></AppLayoutRoute>}/>
                <Route exact path="/4ertuv5c" element={<AppLayoutRoute><PurchaseCheck/></AppLayoutRoute>}/>
                <Route exact path="/mint" element={<AppLayoutRoute><DapperMint/></AppLayoutRoute>}/>
                <Route exact path="/bearcave" element={<Navigate to={`/mint`}/>} />
                <Route path="*" element={<AppLayoutRoute><PageNotFound/></AppLayoutRoute> }/>

                </Routes>

             
                
            </BrowserRouter>  
            {this.state.loader ?
                    <div className="loader-con">
                    <div className="loader admin-loader"></div>
                </div> :
                    <div className="loader-hide"></div>
                }
            </div>
        )
    }
}

export default AuthCluster