import React from 'react'
import { Navigate } from 'react-router-dom';
import { isAdminLogin } from '../../../helpers/authorizedData'

import {AdminLayout} from './AdminLayout'


export const AdminLayoutRoute = ({ children }) => {
	
	return (
		isAdminLogin() ? 

				 <AdminLayout>{children}</AdminLayout> : 
				 <Navigate to="/creatoradmin"/>
	);
};