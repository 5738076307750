import {adminServices} from '../../services'
import { adminConstants } from "../../constants"
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import PubSub from 'pubsub-js';

export const adminActions = {
    getAllAttributes,
    resetAllAttributes,
    adminLogin,
    resetAdminLogin,
    resetFailedAdminLogin,
    adminLogout,
    getSlots,
    addSlot,
    resetGetSlot,
    getSlotDetail,
    resetGetSlotDetail,
    updateSlot,
    freezeSlot,
    getFilterAttribute,
    resetGetFilteredAttribute,
    updateFilterAttribute,
    resetUpdateFilterAttribute,
    getAllArts,
    resetGetArts,
    getSettings,
    resetSettings,
    updateSettings,
    resetUpdateSettings,
    rejectArts,
    resetRejectArts,
    unRejectArts,
    resetUnRejectArts,
    resetFreezeSlot,
    addEmailtoWaitlist,
    resetAddEmailWaitlist,
    resetAddEmailFailed,
    getWaitlistCount,
    resetemailCount,
    getSoldCount,
    resetSoldCount,
    validateBearTransaction,
    resetValidationSuccess,
    resetValidationFailed,
    sendBearToAddress,
    resetSendBearSuccess,
    resetSendBearFailed,
    verifyBearTransferCode,
    resetBearVerification,
    resetBearVerificationFailed,
    getAllEvents,
    resetGetEvents,
    addEvents,
    resetAddEvent,
    getEventDetail,
    resetGetEventDetail,
    updateEvent,
    resetupdateEvent,
    addEventGallery,
    resetAddEventGallery,
    getAllEventGallery,
    resetGetAllEventGallery,
    deleteEventGallery,
    resetDeletedEventGallery
}
function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
}
function getAllAttributes(id){
    return dispatch => {
        adminServices.getAllAttributes(id)
        .then(mes => {
            hideLoader()
            if(mes){
                console.log(mes)
                dispatch({type:adminConstants.GET_ALL_ATTRIBUTES,attributes:mes})
               
            }
        },error => {
            hideLoader()
        })
    }
}
function resetAllAttributes(){
    return dispatch => {
        dispatch({type : adminConstants.GET_ALL_ATTRIBUTES,attributes:[]})
    }
}
function adminLogin(obj) {
    return dispatch => {
        adminServices.adminLogin(obj)
            .then(user => {
                if ((user && user.statusCode) === 200) {
                    localStorage.setItem("bab_adminDetail", JSON.stringify(user))
                    dispatch({ type: adminConstants.ADMIN_AUTH, adminUser:user, isAdmin: true })
                }
            }, error => {
                dispatch({ type: adminConstants.ADMIN_AUTH_FAILED,adminuserfailed : error})
                console.log("----admin Error----",error)
            })
    }
}
function resetAdminLogin(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_AUTH,adminUser:[]})
    }
}
function resetFailedAdminLogin(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_AUTH_FAILED,adminuserfailed:''})
    }
}
function adminLogout(){
    return dispatch => {
        adminServices.adminLogout()
        .then(user => {
            localStorage.removeItem('bab_adminDetail')

                dispatch({ type: adminConstants.ADMIN_LOGOUT, isLoggedOut : true})
        },error => {
            console.log(error)
        })
    }
}
function getSlots(obj,id){

    return dispatch => {
        showLoader()

        adminServices.getSlots(obj,id)
        .then(slots => {
            hideLoader()
            if(slots){
                console.log(slots)
                dispatch({type:adminConstants.ADMIN_GET_SLOTS,slots})
               
            }
        },error => {
            hideLoader()
        })
    }
}
function addSlot(obj,id){
    return dispatch => {
        showLoader()
        adminServices.addSlot(obj,id)
        .then(slots => {
            hideLoader()
            if(slots){
                console.log(slots)
                dispatch({type:adminConstants.ADMIN_ADD_SLOTS,addedslot : slots})
               
            }
        },error => {
            hideLoader()
            toast.error(error)
            console.log(error)
        })
    }
}
function resetGetSlot(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_GET_SLOTS,slots:[]})
    }
}
function getSlotDetail(id,slotid){
    return dispatch => {
        showLoader()
        adminServices.getSlotDetail(id,slotid)
        .then(slotDet => {
            hideLoader()
            if(slotDet){
                console.log(slotDet)
                dispatch({type : adminConstants.ADMIN_GET_SLOT_DETAIL,slotDet})
            }
        },error => {
            hideLoader()
            toast.error(error)
            console.log(error)
        })
    }
}
function resetGetSlotDetail(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_GET_SLOT_DETAIL,slotDet : []})
    }
}
function updateSlot(id,obj){
    return dispatch => {
        showLoader()
        adminServices.updateSlot(id,obj)
        .then (updateSlot => {
            hideLoader()
            if(updateSlot){
                console.log(updateSlot)
                dispatch({type: adminConstants.ADMIN_UPDATE_SLOT,updateSlot})
            }
        },error => {
            hideLoader()
            toast.error(error)
            console.log(error)
        })
    }
}
function freezeSlot(id,slotId){
    return dispatch => {
        showLoader()
        adminServices.freezeSlot(id,slotId)
        .then (slot => {
            hideLoader()
            if(slot){
                toast.success("Slot frozen")
                dispatch({type : adminConstants.ADMIN_FREEZE_SLOT,freezeSlotRes : slot})
            }
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetFreezeSlot(){
    return dispatch => {
        dispatch({type:adminConstants.ADMIN_FREEZE_SLOT,freezeSlotRes: []})
    }
}
function getFilterAttribute(pfpId){
    return dispatch => {
        showLoader()
        adminServices.getFilterAttribute(pfpId)
        .then (attr => {
            hideLoader()
            if(attr){
                dispatch({type : adminConstants.ADMIN_FILTER_ATTRIBUTES,filteredAttr : attr})
            }
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetGetFilteredAttribute(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_FILTER_ATTRIBUTES,filteredAttr : []})
    }
}
function updateFilterAttribute(pfpId,obj){
    return dispatch => {
        showLoader()
        adminServices.updateFilterAttribute(pfpId,obj)
        .then (attr => {
            hideLoader()
            if(attr){
                dispatch({type : adminConstants.ADMIN_UPDATE_ATTRIBUTES,updateAttr : attr})
            }
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetUpdateFilterAttribute(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_UPDATE_ATTRIBUTES,updateAttr : []})
    }
}
function getAllArts(pfpId,slotId,obj){
    return dispatch => {
        showLoader()
        adminServices.getAllArts(pfpId,slotId,obj)
        .then (arts => {
            hideLoader()
            if(arts){
                dispatch({type : adminConstants.ADMIN_GET_ARTS,getArts : arts})
            }
        },error => {
            hideLoader()
        })
    }
}
function resetGetArts(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_GET_ARTS,getArts : []})
    }
}
function getSettings(){
    return dispatch => {
        showLoader()
        adminServices.getSettings()
        .then(set => {
            hideLoader()
            if(set){
                dispatch({type : adminConstants.ADMIN_GET_SETTINGS,getSettings : set})
            }
        },error => {
            hideLoader()
        })
    }
}
function resetSettings(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_GET_SETTINGS,getSettings : []})
    }
}
function updateSettings(obj){
    return dispatch => {
        showLoader()
        adminServices.updateSettings(obj)
        .then (set => {
            hideLoader()
            if(set){
                dispatch({type :adminConstants.ADMIN_PUT_SETTINGS,updateSettings : set})
            }
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetUpdateSettings(){
    return dispatch => {
        dispatch ({type : adminConstants.ADMIN_PUT_SETTINGS,updateSettings : []})
    }
}
function rejectArts(pfpId,slotId,artId){
    return dispatch => {
        showLoader()
        adminServices.rejectArts(pfpId,slotId,artId)
        .then(art => {
            hideLoader()
            if(art){
                dispatch({type : adminConstants.ADMIN_REJECT_ARTS,rej_art : art})
            }
        },error => {
            hideLoader()
        })
    }
}
function resetRejectArts(){
    return dispatch => {
        dispatch ({type : adminConstants.ADMIN_REJECT_ARTS,rej_art : []})
    }
}
function unRejectArts(pfpId,slotId,artId){
    return dispatch => {
        showLoader()
        adminServices.unRejectArts(pfpId,slotId,artId)
        .then(art => {
            hideLoader()
            if(art){
                dispatch({type : adminConstants.ADMIN_UNREJECT_ARTS,unrej_art : art})
            }
        },error => {
            hideLoader()
        })
    }
}
function resetUnRejectArts(){
    return dispatch => {
        dispatch ({type : adminConstants.ADMIN_UNREJECT_ARTS,unrej_art : []})
    }
}
function addEmailtoWaitlist(obj){
    return dispatch => {
        showLoader()
        adminServices.addEmailtoWaitlist(obj)
        .then(email => {
            hideLoader()
            if(email){
                dispatch({type : adminConstants.ADMIN_ADD_WAITLIST_EMAIL,addedEmail : email})
            }
        },error => {
            hideLoader()
            dispatch ({type : adminConstants.ADMIN_ADD_WAITLIST_EMAIL_FAILED,addEmailFailed : error})
        })
    }
}
function resetAddEmailWaitlist(){
    return dispatch => {
        dispatch ({type : adminConstants.ADMIN_ADD_WAITLIST_EMAIL,addedEmail : []})
    }
}
function resetAddEmailFailed(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_ADD_WAITLIST_EMAIL_FAILED,addEmailFailed : ''})
    }
}
function getWaitlistCount(){
    return dispatch => {
        showLoader()
        adminServices.getWaitlistCount()
        .then(email => {
            hideLoader()
            if(email){
                dispatch({type : adminConstants.ADMIN_GET_WAITLIST_COUNT,emailCount : email})
            }
        },error => {
            hideLoader()
        })
    }
}
function resetemailCount(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_GET_WAITLIST_COUNT,emailCount : ''})
    }
}
function getSoldCount(pfpId,slotId){
    return dispatch => {
        showLoader()
        adminServices.getSoldCount(pfpId,slotId)
        .then(count => {
            hideLoader()
            if(count){
                dispatch({type : adminConstants.ADMIN_GET_SOLD_COUNT,soldCount : count})
            }
        },error => {
            hideLoader()
        })
    }
}
function resetSoldCount(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_GET_SOLD_COUNT,soldCount : []})
    }
}
function validateBearTransaction(obj,pfpId,slotId){
    return dispatch => {
        adminServices.validateBearTransaction(obj,pfpId,slotId)
        .then(res => {
            if(res){
                dispatch({type : adminConstants.ADMIN_BEAR_VALIDATION, validateTransaction : res})
            }
        },error => {
            hideLoader()
            dispatch({type : adminConstants.ADMIN_BEAR_VALIDATION_FAILED,validationFailed : error})
        })
    }
}
function resetValidationSuccess(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_BEAR_VALIDATION, validateTransaction : []})
    }
}
function resetValidationFailed(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_BEAR_VALIDATION_FAILED,validationFailed : []})
    }
}
function sendBearToAddress(obj,pfpId,slotId){
    return dispatch => {
        showLoader()
        adminServices.sendBearToAddress(obj,pfpId,slotId)
        .then(res => {
            hideLoader()
            if(res){
                dispatch({type: adminConstants.ADMIN_SEND_BEAR_TOADDRESS, sendbearSuccess : res})
            }
        },error => {
            hideLoader()
            dispatch({type : adminConstants.ADMIN_SEND_BEAR_FAILED, sendBearFailed : error})
        })
    }
}
function resetSendBearSuccess(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_SEND_BEAR_TOADDRESS, sendbearSuccess : []})
    }
}
function resetSendBearFailed(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_SEND_BEAR_FAILED, sendBearFailed : []})
    }
}
function verifyBearTransferCode(obj,pfpId,slotId){
    return dispatch => {
        adminServices.verifyBearTransferCode(obj,pfpId,slotId)
        .then(res => {
            if(res){
                dispatch({type: adminConstants.ADMIN_BEAR_VERIFICATION, transactionVerified : res})
            }
        },error => {
            hideLoader()
            dispatch({type : adminConstants.ADMIN_BEAR_VERIFICATION_FAILED, verificationFailed : error})
        })
    }
}
function resetBearVerification(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_BEAR_VERIFICATION, transactionVerified : []})
    }
}
function resetBearVerificationFailed(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_BEAR_VERIFICATION_FAILED, verificationFailed : []})
    }
}
function getAllEvents(obj){
    return dispatch => {
        showLoader()
        adminServices.getAllEvents(obj)
        .then (events => {
            hideLoader()
            if(events){
                dispatch({type : adminConstants.ADMIN_GET_EVENTS,getEvents : events})
            }
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetGetEvents(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_GET_EVENTS,getEvents : []})
    }
}
function addEvents(obj){
    return dispatch => {
        showLoader()
        adminServices.addEvents(obj)
        .then (events => {
            hideLoader()
            if(events){
                dispatch({type : adminConstants.ADMIN_ADD_EVENTS,addEvent : events})
            }
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetAddEvent(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_ADD_EVENTS,addEvent : []})
    }
}
function getEventDetail(id){
    return dispatch => {
        showLoader()
        adminServices.getEventDetail(id)
        .then (events => {
            hideLoader()
            if(events){
                dispatch({type : adminConstants.ADMIN_GET_EVENT_DETAIL,eventDetail : events})
            }
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetGetEventDetail(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_GET_EVENT_DETAIL,eventDetail : []})
    }
}
function updateEvent(obj){
    return dispatch => {
        showLoader()
        adminServices.updateEvent(obj)
        .then (events => {
            hideLoader()
            if(events){
                dispatch({type : adminConstants.ADMIN_UPDATE_EVENT,updatedEvent : events})
            }
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetupdateEvent(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_UPDATE_EVENT,updatedEvent : []})
    }
}
function addEventGallery(id,obj){
    return dispatch => {
        showLoader()
        adminServices.addEventGallery(id,obj)
        .then (events => {
            hideLoader()
            if(events){
                dispatch({type : adminConstants.ADMIN_ADD_EVENT_GALLERY,e_galleryAdded : events})
            }
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetAddEventGallery(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_ADD_EVENT_GALLERY,e_galleryAdded : []})
    }
}
function getAllEventGallery(obj,id){
    return dispatch => {
        showLoader()
        adminServices.getAllEventGallery(obj,id)
        .then (events => {
            hideLoader()
            if(events){
                dispatch({type : adminConstants.ADMIN_GET_EVENT_GALLERY,eventGallery : events})
            }
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetGetAllEventGallery(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_GET_EVENT_GALLERY,eventGallery : []})
    }
}
function deleteEventGallery(id,itemId){
    return dispatch => {
        showLoader()
        adminServices.deleteEventGallery(id,itemId)
        .then(events => {
            hideLoader()
            toast.success("Deleted Successfully")
            let obj = {
                statusCode : 200
            }
                dispatch({type : adminConstants.ADMIN_DELETE_EVENT_GALLERY,deletedEventGallery : obj})
            
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetDeletedEventGallery(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_DELETE_EVENT_GALLERY,deletedEventGallery : []})
    }
}