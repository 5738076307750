import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types";

export const getFlowAccountStorageDetails = async (userAddress) => {
    let scriptcode = `
            import FlowStorageFees from ${process.env.REACT_APP_FLOW_STORAGE}
            pub fun main(address: Address): {String: UInt64} {
                let account = getAccount(address)
                return { "storageUsed": account.storageUsed, "storageCapacity": account.storageCapacity }
            }
            `;
    try {
        const args = [];
        args.push(
            fcl.arg(userAddress, t.Address)
        )
        let scriptresult = await fcl.send([
            fcl.script(scriptcode),
            fcl.args(args)
        ]);
        const result = scriptresult["encodedData"]["value"];
        let storageCapacity = 0;
        let storageUsed = 0;
        for (const detail of result) {
            if (detail.key.value === 'storageCapacity') {
                storageCapacity = parseFloat(detail.value.value);
            }
            if (detail.key.value === 'storageUsed') {
                storageUsed = parseFloat(detail.value.value);
            }
        }
        const retVal = {
            storageCapacity,
            storageUsed
        }
        return {
            status: 200,
            data: { "message": "User DisruptNow Token", "Value": retVal }
        };
    }
    catch (e) {
        return {
            status: 400,
            data: { "message": "Exception happens", "Error": String(e) }
        };
    }
}
export const getAverageNftSize = () => {
    // STatic value calculated manually
    return 2014;
}








