import {babServices} from '../../services'
import { babConstants } from "../../constants"
import { toast } from 'react-toastify';
import PubSub from 'pubsub-js';
import { getUserAddress } from '../../Utils';

export const babActions = {
    getArts,
    resetAllArts,
    getAttributeFilters,
    resestAttributeFilters,
    registerUser,
    resetRegisterUser,
    verifyUser,
    resetVerifyUser,
    resetRegisterUserFailed,
    resetVerifyFailUser,
    getUSERADDRESS,
    resetAddress,
    emailwalletPurchase,
    resetEmailWalletPurchase,
    resetEmailWalletPurchaseFailed,
    paymentInt,
    resetPaymentInt,
    resetPaymentIntFail
}

function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
}
function getArts(pfpId,slotId,obj){
    return dispatch => {
        // showLoader()
        babServices.getArts(pfpId,slotId,obj)
        .then(arts => {
            hideLoader()
            if(arts){
                dispatch({type:babConstants.GET_ARTS,allArts:arts})
               
            }
        },error => {
            hideLoader()
        })
    }
}
function resetAllArts(){
    return dispatch => {
        dispatch({type : babConstants.GET_ARTS,allArts:[]})
    }
}
function getAttributeFilters(pfpId,slotId,obj){
    return dispatch => {
        babServices.getAttributeFilters(pfpId,slotId,obj)
        .then(attr => {
            if(attr){
                dispatch({type : babConstants.GET_ATTRIBUTE_FILTERS,frontAttr : attr})
            }
        },error => {})
    }
}
function resestAttributeFilters(){
    return dispatch => {
        dispatch({type : babConstants.GET_ATTRIBUTE_FILTERS,frontAttr : []})
    }
}
function registerUser(obj){
    return dispatch => {
        showLoader()
        babServices.registerUser(obj)
        .then(user => {
            hideLoader()
            if(user){
                dispatch({type : babConstants.REGISTER_USER,r_user : user})
            }
        },error => {
            hideLoader()
            dispatch({type : babConstants.REGISTER_USER_FAILED,r_user_fail : error})
        })
    }
}
function resetRegisterUser(){
    return dispatch => {
        dispatch({type : babConstants.REGISTER_USER,r_user : []})
    }
}
function resetRegisterUserFailed(){
    return dispatch => {
        dispatch({type : babConstants.REGISTER_USER_FAILED,r_user_fail : []})

    }
}
function verifyUser(obj){
    return dispatch => {
        showLoader()
        babServices.verifyUser(obj)
        .then(user => {
            hideLoader()
            if(user){
                dispatch({type : babConstants.VERIFY_USER,v_user : user})
            }
        },error => {
            hideLoader()
            dispatch({type: babConstants.VERIFY_USER_FAIL,v_user_fail : error})
        })
    }
}
function resetVerifyUser(){
    return dispatch => {
        dispatch({type : babConstants.VERIFY_USER,v_user : []})
    }
}
function resetVerifyFailUser(){
    return dispatch => {
        dispatch ({type : babConstants.VERIFY_USER_FAIL,v_user_fail:[] })
    }
}
function getUSERADDRESS(){
    return dispatch => {
        dispatch({type : babConstants.GET_USER_ADDRESS,useraddress : getUserAddress()})
    }
}
function resetAddress(){
    return dispatch => {
        dispatch({type : babConstants.GET_USER_ADDRESS,useraddress : ''})
    }
}
function emailwalletPurchase(obj,values){
    return dispatch => {
        showLoader()
        babServices.emailwalletPurchase(obj,values)
        .then(user => {
            hideLoader()
            if(user){
                dispatch({type : babConstants.EMAIL_WALLET_PURCHASE,emailWallet : user})
            }
        },error => {
            dispatch({type : babConstants.EMAIL_WALLET_PURCHASE_FAILED,emailWalletFailed : error})
            hideLoader()
        })
    }
}
function resetEmailWalletPurchase(){
    return dispatch => {
        dispatch({type : babConstants.EMAIL_WALLET_PURCHASE,emailWallet : []})
    }
}
function resetEmailWalletPurchaseFailed(){
    return dispatch => {
        dispatch({type : babConstants.EMAIL_WALLET_PURCHASE_FAILED,emailWalletFailed:''})
    }
}
function paymentInt(obj){
    return dispatch => {
        showLoader()
        babServices.paymentInt(obj)
        .then(res => {
            hideLoader()
            if(res){
                dispatch({type : babConstants.PAYMENT_INTENT,paymentIntRes : res})
            }
        },error => {
            dispatch({type : babConstants.PAYMENT_INTENT_FAILED,paymentIntFail : error})
            hideLoader()
        })
    }
}
function resetPaymentInt(){
    return dispatch => {
        dispatch({type : babConstants.PAYMENT_INTENT,paymentIntRes : []})
    }
}
function resetPaymentIntFail(){
    return dispatch => {
        dispatch({type : babConstants.PAYMENT_INTENT_FAILED,paymentIntFail:''})
    }
}