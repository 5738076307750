import React, { Component, Fragment, useEffect, useState, useRef, useCallback, Suspense } from 'react'
import { useDispatch, useSelector } from "react-redux";

import Img3 from "../Assets/images/sec1_img3.png";
import { useNavigate } from 'react-router'
import { Modal, Button } from "react-bootstrap";
import { babServices } from '../services';
import { babActions } from '../store/actions';
import { configPath } from '../config';
import { MintWaitList } from './MintWaitList';
import InfiniteScroll from 'react-infinite-scroll-component';
import Img6 from "../Assets/images/logo192.png";
import { isConnectWallet } from '../helpers/authorizedData';
import { getUserAddress, purchaseTokens, purchaseTokenss } from '../Utils';
import { ConnectDapper } from '../helpers/connectDapper';
import PubSub from 'pubsub-js';
import Select from 'react-select';
import { createSetUp, setupExists } from '../Utils/disrupt';

let controller = new AbortController();

function TestMint() {

    const navigate = useNavigate()
    const dispatch = useDispatch() //dispatch action
    const arts = useSelector(state => state.babreducer.allArts)
    const attributes = useSelector(state => state.babreducer.frontAttr)
    const emailWalletResponse = useSelector(state => state.babreducer.emailWallet)
    const emailWalletFailedRes = useSelector(state => state.babreducer.emailWalletFailed)
    const payResIntent = useSelector(state => state.babreducer.paymentIntRes)
    const payIntFail = useSelector(state => state.babreducer.paymentIntFail)
    //states
    const [artsList, setArtsList] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [selectType1, setSelectType1] = useState(0) // 1st filter state
    const [selectType2, setSelectType2] = useState(0) // 2nd filter state
    const [selectType3, setSelectType3] = useState(0) // 3rd filter state
    const [offset, setOffset] = useState(0)
    const [recordLimit, setRecordLimit] = useState(16)
    const [filtType1, setFilterType1] = useState([{ attribute: "Select trait", value: 0 }])
    const [filtType2, setFilterType2] = useState([{ attribute: "Select trait", value: 0 }])
    const [filtType3, setFilterType3] = useState([{ attribute: "Select trait", value: 0 }])
    const [filter1, setFilter1] = useState([])
    const [filter2, setFilter2] = useState([])
    const [filter3, setFilter3] = useState([])
    const [loader, setLoader] = useState(false)
    const [changeFilter, setChangeFilter] = useState(false)
    const [hasMore, sethasMore] = useState(true);
    const [isEmailPage, setIsEmailPage] = useState(false)
    const [openSuccessModal, setOpenSuccessModal] = useState(false)
    const [errorMes, setErrorMes] = useState('')
    const [openErrorModal, setOpenErrorModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState([])
    const [txnError,setTxnError] = useState("Unexpected error has occurred. Please try again later")
    const [isAccSetup,setIsAccSetup] = useState(false)
    const customStyles = {
        control: (base, state) => ({
            ...base,
            minWidth: "200px",
            background: "transparent",
            color: "white",
            // match with the menu
            borderRadius: state.isFocused ? "10px 10px 10px 10px" : "10px 10px 10px 10px",
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "white" : "white",
            borderWidth: state.isFocused ? "2px" : "2px",
            fontWeight: state.isFocused ? "bold" : "bold",
            position: state.isFocused ? "relative" : "relative",
    
            //   top:state.isFocused ? "8px" : "8px",
            minHeight: state.isFocused ? "41px" : "41px",
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: state.isFocused ? "white" : "white",
    
            }
        }),
        menu: base => ({
            ...base,
            // override border radius to match the box
            borderRadius: 10,
            // kill the gap
            marginTop: 0,
            backgroundColor: "#cc7700"
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
        }),
        singleValue: base => ({
            ...base,
            color: "white"
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);
            // console.log({ data, isDisabled, isFocused, isSelected });
            return {
                ...styles,
                backgroundColor: isFocused ? "#797979" : null,
                color: "white",
                cursor: "pointer"
            };
        }
    }
    const fetchArts = () => {
        controller = new AbortController();
        let signal = controller.signal;
        let array = []
        let obj = {
            offSet: offset,
            recordLimit: recordLimit,
            signal
        }
        console.log(selectType1,selectType2,selectType3)
        if (Number(selectType1 && selectType1.value)) {
            array.push(Number(selectType1.value))
        }
        if (Number(selectType2 && selectType2.value)) {
            array.push(selectType2.value)
        }
        if (Number(selectType3 && selectType3.value)) {
            array.push(selectType3.value)
        }
        if (array && array.length > 0) {
            obj['attributeIds'] = array.join()
            console.log(array, array.join())
        }
        dispatch(babActions.getArts((configPath.pfpId), (configPath.slotId), (obj)))
    }

    const fetchFilterAttributes = () => {
        dispatch(babActions.getAttributeFilters((configPath.pfpId)))
    }

    useEffect(async() => {
        
        var hours = 6; // to clear the localStorage after 6 hour
        // (if someone want to clear after 8hrs simply change hours=8)
        var now = new Date().getTime();
        var setupTime = localStorage.getItem('setupTime');
        var user = localStorage.getItem('User')
        if (setupTime && user) {
            if ((now - setupTime) > hours * 60 * 60 * 1000) {
                localStorage.removeItem('setupTime')
                localStorage.removeItem('User')
                setIsEmailPage(true)
            } else {
                
                setIsEmailPage(false)
                fetchArts() // fetch ArtsList
                 fetchFilterAttributes() // fetch filter attributes 
              
            }
        } else {
            setIsEmailPage(true)
        }
        // setOpenModal(true)
        // setOpenSuccessModal(true)

    }, [])

    useEffect(() => {
        if ((arts && arts.statusCode) === 200) {
            dispatch(babActions.resetAllArts())
            if (arts && arts.data && arts.data.arts) {
                setArtsList([...artsList, ...arts.data.arts]);

                if (arts.data.arts.length === 0 || (arts.data.arts.length < (recordLimit))) {
                    sethasMore(false);
                    setLoader(false)
                }
                const page = Number(recordLimit) + Number(offset)
                setOffset(page)
            }
        }
    },[arts])



    //getRecieveProps when attributes change
    useEffect(async () => {
        let filters = []
        let filters1 = []
        let filters2 = []
        let filters3 = []
        console.log(attributes)
        if ((attributes && attributes.statusCode) === 200) {

            console.log(attributes)
            dispatch(babActions.resestAttributeFilters())
            await attributes && attributes.data && attributes.data.attributeFilters && (attributes.data.attributeFilters.length > 0) &&
                attributes.data.attributeFilters.map((item, index) => {
                     filters.push(item)
                })

            filters1 = await filters && filters[0] && filters[0]
            filters2 = await filters && filters[1] && filters[1]
            filters3 = await filters && filters[2] && filters[2]
            setFilter1(filters1)
            setFilter2(filters2)
            setFilter3(filters3)
                let fisrstFileterArray = []
                let secondFilterArray = []
                let thirdFilterArray = []
            await filters1 && filters1.attributeDetails && filters1.attributeDetails.length > 0 &&
                filters1.attributeDetails.map(attr => {
                    let obj = {
                        label: attr && attr.name,
                        value: attr && attr.id
                    }
                    // filtType1.push(obj)
                    fisrstFileterArray.push(obj)
                    
                })
                setFilterType1(fisrstFileterArray)
            await filters2 && filters2.attributeDetails && filters2.attributeDetails.length > 0 &&
                filters2.attributeDetails.map(attr => {
                    let obj = {
                        label: attr && attr.name,
                        value: attr && attr.id
                    }
                    // filtType2.push(obj)
                    secondFilterArray.push(obj)
                    
                })
                setFilterType2(secondFilterArray)
            await filters3 && filters3.attributeDetails && filters3.attributeDetails.length > 0 &&
                filters3.attributeDetails.map(attr => {
                    let obj = {
                        label: attr && attr.name,
                        value: attr && attr.id
                    }
                    // filtType3.push(obj)
                    thirdFilterArray.push(obj)
                })
                setFilterType3(thirdFilterArray)

        }
    },[attributes])

    const attributeDropdown1 = filtType1 && filtType1.length > 0 && filtType1.map((filType) => {
        return <option value={filType.value} key={filType.value}>{filType.attribute}</option>
    });
    const attributeDropdown2 = filtType2.map((filType) => {
        return <option value={filType.value} key={filType.value}>{filType.attribute}</option>
    });
    const attributeDropdown3 = filtType3.map((filType) => {
        return <option value={filType.value} key={filType.value}>{filType.attribute}</option>
    });


    const closeModal = () => {
        setOpenModal(false)

    }
    const closeSuccessModal = () => {
        setOpenSuccessModal(false)
        setSelectedItem([])
    }
    const closeErrorModal = () => {
        setOpenErrorModal(false)
    }
    const handleSubmit = (e, form) => {
        e.preventDefault()
        setOpenModal(false)
        if (document.getElementById("mc-embedded-subscribe-form")) {
            document.getElementById("mc-embedded-subscribe-form").action = `${process.env.REACT_APP_WAITIST_FORM_URL}`
            document.getElementById("mc-embedded-subscribe-form").method = "POST"
            document.getElementById("mc-embedded-subscribe-form").submit()

        }
    }
    useEffect(() => {
        if (changeFilter) {
            controller.abort()
            controller = new AbortController();
            setChangeFilter(false)
            sethasMore(true)
            setLoader(true)
            fetchArts()
            console.log("artsList", artsList)
        }
    }, [changeFilter])

    const handleAttributeTypes1 = (e) => {
        console.log(e)
        setSelectType1(e)
        setOffset(0)
        setArtsList([])
        setChangeFilter(true)
    }
    const handleAttributeTypes2 = (e) => {
        setSelectType2(e)
        setOffset(0)
        setArtsList([])
        setChangeFilter(true)
    }
    const handleAttributeTypes3 = (e) => {
        setSelectType3(e)
        setOffset(0)
        setArtsList([])
        setChangeFilter(true)
    }
    const handleAllArts = () => {
        setSelectType1(0)
        setSelectType2(0)
        setSelectType3(0)
        setArtsList([])
        setOffset(0)
        setChangeFilter(true)
    }
    const updateEmail = (value) => {
        if (value === "true") {
            setOffset(0)
            setArtsList([])
            setChangeFilter(true)
            setIsEmailPage(false)
            fetchFilterAttributes()

        } else {
            setIsEmailPage(true)
        }
        console.log(value)
    }
    function showLoader() {
        PubSub.publish('msg', true);
    }

    function hideLoader() {
        PubSub.publish('msg', false);
    }
    const emailWalletSendApi = (item) => {
        let user = JSON.parse(localStorage.getItem('User'))
        if (user && user !== undefined && user !== null && user != '') {
            let selectedParams = {
                slotId : configPath.slotId,
                pfpId : configPath.pfpId,
                offSet: offset,
                recordLimit: recordLimit,
            }
            let array = []
            if (Number(selectType1 && selectType1.value)) {
                array.push(Number(selectType1.value))
            }
            if (Number(selectType2 && selectType2.value)) {
                array.push(selectType2.value)
            }
            if (Number(selectType3 && selectType3.value)) {
                array.push(selectType3.value)
            }
            if (array && array.length > 0) {
                selectedParams['attributeIds'] = array.join()
                console.log(array, array.join())
            }
            let obj = {
                email: user,
                walletAddress: getUserAddress(),
                // artId : item && item.id
                // artId : 1
            }
            if (obj && (obj.walletAddress) && (obj.walletAddress != '' && obj.walletAddress !== null && obj.walletAddress !== undefined)) {
                dispatch(babActions.emailwalletPurchase((JSON.stringify(obj)), selectedParams))
                setSelectedItem(item)
            }
        }
    }
    //trigger purchase button
    const handleDapperPurchase = async (e, item) => {
        e.preventDefault()
        console.log(item)
        //check wallet connection
        if (isConnectWallet()) {
            //if true call emailwallet send api call
            emailWalletSendApi(item)
            
        } else {
            //otherwise connect dapper
            showLoader()
            ConnectDapper().then(res => {
                hideLoader()
                if ((res && res.addr && res.addr != null)) {
                    setOpenErrorModal(false)

                    dispatch(babActions.getUSERADDRESS())

                    //call emailwallet send api call
                    emailWalletSendApi(item)
                } else {
                    setErrorMes("Couldn't connect wallet account")
                    setOpenErrorModal(true)
                    console.log("---failed---", res)
                }
            }).catch(error => {
                setErrorMes("Couldn't connect wallet account")
                setOpenErrorModal(true)
                console.log("---dapper error---", error)
                hideLoader()
            })
        }
    }
    useEffect(() => {
        if ((emailWalletResponse && emailWalletResponse.statusCode) === 201) {
            dispatch(babActions.resetEmailWalletPurchase())
            setOpenErrorModal(false)
            // purchaseEvent(emailWalletResponse)
            paymentCall(emailWalletResponse)
        }
    }, [emailWalletResponse])
    useEffect(() => {
        if (emailWalletFailedRes && emailWalletFailedRes.length > 0) {
            dispatch(babActions.resetEmailWalletPurchaseFailed())
            setErrorMes(emailWalletFailedRes)
            setOpenErrorModal(true)
        }
    }, [emailWalletFailedRes])

    useEffect(() => {
        if((payResIntent && payResIntent.statusCode) === 200){
            if(payResIntent && payResIntent.data && payResIntent.data.clientSecret && payResIntent.data.clientSecret != null){
                navigate('/checkout')
            }else{
                setErrorMes("Oops! Client secret missing")
                setOpenErrorModal(true)
            }
        }
    },[payResIntent])
    useEffect(() => {
        if((payIntFail && payIntFail.length > 0)){
            dispatch(babActions.resetPaymentIntFail())
            setErrorMes("Error occured while create payment intent. Please try again later")
            setOpenErrorModal(true)
        }
    },[payIntFail])
    const paymentCall = async (response) => {
        console.log(response)
        if((response && response.data && response.data.artId && response.data.artId != null)){
            setOpenErrorModal(false)
            let user = await JSON.parse(localStorage.getItem('User'))
            if (user && user !== undefined && user !== null && user != '') {
            let obj = {
                email: user,
                walletAddress: getUserAddress(),
                // artId : item && item.id
                artId : response.data.artId
            }
            dispatch(babActions.paymentInt(JSON.stringify(obj)))
            // try {
            //     const payResponse = babServices.paymentInt(JSON.stringify(obj))
            //     console.log(payResponse)
            //     payResponse.then(payRes => {
            //         if((payRes && payRes.statusCode) === 200){
            //             if(payRes && payRes.data && payRes.data.clientSecret && payRes.data.clientSecret != null){
            //                 navigate('/checkout',{state : {clientsecret:payRes.data.clientSecret}})
            //             }else{
            //                 setErrorMes("Oops! Client secret missing")
            //                 setOpenErrorModal(true)
            //             }
            //         }else{
            //             setErrorMes("Error occured while create payment intent. Please try again later")
            //             setOpenErrorModal(true)
            //         }
            //     }).catch(e => {
            //         setErrorMes("Error occured while create payment intent. Please try again later")
            //         setOpenErrorModal(true)
            //     })
            // }catch(err){
            //     if(err && err.length > 0){
            //         setErrorMes("Error occured while create payment intent. Please try again later")
            //         setOpenErrorModal(true)
            //     }else{
            //         setErrorMes(err)
            //         setOpenErrorModal(true)
            //     }
            // }
        }
    }else{
        setErrorMes("Bear is sold out")
        setOpenErrorModal(true)
    }
    }
    const purchaseEvent = async (id) => {
        if (id) {
            setOpenErrorModal(false)
            showLoader()
            purchaseTokens(id, (process.env.REACT_APP_PURCHASE_WALLET_ADDRESS), (process.env.REACT_APP_PURCHASE_PRICE)).then(res => {
                hideLoader()
                if ((res && res.status) === 200) {
                    if ((res && res.data && res.data.transaction && res.data.transaction.errorMessage)) {
                        setErrorMes("Transaction failed; Please try again later")
                        setOpenErrorModal(true)
                        setOpenSuccessModal(false)

                    }else{
                        setOpenSuccessModal(true)
                        setOpenErrorModal(false)
                        handleAllArts()
                    }

                    console.log("Purchase Res", res)
                } else {
                    console.log(res)
                    if((res && res.data && res.data.Error)){
                        if(res.data.Error.toLowerCase().includes('declined')){
                            setErrorMes("User rejected transaction")
                            setOpenErrorModal(true)
                            setOpenSuccessModal(false)
                        }else{
                        setErrorMes(res.data.Error)
                        setOpenErrorModal(true)
                        setOpenSuccessModal(false)
                        }
                    }
                }
            }).catch(e => {
                hideLoader()
                setErrorMes("Transaction failed;Unexpected error occured")
                setOpenErrorModal(true)
                setOpenSuccessModal(false)
                console.log("Purchase error", e)
            })
        }else{
            // call api for get clientsecret key
           
            // navigate('/checkout',{state : {clientsecret:"pi_3LCHWjSEceklj1Cy174QT2vJ_secret_cOKu8Vt81fbv7Zqct6ShR41b6"}})
            setErrorMes("Transaction failed : Token not available")
            setOpenErrorModal(true)

        }
    }
    const checkSetup = async (item) => {
        const address = await getUserAddress()
        if(address && address != null && address != undefined && address != ''){
            setOpenErrorModal(false)
            showLoader()
            //check setup already exist
            const checkingSetup = await setupExists(address)
            if(checkingSetup && checkingSetup.status === 200){
                hideLoader()
                if(checkingSetup && checkingSetup.data && checkingSetup.data.Value === "0"){
                    //if not setup, create account setup
                    console.log(checkingSetup)
                    setIsAccSetup(true)
                    const createSetupRes = await createSetUp()
                    console.log(createSetupRes)
                    if(createSetupRes && createSetupRes.status === 200){
                        setIsAccSetup(false)
                        if((createSetupRes && createSetupRes.data && createSetupRes.data.transaction && createSetupRes.data.transaction.errorMessage && createSetupRes.data.transaction.errorMessage != "")){
                            setErrorMes("Transaction failed : Account setup failed,Unexpected error has occurred")
                            setOpenErrorModal(true)
                        }else{
                            emailWalletSendApi(item)
                        }
                    }else{
                        setIsAccSetup(false)
                        if((createSetupRes && createSetupRes.status === 400)){
                            if((createSetupRes && createSetupRes.data && createSetupRes.data.message)){
                                setErrorMes(getError(createSetupRes))
                                setOpenErrorModal(true)
                            }else{
                                setErrorMes(txnError)
                                setOpenErrorModal(true)
                            }
                        }else{
                            setErrorMes(txnError)
                            setOpenErrorModal(true)
                        }
                    }
                }else{
                    emailWalletSendApi(item)
                }
            }else{
                hideLoader()
                if((checkingSetup && checkingSetup.status === 400)){
                    if((checkingSetup && checkingSetup.data && checkingSetup.data.message)){
                        setErrorMes(getError(checkingSetup))
                        setOpenErrorModal(true)
                    }else{
                        setErrorMes(txnError)
                        setOpenErrorModal(true)
                    }
                }else{
                    setErrorMes(txnError)
                    setOpenErrorModal(true)
                }
            }
            console.log(checkingSetup)
            
        }else{
            setErrorMes("Could not find your wallet account")
            setOpenErrorModal(true) 
        }
    }
    const handleStripePurchase = (e,item) => {
        
        e.preventDefault()
        console.log(item)
        //check wallet connection
        if (isConnectWallet()) {
            //if true call emailwallet send api call
            // emailWalletSendApi(item)
            checkSetup(item)
            
        } else {
            //otherwise connect dapper
            showLoader()
            ConnectDapper().then(res => {
                hideLoader()
                if ((res && res.addr && res.addr != null)) {
                    setOpenErrorModal(false)

                    dispatch(babActions.getUSERADDRESS())

                    //call emailwallet send api call
                    // emailWalletSendApi(item)
                    checkSetup(item)
                } else {
                    setErrorMes("Couldn't connect wallet account")
                    setOpenErrorModal(true)
                    console.log("---failed---", res)
                }
            }).catch(error => {
                setErrorMes("Couldn't connect wallet account")
                setOpenErrorModal(true)
                console.log("---dapper error---", error)
                hideLoader()
            })
        }
    }
    const getError = (txnResponse) => {
        const error = txnResponse.data.Error
        let mes = 'Transaction failed : '; 
        const splitError = error.split(':');
        if (
            splitError[2] &&
            splitError[2].includes("Session expired")
        ) {
            mes += "Session Expired.Please reconnect your wallet account";
        } else if (splitError[1]) {
            mes += splitError[1]
        } else {
            mes += splitError[0];
        }
        console.log(splitError[2],splitError[1],splitError[0])
        return (mes)
    }
    const handlePurchase1 = async () => {
        const res = await purchaseTokens(293136394,"0x0feb0de15fff8a89","1.0")
        raiseModalWithRes(res) 
    }
    const raiseModalWithRes = (res) => {
        if((res && res.status) === 200){
            console.log("Response---------",res)
            if ((res && res.data && res.data.transaction && res.data.transaction.errorMessage)) {
                setErrorMes(JSON.stringify(res))
                setOpenErrorModal(true)
            }else{
                setOpenErrorModal(true)
                setErrorMes(JSON.stringify(res))
            }
        }else{
            setErrorMes(JSON.stringify(res))
            setOpenErrorModal(true)
        }
    }
    return (
        <Fragment>
            {isAccSetup && 
                    <div className="show-content">
                        <div className="child-show purchasebox">
                            <p className="text-white">Accessing your Flow wallet account</p>
                            <div className='loader'></div>

                        </div>
                    </div>
                }
            <Modal show={openSuccessModal} onHide={(e) => closeSuccessModal(e, this)} size="lg" className="bab-popup babmsgpopu" aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Body>

                    <div className="form-group my-5 text-center">
                    <img src={Img6} alt="" class="mw-100 mb-3 noimgfound" />
                        <div class="success-checkmark">
                            <div class="check-icon">
                                <span class="icon-line line-tip"></span>
                                <span class="icon-line line-long"></span>
                                <div class="icon-circle"></div>
                                <div class="icon-fix"></div>
                            </div>
                        </div>
                        <h2 className='mt-3 mb-5'>Woohoo! Art purchased successfully</h2>

                        <Button variant="outline-primary" onClick={closeSuccessModal}>
                            Close
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={openErrorModal} onHide={(e) => closeErrorModal(e, this)} size="lg" className="bab-popup" aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Body>
                    <div className="modal-body d-flex flex-column align-items-center">
                        <div className="form-group my-5 text-center">
                            <h4>{errorMes}</h4>
                        </div>
                        <Button variant="outline-primary" onClick={closeErrorModal}>
                            Close
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={openModal} onHide={(e) => closeModal(e, this)} size="lg" className="bab-popup orangebg1" aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header >
                    <Modal.Title>BEARCAVE</Modal.Title>
                    <Button variant="outline-primary" onClick={closeModal}>
                        Close <i className="fa fa-times mr-0"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group mt-3 text-center">
                        <div id="mc_embed_signup">
                            <form onSubmit={handleSubmit} id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
                                <div id="mc_embed_signup_scroll" className='row'>
                                    {/* <h2>Subscribe</h2> */}
                                    {/* <div className="indicates-required col-sm-12"><span className="asterisk">*</span> indicates required</div> */}
                                    <div className="mc-field-group form-group mb-4 col-sm-6">

                                        <input type="email" name="EMAIL" className="required email form-control" id="mce-EMAIL" placeholder='Email' required />
                                    </div>
                                    <div className="mc-field-group form-group mb-4 col-sm-6">
                                        <input type="text" name="FNAME" className="form-control" id="mce-FNAME" placeholder='Name' required />
                                    </div>
                                    <div className="mc-field-group form-group mb-4 col-sm-6">
                                        <input type="text" name="TWITTER" className="form-control" id="mce-TWITTER" placeholder='Twitter' />
                                    </div>
                                    <div className="mc-field-group form-group mb-4 col-sm-6">
                                        <input type="text" name="DISCORD" className="form-control" id="mce-DISCORD" placeholder='Discord' />
                                    </div>
                                    <div id="mce-responses" className="clear">
                                        <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
                                        <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                                    </div>

                                    <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                                        <input type="text" name="b_ae99ac9a4fafd8609771595ff_fe021b9892" tabIndex="-1" />
                                    </div>

                                    <div className="form-group col-sm-12 mt-4">
                                        <input type="submit" value="Join Waitlist" name="subscribe" id="mc-embedded-subscribe" className="btn btn-primary " />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            
            {isEmailPage && <MintWaitList updatePage={(val) => updateEmail(val)} />}
            {/* <div className="mintpage orangebg">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4 offset-md-2">
                            <img src={Img1} />
                        </div>
                        <div className="col-md-4">
                            <div className='mint-content'>
                                <p className="waitinglist-p mb-3">Mint NFT</p>
                                <h1 className="waitinglist-h mb-3">Badazz Bears</h1>
                                <p className="mb-4">1,801 Bears will be released to  members of the Bear Cave (Presale List). The 1st Batch of Bears will be unique with some special traits.</p>
                                <a className="btn btn-primary" onClick={gotoBearCave}>Join Bear Cave</a>
                            </div>
                        </div>

                    </div>

                </div>
            </div> */}
            {!isEmailPage && <div className="collection orangebg">
                <div className="container-fluid">
                    <div className="row align-items-center mb-5">
                        <div className='text-center col-md-6 offset-md-3'>
                            <p className="waitinglist-p mb-3">Purchase NFT Badazz Bears for $150 per NFT</p>
                            <h1 className="waitinglist-h mb-3">Badazz Bears</h1>
                            <p className="mb-4">1,801 Bears will be available to mint for members of the Bear Cave (Presale List). The 1st Batch of Bears will be unique with some special OG traits. Some of these traits will not be available in later Batches $150 per NFT</p>
                        </div>
                    </div>

                    {<div className="bb-page-title mintlist">
                        <hr />
                        <div className="row align-items-center justify-content-center">

                            {(filter1 && filter1.name) && <div className='col-md-3 mb-3'>
                                <label className='font-weight-bold w-100'>{filter1 && filter1.name}</label>
                                <Select
                                    value={selectType1}
                                    onChange={handleAttributeTypes1}
                                    options={filtType1}
                                    placeholder="Select trait"
                                    styles={customStyles}
                                />
                             
                            </div>}
                            {(filter2 && filter2.name) && <div className='col-md-3 mb-3'>
                                <label className='font-weight-bold w-100'>{filter2 && filter2.name}</label>
                                <Select
                                    value={selectType2}
                                    onChange={handleAttributeTypes2}
                                    options={filtType2}
                                    placeholder="Select trait"
                                    styles={customStyles}
                                />
                                
                            </div>}
                            {(filter3 && filter3.name) && <div className='col-md-3 mb-3'>
                                <label className='font-weight-bold w-100'>{filter3 && filter3.name}</label>
                                <Select
                                    value={selectType3}
                                    onChange={handleAttributeTypes3}
                                    options={filtType3}
                                    placeholder="Select trait"
                                    styles={customStyles}
                                />
                                
                            </div>}

                            {(Number(selectType1 && selectType1.value) || Number(selectType2 && selectType2.value) || Number(selectType3 && selectType3.value)) ?
                                <div className='col-md-3 text-left'>
                                    <button type="button" className='btn btn-primary' onClick={handleAllArts}>Clear Filters</button>
                                </div> : ''}
                        </div>
                        <hr />
                    </div>}


                    <InfiniteScroll
                        dataLength={artsList && artsList.length} //This is important field to render the next data
                        next={(artsList && artsList.length > 0) ? fetchArts : ''}
                        hasMore={hasMore}
                        loader={<div className="col-md-12 text-center">
                            <div className="loader"></div>
                        </div>}
                        endMessage={
                            (artsList.length > 0) ?
                                <p style={{ textAlign: 'center' }}>
                                    <b>Yay! You have seen it all</b>
                                </p> : (!loader && <div className='text-center'> <img src={Img6} alt="" class="mw-100 mb-3 noimgfound" /><h4 class="text-center d-block">No bears found! May be you are late!</h4></div>)
                        }
                        style={{ overflow: "hidden" }}

                    >
                        <div class="bab-speakers-list">
                            <div class="row">
                                {artsList && (artsList.length > 0) && artsList.map((items, index) => (
                                    <div class="col-sm-6 col-md-3">
                                        <div class="bab-speaker">
                                            <div class="bab-propic"><img src={"/assets/img/David-Bianchi.png"} alt="" class="mw-100" /></div>
                                            {/* <div class="bab-proname">{items && items.id && `#${items.id}`}</div> */}
                                            {/* <div class="bab-prodetail"><button className="btn btn-outline-primary" type="button" onClick={(e) => handleDapperPurchase(e, items)}>Purchase</button></div> */}
                                            <div class="bab-prodetail"><button className="btn btn-outline-primary" type="button" onClick={(e) => handleStripePurchase(e, items)}>Purchase</button></div>

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </InfiniteScroll>



                </div>
            </div>}


        </Fragment>
    )

}


export default TestMint



