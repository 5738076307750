
import {configPath} from '../config'
import { adminToken } from '../helpers/authorizedData';
import { fetchService } from './fetch-service';
import PubSub from 'pubsub-js';

export const babServices = {
    getArts,
    getAttributeFilters,
    registerUser,
    verifyUser,
    emailwalletPurchase,
    paymentInt
}


function getArts(pfpId,slotId,obj){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json','accept' : 'application/json'},
        signal : obj.signal
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''
    let roleId = obj && obj.roleId ? '&roleId=' + obj.roleId : ''
    let pfp = `&pfpId=` + pfpId
    let slot = `&slotId=` + slotId
    let attributes = obj && obj.attributeIds && (obj.attributeIds.length > 0) ? `&attributeDetailIds=` + obj.attributeIds : ''
    console.log(obj.attributeIds)

    params =  offSet + recordLimit + sortBy + sortOrder + search + roleId + pfp + slot + attributes

    return fetchService.fetchResource(`${configPath.apiUrl}/front-app/arts?` + params,requestOptions).then(handleResponse,handleErrorResponse)
}
function getAttributeFilters(pfpId,slotId,obj){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
    let pfp = `&pfpId=` + pfpId
    let slot = obj && obj.selectedIds && (obj.selectedIds.length > 0) && slotId ? `&slotId=` + slotId : ''
    let attributes = obj && obj.selectedIds && (obj.selectedIds.length > 0) ? `&selectedIds=` + obj.selectedIds : ''

    params = pfp + slot + attributes

    return fetchService.fetchResource(`${configPath.apiUrl}/front-app/arts/attributeFilters?` + params,requestOptions).then(handleResponse,handleErrorResponse)
}
function registerUser(obj){
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json','accept' : 'application/json'},
        body : obj
    };
    

    return fetch(`${configPath.apiUrl}/user-managment/register-user`,requestOptions).then(handleResponse,handleErrorResponse)
}
function verifyUser(obj){
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json','accept' : 'application/json'},
        body : obj
    };
    

    return fetch(`${configPath.apiUrl}/user-managment/verify-user?`,requestOptions).then(handleResponse,handleErrorResponse)
}
function emailwalletPurchase(obj,values){
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json','accept' : 'application/json'},
        body : obj
    };
    let params = ''
    let offSet = values && values.offSet != null && values.offSet !== undefined ? "&pageOffset=" + values.offSet : ''

	let recordLimit = values && values.recordLimit ? '&recordLimit=' + values.recordLimit : ''


    let pfp = values && values.pfpId ? `&pfpId=` + values.pfpId : ''
    let slot = values && values.slotId ? `&slotId=` + values.slotId : ''

    let attributes = values && values.attributeIds && (values.attributeIds.length > 0) ? `&attributeDetailIds=` + values.attributeIds : ''
    params = attributes + pfp + slot
    return fetch(`${configPath.apiUrl}/front-app/arts/createEmailWalletPurchaseLog?` + params,requestOptions).then(handleResponse,handleErrorResponse)
}

function paymentInt(obj){
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json','accept' : 'application/json'},
        body : obj
    };
    return fetch(`${configPath.apiUrl}/payment-management/create-payment-intent`,requestOptions).then(handleResponse,handleErrorResponse)
}

function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 403) {
            //    localStorage.removeItem('bab_adminDetail')
			}
            const error = (data && data.data && data.data.data && data.data.data[0] && data.data.data[0][0]) || (data && data.message)
			return Promise.reject(error)
		}
		return data;
	});
}
function handleErrorResponse(error){
    if(error instanceof TypeError){
        console.log(error)
        return Promise.reject("Unexpected error occured")
    }else{
        return Promise.reject("Unexpected error occured")
    }
}