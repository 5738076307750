import React from "react";
import { useNavigate } from 'react-router'


function PageNotFound() {
    const navigate = useNavigate()
    const gotoHome = () => {
        navigate('/')
    }
        return (
            <div>
            <div className="container text-center pagenotfound py-5">
            <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 text-center">
                <h1 class="display-1 d-block">404</h1>
                <div class="mb-4 lead">The page you are looking for was not found.</div>
                <button className="btn btn-primary px-5 mt-3" type="button" onClick={gotoHome}>Back to Home</button>
            </div>
        </div>
    </div>
            </div>
            </div>
        );
}
export {PageNotFound}