import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./config"
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {store} from './store'
import { Provider } from 'react-redux'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <Provider store = {store}>
        <ToastContainer autoClose={3000}/>

    <App />
  </Provider>,
  document.getElementById('root')
);


reportWebVitals();
