import React, { Component, Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from '../../../../store/actions';
import moment from 'moment'
import { ReactPagination } from '../../../../helpers/paginate';
import { ArtPreview } from './ArtPreview';
import { configPath } from '../../../../config';

function FetchEventGallery() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const getEvents = useSelector(state => state.adminreducer.eventGallery)
    const getEventsList = useSelector(state => state.adminreducer.getEvents)
    const deletedEvent = useSelector(state => state.adminreducer.deletedEventGallery)

    const gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    const [events, setEvents] = useState([]);
    const [offSet, setOffSet] = useState(0)
    const [recordLimit, setRecordLimit] = useState(20)
    const [pageCount, setPageCount] = useState('')
    const [callBack, setCallBack] = useState(false)
    const [selectType, setSelectType] = useState(1)
    const [getEve, setGetEve] = useState([]) // for event dropdown
    const [filterType, setFilterType] = useState('')
    const [forcePage,setForcePage] = useState(0)

    const allTypes = [];

    useEffect(() => {
        dispatch(adminActions.getAllEvents({}))

    }, [])
    useEffect(() => {
        if (deletedEvent && deletedEvent.statusCode === 200) {
            dispatch(adminActions.resetDeletedEventGallery())
            initialCall(selectType)
        }
    }, [deletedEvent])
    const initialCall = (id) => {
        gotoTop()
        let obj = {
            offSet: offSet,
            recordLimit: recordLimit,
            sortBy: "createdAt",
            sortOrder: "DESC"
        }
        if (filterType !== '') {
            obj['type'] = filterType
        }
        dispatch(adminActions.getAllEventGallery(obj, id))
    }
    //fetch all events name
    useEffect(() => {
        console.log(getEventsList)
        if (getEventsList && getEventsList.statusCode === 200) {
            dispatch(adminActions.resetGetEvents())
            const allevents = (getEventsList && getEventsList.data && getEventsList.data.events)
            if(allevents && allevents[0] && allevents[0].id){
                initialCall(allevents && allevents[0] && allevents[0].id)
            }
            allevents && allevents.length > 0 && allevents.map(items => {
                let obj = {
                    event: items && items.name,
                    value: items && items.id
                }
                allTypes.push(obj)

            })
            setGetEve(allTypes)

        }
    }, [getEventsList])
    const eventDropDown = getEve.map((filType) => {
        return <option value={filType.value} key={filType.value}>{filType.event}</option>
    });

    useEffect(() => {

        if (getEvents && getEvents.statusCode === 200) {
            dispatch(adminActions.resetGetAllEventGallery())
            if (getEvents && getEvents.data && getEvents.data.gallery) {
                setEvents(getEvents.data.gallery)
                setPageCount(getEvents && getEvents.data && getEvents.data.count / recordLimit)

            }
        }
    }, [getEvents])
    const editAccount = (items) => {
        if ((items && items.id)) {
            navigate(`/creatoradmin/event/edit/${items.id}`)
        }
    }
    const deleteAccount = (items) => {
        // const filterArray = events && events.filter(el => (el && el.id) != (items && items.id))
        // setEvents(filterArray)
        if (items && items.eventId && items.id) {
            dispatch(adminActions.deleteEventGallery(items.eventId, items.id))

        }
    }

    const gotoAddEvent = () => {
        navigate('/creatoradmin/eventgalleryList/add')
    }
    const onPageChange = (data) => {
        let offSet = recordLimit * (data && data.selected)
        setOffSet(offSet)
        setCallBack(true)
    }
    useEffect(() => {
        if (callBack) {
            initialCall(selectType)
            setCallBack(false)
        } else {
            return;
        }
    }, [callBack])
    const handleEventSelect = (e) => {

        setSelectType(e.target.value) //selected rarity type
        setOffSet(0)
        setCallBack(true)
        setForcePage(0)
    }
    const handleFilterType = (e) => {
        setFilterType(e.target.value)
        setCallBack(true)
    }
    const filterDropDown = (
        <select className='form-control' value={filterType} onChange={handleFilterType}>
            <option value={''}>Select filter type</option>
            <option value={"image"}>Image</option>
            <option value={"video"}>Video</option></select>
    )
    const AccountList = events && events.map((items) => {
        return <tr>
            <td>{items && items.eventId}</td>
            <td className='userdescrip_adm' title={items && items.caption}>{items && items.caption}</td>
            <td><ArtPreview src={`${configPath.baseUrl}${items && items.path}`} mimeType={items && items.mimeType}/></td>
            <td>{items && items.createdAt && moment(items.createdAt).format('DD/MM/YYYY hh:mm:ss A')}</td>
            <td>
                <a className="btn btn-outline-primary btn-sm btn-action mr-2" type="button" onClick={() => { deleteAccount(items) }} data-toggle="tooltip" data-placement="bottom" title="Delete"><i className="fa fa-trash" aria-hidden="true"></i></a>
            </td>
        </tr>
    })
    return (
        <Fragment>
            <div className="smallilac px-3">
                <div className='ca-card mb-3 '>
                    <div className="row admintitle align-items-center">
                        <div className="col-sm-3"><h2 className="font-weight-bold">Event Gallery  </h2></div>
                    </div>
                </div>
                <div className='ca-card mb-3 '>
                    <div className="row admintitle align-items-center">
                        {<div className="col-md-3 ">{filterDropDown}</div>}

                        <div className="form-group mb-0 col-md-6">
                            {getEve && getEve.length > 0 ? <div className='ca-lablerow justify-content-start'>
                                <label htmlFor="events " className="font-weight-bold mb-0" >Select Event &nbsp;*</label>

                                <select className="form-control" value={selectType} onChange={handleEventSelect} >
                                    {eventDropDown}
                                </select>
                            </div> : ''}
                        </div>
                        {<div className="col-md-3 text-right"><button className=" btn btn-outline-primary" type="button" onClick={gotoAddEvent}><i class="fas fa-plus"></i> Add Event Gallery</button>  </div>}
                    </div>
                </div>
                <div className='ca-card'>
                    <div className="table-responsive">
                        <table className="table table-bordered ca-table-custom">
                            <thead>
                                <tr>
                                    <th>EVENT ID</th>
                                    <th>CAPTION</th>
                                    <th>GALLERY</th>
                                    <th>CREATED AT</th>
                                    <th>ACTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                {AccountList}
                            </tbody>
                        </table>
                        {(events && events.length > 0) ? '' : <div className="col-md-12 text-center">
                            No Events
                        </div>}
                    </div>
                </div>
                {events && events.length > 0 && <ReactPagination pageDetails={{ pageCount: pageCount, perPage: recordLimit, onPageChange }} forcePg={forcePage}/>}

            </div>

        </Fragment>
    )

}


export { FetchEventGallery }