import * as fcl from "@onflow/fcl"

export const createSetUp = async () => {
  const transaction = `
  // DisruptArt.io NFT Token Smart Contract
// Owner     : DisruptionNowMedia www.disruptionnow.com
// Developer : www.BLAZE.ws
// Version: 0.0.4


// Transaction to mint the multiple tokens

import DisruptArt from ${process.env.REACT_APP_CONTRACT_ADDRESS}


transaction() {
    
    prepare(acct: AuthAccount) {

        // Return early if the account already has a collection
        if acct.borrow<&DisruptArt.Collection>(from: DisruptArt.disruptArtStoragePath) == nil {

            // Create a new empty collection
            let collection <- DisruptArt.createEmptyCollection()

            // save it to the account
            acct.save(<-collection, to: DisruptArt.disruptArtStoragePath)

            // create a public capability for the collection
            acct.link<&{DisruptArt.DisruptArtCollectionPublic}>(
                    DisruptArt.disruptArtPublicPath,
                    target: DisruptArt.disruptArtStoragePath
                    )
        }

   }


}
  `;
  const blockResponse = await fcl.send([
    fcl.getBlock(),
  ])

  try {
    console.log('Account setup txn \n', transaction);
    const { transactionId } = await fcl.send([
      fcl.transaction(transaction),
      fcl.args([]),
      fcl.proposer(fcl.currentUser().authorization),
      fcl.authorizations([
        fcl.currentUser().authorization
      ]),
      fcl.payer(fcl.currentUser().authorization),
      fcl.ref(blockResponse["block"].id),
      fcl.limit(1000),
    ])

    // A Promise is used to get the transaction details only after the 
    // said transaction is sealed. This promise doesnot have a reject
    // condition. It can be added if necessary.
    const result = new Promise((resolve) => {
      fcl

        .tx(transactionId)

        .subscribe(transaction => {

          if (fcl.tx.isSealed(transaction)) {
            // console.log(`Transaction (${transactionId}) is Sealed`)
            // console.log('Transaction obj', transaction);
            resolve({
              status: 200,
              data: {
                "message": "Minted Token",
                "Value": String(transactionId),
                "transaction": transaction
              }
            })
          }
        })
    })

    return result;

  } catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens", "Error": String(e) }
    };
  }

}



export const setupExists = async (useraddress) => {
    // console.log(useraddress)

    let retriveTokens = `\
              import  `+ process.env.REACT_APP_CONTRACT  +` from `+ process.env.REACT_APP_CONTRACT_ADDRESS +`

                                        // This transaction returns an array of all the nft ids in the collection

                                        pub fun main(): Int { 
                                            if
                                                getAccount(`+useraddress+`)
                                                                    .getCapability(`+process.env.REACT_APP_CONTRACT+`.disruptArtPublicPath)
                                                                    .borrow<&{${process.env.REACT_APP_CONTRACT}.DisruptArtCollectionPublic}>() == nil {
                                                                        return 0 
                                                                    }
                                                                    return 1 
     
                                        }`
                                   console.log(retriveTokens)

    try {
             let scriptresult = await fcl.send([fcl.script(retriveTokens)]);
             //console.log(JSON.stringify(scriptresult["encodedData"]["value"],null,1));
             return {
                 status: 200,
                 data: { "message" : "User DisruptNow Token" , "Value" : scriptresult["encodedData"]["value"] }
             };
    }
    catch(e) {
           return {
               status: 400,
               data: { "message" : "Exception happens" , "Error" : String(e) } 
           };
    }
}

