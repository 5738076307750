export const isConnectWallet = () => {
    let useraddress = ""
    if(window.sessionStorage.getItem("CURRENT_USER"))
    useraddress=JSON.parse(window.sessionStorage.getItem("CURRENT_USER"))["addr"];
    if(useraddress && useraddress != null ){
        return true
    }
    else{
        return false
     }
}

export const isAdminLogin = () => {
    let user = localStorage.getItem('bab_adminDetail')
    let userDetail = JSON.parse(user)
    if(userDetail){
        return true
    }else{
        return false
    }
}
export function adminToken(){
    let adminDetail =  localStorage.getItem("bab_adminDetail")
    let admin = JSON.parse(adminDetail)
    if(admin && admin.data && admin.data.accessToken){
         return {'Authorization' : "Bearer" +  " " + admin.data.accessToken}
    }else{
        return {}
    }
 }

export function refreshToken(){
    let userDetail =  localStorage.getItem("bab_adminDetail")
    let user = JSON.parse(userDetail)
    if(user && user.data && user.data.refreshToken){
         return {'refreshToken':user.data.refreshToken}
    }else{
        return {}
    }
 }