import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import moment from 'moment'
import { adminActions } from '../../../../store/actions';
import { toast } from 'react-toastify';
import '../Menu.css'
import { getMaxMintCount } from '../../../../Utils/utils';
import { configPath } from '../../../../config';
import { config } from '@onflow/fcl';

function AddSlots(props) {


    const navigate = useNavigate() // page redirection 
    const dispatch = useDispatch() //dispatch action
    
    //define states
    const [name, setName] = useState('')
    const [desc, setDesc] = useState('')
    const [submitted, setSubmitted] = useState(false)
    const [selectType, setSelectType] = useState(0)
    const [mintCount, setMintCount] = useState(1)
    const [onstart, setOnstart] = useState('')
    const [endat, setEndat] = useState('')
    const [price, setPrice] = useState('')
    const [slots, setSlots] = useState([])
    const [isCountWarning, setIsCountWarning] = useState(false)
    const [getattrb, setGetattrb] = useState([]) // for rarity dropdown
    const [getDetailAttributes, setGetDetailAttributes] = useState([]) // get all attributes with detail
    const [getSlectedAttribute, setGetSelectedAttribute] = useState([]) // store selected attribute detail
    const [isAlert, setIsAlert] = useState(false)
    const [isChange, setIsChange] = useState(false)
    const [rarityCount,setRarityCount] = useState(0)
    const [freezeStatus,setFreezeStatus] = useState(1)
    //get states from store
    const addedslot = useSelector(state => state.adminreducer.addedslot)
    const attributes = useSelector(state => state.adminreducer.attributes);
    const slotDetail = useSelector(state => state.adminreducer.slotDet)
    const updateSlot = useSelector(state => state.adminreducer.updateSlot)

    const allTypes = [{ rarity: "Select rarity type", value: 0 }];

    const gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
   


    //successfully created/updated slot
    //navigate to slots page

    useEffect(() => {
        if (addedslot && addedslot.statusCode === 201) {
            navigate('/creatoradmin/slots')
        }
        if(updateSlot && updateSlot.statusCode === 200){
            navigate('/creatoradmin/slots')
        }
        
    })
    //componentDidMount()
    useEffect(() => {
        gotoTop()
        if((props && props.params && props.params.id)){
            dispatch(adminActions.getSlotDetail((configPath.pfpId),(props.params.id)))
        }else{
            dispatch(adminActions.getAllAttributes(configPath.pfpId))
        }
    }, [])

    useEffect(() => {
        //update state when Edit
        if(slotDetail && slotDetail.statusCode === 200){
            dispatch(adminActions.getAllAttributes(1))
            if(slotDetail && slotDetail.data ){
                let attributeArray = []
                let attributeDetailArray = []
                let obj = {}
                //update rarity changes
                slotDetail.data.slotDetails && slotDetail.data.slotDetails.forEach((item,index) => {
                    
                    obj = {
                        attributeId : item && item.rarityAttributeDetail && item.rarityAttributeDetail.attributeId,
                        name : item && item.rarityAttributeDetail && item.rarityAttributeDetail.name,
                        id : item && item.rarityAttributeDetail && item.rarityAttributeDetail.id,
                        location : `${configPath.pfpId}/attributes/${slotDetail && slotDetail.data && slotDetail.data.rarityAttributeId}/details/${item.rarityAttributeDetailId}/preview`,
                        value : item && item.value,
                        rarityId : item && item.id,
                        status : slotDetail && slotDetail.data.rarityAttribute && slotDetail.data.rarityAttribute.status,
                        slotId : slotDetail && slotDetail.data && slotDetail.data.id
                    }
                    attributeDetailArray.push(obj)

                })
                let attributeObj = {
                    attributeDetails : attributeDetailArray,
                    description : slotDetail.data.rarityAttribute && slotDetail.data.rarityAttribute.description,
                    name : slotDetail.data.rarityAttribute && slotDetail.data.rarityAttribute.name,
                    id : slotDetail.data.rarityAttribute && slotDetail.data.rarityAttribute.id
                }
                attributeArray && attributeArray.push(attributeObj)
                
                //update other states
                setGetSelectedAttribute(attributeArray)
                setName(slotDetail.data.name && slotDetail.data.name) 
                setOnstart(slotDetail.data.startTime && moment(slotDetail.data.startTime).format('YYYY-MM-DD'))           
                setEndat(slotDetail.data.endTime && moment(slotDetail.data.endTime).format('YYYY-MM-DD'))
                setMintCount(slotDetail.data.mintCount && slotDetail.data.mintCount)
                setSelectType(slotDetail.data.rarityAttributeId && slotDetail.data.rarityAttributeId)
                setPrice(slotDetail.data.nftPrice && slotDetail.data.nftPrice)
                setDesc(slotDetail.data.description && slotDetail.data.description)
                setFreezeStatus(slotDetail.data.status && slotDetail.data.status)
            }
            
        }
    },[slotDetail])

    //fetch all attributes
    useEffect(() => {
        if (attributes && attributes.statusCode === 200) {
            dispatch(adminActions.resetAllAttributes())
            const allAttributes = (attributes && attributes.data && attributes.data.attributes)
            setGetDetailAttributes(allAttributes)
            allAttributes && allAttributes.length > 0 && allAttributes.map(items => {
                allTypes.push({
                    rarity: items && items.name,
                    value: items && items.id
                })
                setGetattrb(allTypes)

            })
        }
    }, [attributes])


    const rarityDropdown = getattrb.map((filType) => {
        return <option value={filType.value} key={filType.value}>{filType.rarity}</option>
    });

    const gotoBack = () => {
        navigate(-1)
    }
    const onFullNameChange = (e) => {
        setName(e.target.value)
    }
    const onDescChange = (e) => {
        setDesc(e.target.value)
    }
    useEffect(() => {
        checkRarityCount()
    },[mintCount])
    const onMintCountChange = (e) => {
        if ((e.target.value) > 0 && (e.target.value) <= (Number(1801)) && ((e.target.value) % 1 == 0)) {
            setMintCount(e.target.value)
        } else {
            setMintCount('')
        }
    }
    const digitValidate = (s) => {
        var n = Number(s)

        if (n > 0 && n <= 999.99) {
            if (!s.match(/\d{1,3}\.\d{3}/)) {

                return true
            }
        } else {

            return true
        }
        return false
    }
    const onPriceChange = (e) => {
        if ((e.target.value) >= 0 && digitValidate(e.target.value)) {
            setPrice(e.target.value)
        } else {
            setPrice('')
        }
    }
    const checkname = (value) => {
        if((value && value.length) > 100){
            return true
        }else{
            return false
        }
    }
    const checkFullName = (value) => {
        if (((value && value.length) <= 100) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }
    const checkDesc = (value) => {
        if (((value && value.length) <= 100) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }
    const validNumber = (value) => {
        if (isNaN(value)) {
            return false
        } else {
            return true
        }
    }
    
    const handleOnstart = (e) => {
        setOnstart(e.target.value)
    }
    const handleEndTime = (e) => {
        setEndat(e.target.value)
    }
    useEffect(() => {
        if(isChange){
            setIsChange(false)
         const filtAttrDetail = getDetailAttributes && getDetailAttributes.filter(el => (el.id === Number(selectType)))

        filtAttrDetail && filtAttrDetail[0] && filtAttrDetail[0].attributeDetails && filtAttrDetail[0].attributeDetails.length > 0 && filtAttrDetail[0].attributeDetails.forEach(el => {
            el.location = `${configPath.pfpId}/attributes/${filtAttrDetail[0].id}/details/${el.id}/preview`
            el.value = ''
        }) // insert value propert for all attributes
        console.log(filtAttrDetail)
        setGetSelectedAttribute(filtAttrDetail)
        }
    },[isChange])
    
    const handleRarityTypes = (e) => {

        setSelectType(e.target.value) //selected rarity type
        setGetSelectedAttribute([]) // reset attribute values
        setIsChange(true)
        setRarityCount(0)
        setIsCountWarning(false)
       
    }
    
    const checkRarityCount = () => {
        const newlist = [...getSlectedAttribute]
        const valueList = newlist && newlist[0] && newlist[0].attributeDetails && newlist[0].attributeDetails.filter(ele => ele.value).map(val => val.value)
        console.log(valueList)
        const rarityCount = valueList && valueList.length > 0 && valueList.reduce((a, b) => Number(a) + Number(b))
        if(rarityCount){
            setRarityCount(rarityCount)
        }else{
            setRarityCount(0)
        }
        if (Number(rarityCount) > Number(mintCount)) {
            setIsCountWarning(true)
        } else {
            setIsCountWarning(false)
        }
    }
    const handleRarityCount = (e, item, idx) => {
        console.log(e.target.value)
        const newlist = [...getSlectedAttribute]
        var regex = /[.]/g
        if ((e.target.value) <= (getMaxMintCount()) && ((e.target.value) % 1 == 0) && !(regex.test(e.target.value))) {
            if (item) {
                item.value = e.target.value
            }
            newlist && newlist[0] && newlist[0].attributeDetails && newlist[0].attributeDetails.filter(el => (el.id === item.id) ? (el = item) : '')
        }
        setGetSelectedAttribute(newlist)

        const valueList = newlist && newlist[0] && newlist[0].attributeDetails && newlist[0].attributeDetails.filter(ele => ele.value).map(val => val.value)
        const rarityCount = valueList && valueList.length > 0 && valueList.reduce((a, b) => Number(a) + Number(b))
        if(rarityCount){
            setRarityCount(rarityCount)
        }else{
            setRarityCount(0)
        }
        if (Number(rarityCount) > Number(mintCount)) {
            setIsCountWarning(true)
        } else {
            setIsCountWarning(false)
        }

    }
    const checkValidDate = () => {
        if (new Date(endat) > new Date(onstart)) {
            return true
        } else {
            return false
        }
    }
    const checkmaxInput = (value) => {
        if ((value && value.length) <= 100) {
            return true
        } else {
            return false
        }
    }
    const checkInput = (value) => {
        if ((value && value.length) >= 3) {
            return true
        } else {
            return false
        }
    }
    
    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)

        //is there any attribute count not fill

        const isAnyoneFilled = getSlectedAttribute && getSlectedAttribute[0] && getSlectedAttribute[0].attributeDetails && getSlectedAttribute[0].attributeDetails.some(el => (el.value !== ""))
    
        //attribute id
        const id = getSlectedAttribute && getSlectedAttribute[0] && getSlectedAttribute[0].id //rarity attribute id
        // create array for selected attributes detail

        const rarityAttributeDetails = []
        getSlectedAttribute && getSlectedAttribute[0] && getSlectedAttribute[0].attributeDetails && getSlectedAttribute[0].attributeDetails.forEach(el => {
            if(props && props.params && props.params.id && el.slotId && el.rarityId){
                if(el.value){
                    rarityAttributeDetails.push({
                        slotId : el.slotId,
                        attributeDetailId : el.id,
                        count : Number(el.value),
                        id : el.rarityId,
                        status : el.status
                    })
                }
            }else{
                if(el.value){
                    rarityAttributeDetails.push({
                        attributeDetailId: el.id,
                        count: Number(el.value)
                    })
                }
        }

        })
        
    
        
        console.log(rarityAttributeDetails)
        
        if (name && checkFullName(name)) {
            if (desc && mintCount && price) {
                if (onstart) {
                    if (endat) {
                        if (checkValidDate()) {
                            if(id){
                            if (isAnyoneFilled) {
                                if (!isCountWarning) {
                                    console.log(mintCount,rarityCount)
                                    if((Number(mintCount) >= Number(rarityCount))){
                                    let obj = {
                                        name,
                                        mintCount: Number(mintCount),
                                        startTime: moment(onstart).toISOString(),
                                        endTime: moment(endat).toISOString(),
                                        nftPrice: Number(price),
                                        rarityAttributeId: id,
                                        rarityAttributeDetails,
                                        description : desc
                                    }
                                    if(props && props.params && props.params.id){
                                        obj['id'] = Number(props && props.params && props.params.id)
                                        obj['pfpId'] = configPath.pfpId
                                        dispatch(adminActions.updateSlot((configPath.pfpId),(JSON.stringify(obj))))
                                    }else{
                                    dispatch(adminActions.addSlot((JSON.stringify(obj)), (configPath.pfpId)))
                                    }
                                    }else{
                                        toast.warning("Rarity count should be less than or equal to mint count")

                                    }
                                } else {
                                    toast.warning("Rarity count should be less than or equal to mint count")
                                }
                            } else {
                                toast.error("Please fill atleast one rarity count")
                             }
                        }else{
                            toast.error("Please select any one of the rarity type")
                        }
                        } else {
                            toast.error("End date and time should be greater than start date and time")
                        }
                    } else {
                        toast.error("Please select end date and time")
                    }
                } else {
                    toast.error("Please select start date and time")
                }
            } else {
                toast.error("Please fill out mandatory fields")
            }

        } else {
            toast.error("Slot name mandatory and should be atleast 3 characters and atmost 100 characters")
        }
    }

    return (
        <Fragment>
            <div className="smallilac px-3">
                <div className='ca-card mb-3 '>
                    <div className="row admintitle align-items-center">
                        <div className="col-sm-6 "><h2 className="font-weight-bold">
                            {(props && props.params && props.params.id) ? "Edit Slot" : "Add Slot"}</h2>  </div>
                        <div className="col-sm-6 text-right"><button className=" btn btn-outline-primary" type="button" onClick={gotoBack}><i className="fas fa-angle-left"></i> Back</button>  </div>
                    </div>
                </div>
                
                <form onSubmit={handleSubmit}>
                    <div className='ca-card mb-3 '>
                        <div className="row">
                            <div className="form-group col-md-4 mb-4">
                                <label htmlFor="name " className="font-weight-bold" >Slot name &nbsp;*</label>
                                <input type="text" className={checkname(name) ? "form-control descdan" : "form-control"} id="name" aria-describedby="name" placeholder="Enter slot name" value={name} onChange={onFullNameChange} disabled={freezeStatus === 2}/>
                                {submitted && !name && <div className="mandatory small">{"Name required"}</div>}
                                {!submitted && name && !checkInput(name) && <div className="mandatory-req small">{"Name must be at least 3 characters"}</div>}
                                {!submitted && name && !checkmaxInput(name) && <div className="mandatory-req small">Name should be no more than 100 characters</div>}
                                {submitted && name && !checkInput(name) && <div className="mandatory small">{"Name must be at least 3 characters"}</div>}
                                {submitted && name && !checkmaxInput(name) && <div className="mandatory small">Name should be no more than 100 characters</div>}
                            </div>
                            <div className="form-group col-md-4 mb-4">
                                <label htmlFor="mintCount " className="font-weight-bold" >Mint count &nbsp;*</label>
                                <input type="text" className="form-control" id="mintCount" aria-describedby="mintCount" placeholder="Enter mint count" value={mintCount} onChange={onMintCountChange} disabled={freezeStatus === 2}/>
                                {submitted && !mintCount && <div className="mandatory small">{"Mint count required"}</div>}
                                {mintCount && !validNumber(mintCount) && <div className="mandatory-req small">{"Must be whole number"}</div>}
                                {mintCount && <div className="mandatory-req small">{`Should be less than or equal to ${getMaxMintCount()}`}</div>}


                            </div>
                            <div className="form-group col-md-4 mb-4">
                                <label htmlFor="price " className="font-weight-bold" >Price &nbsp;*</label>
                                <input type="text" className="form-control" id="price" aria-describedby="price" placeholder="Enter price per NFT" value={price} onChange={onPriceChange} disabled={freezeStatus === 2}/>
                                {submitted && !price && <div className="mandatory small">{"Price required"}</div>}

                            </div>
                            <div className="form-group col-md-6 mb-4">
                                <div className="">
                                    <label className="font-weight-bold" htmlFor="onstart" >Starts from</label>

                                    <input id="onstart" name="onstart" type="date" value={onstart} onChange={handleOnstart} className="form-control" min={moment().format('YYYY-MM-DD')} disabled={freezeStatus === 2}/>
                                    {submitted && !onstart && <div className="mandatory mt-2 small">{"Please select your date and time for slot launch"}</div>}
                                </div>
                            </div>
                            <div className="form-group col-md-6 mb-4">
                                <div className="">
                                    <label className="font-weight-bold" htmlFor="endat" >Ends at</label>

                                    <input id="endat" name="endat" type="date" value={endat} onChange={handleEndTime} className="form-control" min={moment(onstart).format('YYYY-MM-DD')} disabled={!onstart || freezeStatus === 2} />
                                    {submitted && !endat && <div className="mandatory mt-2 small">{"Please select your date and time for slot end"}</div>}
                                </div>
                            </div>
                            <div className="form-group col-md-12 ">
                                <label htmlFor="desc " className="font-weight-bold" >Description &nbsp;*</label>
                                <textarea className="form-control" id="desc" aria-describedby="desc" placeholder="Enter slot description" value={desc} onChange={onDescChange} disabled={freezeStatus === 2}/>
                                {submitted && !desc && <div className="mandatory small">{"Description required"}</div>}
                                {!submitted && desc && !checkInput(desc) && <div className="mandatory-req small">{"Description must be at least 3 characters"}</div>}
                                {!submitted && desc && !checkmaxInput(desc) && <div className="mandatory-req small">Description should be no more than 100 characters</div>}
                                {submitted && desc && !checkInput(desc) && <div className="mandatory small">{"Description must be at least 3 characters"}</div>}
                                {submitted && desc && !checkmaxInput(desc) && <div className="mandatory small">Description should be no more than 100 characters</div>}

                            </div>
                        </div>
                    </div>

                    <div className='ca-card mb-3 '>
                        <div className="row">
                            <div className="form-group col-md-6 ">
                                <label htmlFor="rarity " className="font-weight-bold" >Rarity &nbsp;*</label>
                                {getattrb && getattrb.length > 0 ?
                                    <select className="form-control" value={selectType} onChange={handleRarityTypes} disabled={freezeStatus === 2}>
                                        {rarityDropdown}
                                    </select> : <div className="col-md-12 text-center">
                                        <div className="loader"></div>
                                    </div>}


                            </div>
                            <div className="form-group col-md-12 mt-3">
                                <div className='row'>
                                    {getSlectedAttribute && getSlectedAttribute[0] && getSlectedAttribute[0].attributeDetails && getSlectedAttribute[0].attributeDetails.map((items, index) => (

                                        <div className='col-md-2 mb-3 ca-rar' key={items && items.id} title={items.name}>
                                            <div className="slot-rarity" key={index}>
                                                <span className="slot-img">
                                                    <img data-toggle="tooltip" title={items.name} src={`${configPath.apiUrl}/attribute-management/${items.location}`} className="mw-100" crossorigin="anonymous" alt="image" />
                                                </span>
                                                <span class="text-center">{items.name}</span>
                                                <span className="slot-inp">
                                                    <input value={items.value} onChange={(e) => handleRarityCount(e, items, index)} className="form-control" placeholder='0' />
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                            {isCountWarning && <div className="form-group col-md-6 offset-md-3 mt-4">
                                <div className="alert alert-warning" role="alert">
                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Warning :
                                    Rarity count should be less than or equal to mint count
                                </div>
                            </div>}


                            {(freezeStatus === 1) ? <div className="form-group col-md-12 text-right">
                                <hr />
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div> : ''}

                        </div>
                    </div>
                </form>

            </div>

        </Fragment>
    )

}

export { AddSlots }