import React from "react";
import { AppLayout } from "./app-layout";


export function AppLayoutRoute({
    children
}) {
        return (
           
                        <AppLayout>
                           {children}
                        </AppLayout>
                    
              
        );
   
}


