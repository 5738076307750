import React, { Component, Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from "react-redux";
import { adminServices } from '../../../../services';
import { adminActions } from '../../../../store/actions';
import { configPath } from '../../../../config';
import { ReactPagination } from '../../../../helpers/paginate';
import moment from 'moment'


function FetchArts() {

    const navigate = useNavigate()
    const dispatch = useDispatch() //dispatch action
    const getAllArts = useSelector(state => state.adminreducer.getArts)
    const getSlots = useSelector(state => state.adminreducer.slots)

    const [slotType, setSlotType] = useState(1)
    const [options, setOptions] = useState([{slot : "Select",value:0}])
    const [artsList, setArtsList] = useState([]);
    const [offSet, setOffSet] = useState(0)
    const [recordLimit,setRecordLimit] = useState(40)
    const [pageCount,setPageCount] = useState('')
    const [callBack, setCallBack] = useState(false)
    const [slots, setSlots] = useState([]);
    const [search,setSearch] = useState('')
    

    useEffect(() => {
     
        setOptions(options)
        fetchAllArts()
        let obj = {}
        dispatch(adminActions.getSlots(obj,(configPath.pfpId)))

    }, [])
    useEffect(() => {
        if((getAllArts && getAllArts.statusCode) === 200){
            dispatch(adminActions.resetGetArts())
            if(getAllArts.data && getAllArts.data.arts){
                setPageCount(getAllArts && getAllArts.data && getAllArts.data.count / recordLimit)
                setArtsList(getAllArts.data.arts)
            }
        }
    })
    const fetchAllArts = () => {
        let obj = {
            offSet: offSet,
            recordLimit : recordLimit,
            paymentDetail:true,
            // sortBy : "created_at",
            // sortOrder : "ASC",
            isOwnerEmail : true
        }
        dispatch(adminActions.getAllArts((configPath.pfpId),(slotType),obj))
    }
    const handleSlotDropDown = (e) => {
        setSlotType(e.target.value)
        setCallBack(true)
        setOffSet(0)
    }
    const onPageChange = (data) => {
        let offSet = recordLimit * (data && data.selected)
        setOffSet(offSet)
        setCallBack(true)
    }
    useEffect(() => {
        if (callBack) {
            fetchAllArts()
            setCallBack(false)
        } else {
            return;
        }
    }, [callBack])
    useEffect(() => {
        let array = []
        if(getSlots && getSlots.statusCode === 200){
            dispatch(adminActions.resetGetSlot())
            if(getSlots && getSlots.data && getSlots.data.slots){
                // setSlotCount(getSlots.data && getSlots.data.count)
                setSlots(getSlots.data.slots)
                getSlots.data.slots && getSlots.data.slots.map(item => {
                    let obj = {
                        slot : item && item.name,
                        value : item && item.id
                    }
                    array.push(obj)
                })
                setOptions(array)
            }
        }
    },[getSlots])
    const slotDropdown = options.map((filType) => {
        return <option value={filType.value}>{filType.slot}</option>
    });
    const purchaseStatus = (items) => {
        if(((items && items.isPurchased === 1) && (items.ownerEmail && items.ownerEmail != ''))){
            return "Purchased"
        }else if((items && items.isPurchased === 1)){
            return "Reserved"
        }else{
            return "Active"
        }
    }
    const showEmail = (items) => {
        if(((items && items.isPurchased === 1) && (items.ownerEmail && items.ownerEmail != ''))){
            // const email = items.ownerEmail 
            // const firstEmail = email && email.split(',')
            // if(firstEmail && firstEmail.length > 1){
            //     return (firstEmail.shift() + ",")
            // }else{
                return items.ownerEmail
            // }
        }else{
            return '-'
        }
    }
    const showPurchasedAt = (items) => {
        if(((items && items.isPurchased === 1) && (items.ownerEmail && items.ownerEmail != ''))){
            if(items && items.purchasedDate){
                return (moment(items.purchasedDate).format('MMM/DD/YYYY hh:mm:ss A'))
            }
            else if(items && items.updatedAt){
                return (moment(items.updatedAt).format('MMM/DD/YYYY hh:mm:ss A'))
            }else{
                return '-'
            }
        }else{
            return '-'
        }
    }
    const showPrice = (items) => {
        if(((items && items.isPurchased === 1) && (items.ownerEmail && items.ownerEmail != '') && (items.purchasedDate))){
            return '$154.79'
        }else{
            return '-'
        }
    }
    const showAddress = (items) => {
        if(((items && items.isPurchased === 1) && (items.ownerEmail && items.ownerEmail != '') && (items.ownerWalletAddress) !== null)){
            return items.ownerWalletAddress
        }else{
            return '-'
        }
    }
    // const handleSearch = (e) => {
    //     setSearch(e.target.value)
    //     // setCallBack(true)
    //     // setOffSet(0)
    //     debounce((fetchAllArts(),400),[])
    // }
    // function debounce(func, wait = 300) {
    //     let timeout;
    //     return function(...args) {
    //       const context = this;
    //       if (timeout) clearTimeout(timeout);
    //       timeout = setTimeout(() => {
    //         timeout = null;
    //         func.apply(context, args);
    //       }, wait);
    //     };
    //   }
    const AccountList = artsList && artsList.map((items) => {
        
        return <tr>
            <td><a >{items && items.id}</a> </td>
            {/* <td><a>{(() => listIds(items)) ? items && items.tokenId : '-'}</a></td> */}
            <td><a >{purchaseStatus(items)}</a></td>
            <td><a >{showEmail(items)}</a></td>
            <td><a >{showAddress(items)}</a></td>
            <td><a >{showPurchasedAt(items)}</a></td>
            <td><a >{showPrice(items)}</a></td>

        </tr>
        
    })
    return (
        <Fragment>
            <div className="smallilac px-3">
                <div className='ca-card mb-3 '>
                    <div className="row admintitle align-items-center">
                        <div className="col-md-2"><h2 className="font-weight-bold">Arts  </h2></div>
                        {/* <div className="col-md-4 col-sm-12">
                            <div className="input-group mb-2 mr-sm-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="fa fa-search" aria-hidden="true"></i></div>
                                </div>
                                <input type="text" className="form-control" placeholder="Search" name="search" onChange={handleSearch} value={search} />
                            </div>
                        </div> */}
                        <div className="form-group mb-0 col-md-10 text-right">
                            <div className='ca-lablerow'>
                            <label htmlFor="name " className="font-weight-bold mb-0" for="name">Slots</label>
                            <select className="form-control" value={slotType} onChange={handleSlotDropDown}>
                                {slotDropdown}
                            </select>
                            </div>
                        </div>
                        {/* <div className="form-group mb-0 col-md-4">
                            <div className='ca-lablerow'>
                            <label htmlFor="name " className="font-weight-bold mb-0" for="name">Slots</label>
                            <select className="form-control" value={slotType} onChange={handleSlotDropDown}>
                                <option>Purchased</option>
                                <option>Active</option>
                                <option>Reserved</option>
                            </select>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className='ca-card'>
                    <div className="table-responsive">
                        <table className="table table-bordered ca-table-custom">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    {/* <th>Token Id</th> */}
                                    <th>Status</th>
                                    <th>Purchased by</th>
                                    <th>Owner address</th>
                                    <th>Purchased at</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {AccountList}
                            </tbody>
                        </table>
                        {(artsList && artsList.length > 0) ?  '': <div className="col-md-12 text-center">
                                        No Arts
                                    </div>}
                    </div>
                </div>
                {artsList && artsList.length > 0  &&  <ReactPagination pageDetails={{ pageCount: pageCount, perPage: recordLimit, onPageChange }} />}

            </div>
        </Fragment>
    )

}


export { FetchArts }