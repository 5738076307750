import Home from "./Components/Home";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Appbb.css';
import AuthCluster from './authCluster';


function App() {
  return (
    <div className="App">
      <AuthCluster/>
    </div>
  );
}

export default App;
