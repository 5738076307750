
import {configPath} from '../config'
import { adminToken } from '../helpers/authorizedData';
import { fetchService } from './fetch-service';
export const adminServices = {
    getAllAttributes,
	adminLogin,
    adminLogout,
    addSlot,
    getSlots,
    getSlotDetail,
    updateSlot,
    freezeSlot,
    getFilterAttribute,
    updateFilterAttribute,
    getAllArts,
    updateSettings,
    getSettings,
    rejectArts,
    unRejectArts,
    addEmailtoWaitlist,
    getWaitlistCount,
    getSoldCount,
    exportPurchaseArts,
    validateBearTransaction,
    sendBearToAddress,
    verifyBearTransferCode,
    getAllEvents,
    updateEvent,
    getEventDetail,
    addEvents,
    addEventGallery,
    getAllEventGallery,
    deleteEventGallery
}

function getAllAttributes(id) {
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
   

    return fetchService.fetchResource(`${configPath.apiUrl}/attribute-management/${id}/attributes`,requestOptions).then(handleResponse,handleErrorResponse)
}
function adminLogin(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    };
    return fetch(`${configPath.apiUrl}/admin-auth/sign-in`, requestOptions).then(handleResponse,handleErrorResponse).then(user => { return user })
}
function adminLogout() {
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
        
    };
    return fetch(`${configPath.apiUrl}/admin-auth/logout`, requestOptions).then(handleResponse,handleErrorResponse).then(user => { return user })
}
function addSlot(obj,id) {
    const requestOptions = {
        method: 'POST',
        headers: {  ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/slot-management/${id}/slots`, requestOptions).then(handleResponse,handleErrorResponse)
}
function getSlots(obj,id){
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''
    let roleId = obj && obj.roleId ? '&roleId=' + obj.roleId : ''


    params =  offSet + recordLimit + sortBy + sortOrder + search + roleId

    return fetchService.fetchResource(`${configPath.apiUrl}/slot-management/${id}/slots?` + params,requestOptions).then(handleResponse)
}
function getSlotDetail(id,slotid) {
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
   

    return fetchService.fetchResource(`${configPath.apiUrl}/slot-management/${id}/slots/${slotid}`,requestOptions).then(handleResponse,handleErrorResponse)
}
function updateSlot(id,obj) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/slot-management/${id}/slots`, requestOptions).then(handleResponse,handleErrorResponse).then(user => { return user })
}
function freezeSlot(pfpId,slotId){
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/slot-management/${pfpId}/slots/${slotId}/freeze`, requestOptions).then(handleResponse,handleErrorResponse)

}
function getFilterAttribute(pfpId){
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
   

    return fetchService.fetchResource(`${configPath.apiUrl}/attribute-management/${pfpId}/attributeFilters`,requestOptions).then(handleResponse,handleErrorResponse)
}
function updateFilterAttribute(pfpId,obj){
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/attribute-management/${pfpId}/attributeFilters`, requestOptions).then(handleResponse,handleErrorResponse)
}
function getAllArts(pfpId,slotId,obj){
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''
    let roleId = obj && obj.roleId ? '&roleId=' + obj.roleId : ''
    let isGallery = obj && obj.isGallery ? '&isGallery=' + obj.isGallery : ''
    let paymentDetail = obj && obj.paymentDetail ? '&paymentDetail=' + obj.paymentDetail : ''
    let isPurchased = obj && obj.isPurchased ? '&isPurchased=' + obj.isPurchased : ''
    let isOwnerEmail = obj && obj.isOwnerEmail ? '&isOwnerEmail=' + obj.isOwnerEmail : ''
    params =  offSet + recordLimit + sortBy + sortOrder + search + roleId + isGallery + paymentDetail + isPurchased + isOwnerEmail

    return fetchService.fetchResource(`${configPath.apiUrl}/art-management/${pfpId}/slots/${slotId}/arts?` + params,requestOptions).then(handleResponse)
}
function updateSettings(obj){
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/admin-auth/admin-settings`, requestOptions).then(handleResponse,handleErrorResponse)
}
function getSettings(){
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/admin-auth/admin-settings`, requestOptions).then(handleResponse,handleErrorResponse)
}
function rejectArts(pfpId,slotId,artId){
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/art-management/${pfpId}/slots/${slotId}/arts/${artId}/reject`, requestOptions).then(handleResponse,handleErrorResponse)
}
function unRejectArts(pfpId,slotId,artId){
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/art-management/${pfpId}/slots/${slotId}/arts/${artId}/unreject`, requestOptions).then(handleResponse,handleErrorResponse)
}
function addEmailtoWaitlist(obj){
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/dashboard-management/dashboard/wait-list`, requestOptions).then(handleResponse,handleErrorResponse)
}
function getWaitlistCount(){
    const requestOptions = {
        method : "GET",
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/dashboard-management/dashboard/wait-list/count`, requestOptions).then(handleResponse,handleErrorResponse)

}
function getSoldCount(pfpId,slotId){
    const requestOptions = {
        method : "GET",
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/dashboard-management/dashboard/${pfpId}/slots/${slotId}/sold-art-count`, requestOptions).then(handleResponse,handleErrorResponse)

}
function exportPurchaseArts(pfpId,slotId){
    const requestOptions = {
        method : "GET",
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/dashboard-management/dashboard/${pfpId}/slots/${slotId}/export/purchase-detail`, requestOptions)
}
function validateBearTransaction(obj,pfpId,slotId){
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    let params = ''

	let email = obj && obj.email ? "email=" + obj.email : ''

	let walletAddress = obj && obj.walletAddress ? '&walletAddress=' + obj.walletAddress : ''
    params = email + walletAddress
    return fetchService.fetchResource(`${configPath.apiUrl}/dashboard-management/dashboard/${pfpId}/slots/${slotId}/owned-nfts?` + params, requestOptions).then(handleResponse,handleErrorResponse)
}
function sendBearToAddress(obj,pfpId,slotId){
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/dashboard-management/dashboard/${pfpId}/slots/${slotId}/transfer`, requestOptions).then(handleResponse,handleErrorResponse)
}
function verifyBearTransferCode(obj,pfpId,slotId){
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/dashboard-management/dashboard/${pfpId}/slots/${slotId}/transfer/confirmation-code`, requestOptions).then(handleResponse,handleErrorResponse)
}
function getAllEvents(obj){
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''
 
    params =  offSet + recordLimit + sortBy + sortOrder + search 

    return fetchService.fetchResource(`${configPath.apiUrl}/event-management/event?` + params,requestOptions).then(handleResponse)
}
function getEventDetail(id) {
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
   

    return fetchService.fetchResource(`${configPath.apiUrl}/event-management/event/${id}`,requestOptions).then(handleResponse,handleErrorResponse)
}
function updateEvent(obj) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/event-management/event`, requestOptions).then(handleResponse,handleErrorResponse)
}
function addEvents(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/event-management/event`, requestOptions).then(handleResponse,handleErrorResponse)
}
function addEventGallery(id,obj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(), 'accept': 'application/json' },
        body : obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/event-management/event/${id}/gallery`, requestOptions).then(handleResponse,handleErrorResponse)
}
function getAllEventGallery(obj,id){
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let type = obj && obj.type ? '&type=' + obj.type : ''
 
    params =  offSet + recordLimit + sortBy + sortOrder + type 

    return fetchService.fetchResource(`${configPath.apiUrl}/event-management/event/${id}/gallery?` + params,requestOptions).then(handleResponse)
}
function deleteEventGallery(id,itemId) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/event-management/event/${id}/gallery/${itemId}`, requestOptions).then(handleResponse,handleErrorResponse)
}
function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 403) {
            //    localStorage.removeItem('bab_adminDetail')
			}
            const error = (data && data.data && data.data.data && data.data.data[0] && data.data.data[0][0]) || (data && data.message)
			return Promise.reject(error)
		}
		return data;
	});
}
function handleErrorResponse(error){
    if(error instanceof TypeError){
        console.log(error)
        return Promise.reject("Unexpected error occured")
    }else{
        return Promise.reject("Unexpected error occured")
    }
}