import React, { useEffect, useState } from "react";
import { VideoPreview } from './VideoPreview';


function ArtPreview(props) {
    const [prevSrc, setPrevSrc] = useState('')
    const [mimeType, setMimeType] = useState('')


    useEffect(() => {
        if (props.src !== undefined && (props.src !== prevSrc)) {
            setPrevSrc(props.src)
            setMimeType(props.mimeType)
        }
    })
    if (prevSrc === null) {
        return (<span>

            <img src={''} alt={''} /></span>)

    }
    else if (prevSrc === null) {

        return (<span><img src={''} alt={''} /></span>)
    }
    else if (prevSrc !== '') {
        console.log(prevSrc)

        if (mimeType && mimeType.match(/image.*/)) {

            return (<span>

                <img src={prevSrc} alt={''} width={"50px"} height={"50px"}/></span>)

        } else if (mimeType && mimeType.match(/video.*/)) {

            return <span>

                <VideoPreview source={prevSrc} mimeType={mimeType} width={"50px"} height={"50px"} /></span>

        } else {
            return null
        }

    }
    else {
        return ''
    }



}
export { ArtPreview }