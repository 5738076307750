import React, { Fragment, useEffect, useState } from 'react'
import Logo from "../../../Assets/images/logo.png";
import './Login.css'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';

import { adminActions } from '../../../store/actions';
import { isAdminLogin } from '../../../helpers/authorizedData';
var timeout;
function Login() {

    const [uname, setUname] = useState('')
    const [password, setPassword] = useState('')
    const [submitted, setSubmitted] = useState(false)
    const [email, setEmail] = useState('')
    const [isAlert, setIsAlert] = useState(false)
    const [error, setError] = useState('')
    const [isLoading,setIsLoading] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const response = useSelector(state => state.adminreducer.adminUser)
    const failedResponse = useSelector(state => state.adminreducer.adminuserfailed)
    
    useEffect(() => {
        if (response && response.statusCode === 200) {
            setIsLoading(false)
            dispatch(adminActions.resetAdminLogin())
            navigate('/creatoradmin/slots')
            
        } else if (failedResponse && failedResponse.length !== 0) {
            setIsLoading(false)

            dispatch(adminActions.resetFailedAdminLogin())
            gotoTop()
            setIsAlert(true)
            setError(failedResponse)
            clear()
            settime()
        }
    })
    useEffect(() => {
        if(isAdminLogin()){
        dispatch(adminActions.adminLogout())
        }
    },[])
    const settime = () => {
        timeout = setTimeout(() => {
            setIsAlert(false)
        }, 10000)
    }
    const clear = () => {
        clearTimeout(timeout)
    }

    const handleChange = (e) => {

        setUname(e.target.value)
    }
    const handlePasswordChange = (e) => {
        setPassword(e.target.value)
    }
    const setAlert = () => {
            gotoTop()
            setIsAlert(true)
            clear()
            settime()
    }
    const checkemail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var test = emailReg.test(value)
        if (emailReg.test(value)) {
            return true
        } else {
            return false
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        if (uname && password) {
            if(checkemail(uname)){
                let obj = {
                    email: uname,
                    password
                }
                setIsLoading(true)
                dispatch(adminActions.adminLogin(JSON.stringify(obj)))
                
            }else{
                setError("Email should be an email")
                setAlert()
        }
        } else {
            setError("Email & password mandatory")
            setAlert()
        }
    }
    const gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    return (
        <Fragment>

            {<div className="wrapper login_parent">

                <div className="login">
                    <div className="login-form">
                        <a href={`${process.env.REACT_APP_BASENAME}/home`}><img src={Logo} alt="" /></a>
                        <h3 className="login-title">Log in</h3>
                        <div className="form-wrapper">
                            <div className="input-wrapper">
                                {isAlert && <div className="alert alert-danger" role="alert">
                                    {error}
                                </div>}
                                <form onSubmit={handleSubmit} className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="label">Email<span className="mandatory">*</span></label>
                                            <input type="text" name="uname" value={uname} onChange={handleChange} className="input form-control" placeholder="Enter Email*" />
                                            {submitted && !uname && <div className="mandatory">{"Email/Username is Required!"}</div>}
                                            {uname && !checkemail(uname) && <div className="mandatory">{"Email should be an email"}</div>}
                                            {!submitted && !uname && <div className="mandatory-req">{"Required!"}</div>}

                                        </div>
                                    </div>

                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="label">Password<span className="mandatory">*</span></label>
                                            <input type="password" className="input form-control" name="password" value={password} placeholder="Enter Password*" onChange={handlePasswordChange} />
                                            {submitted && !password && <div className="mandatory">{"Password is Required!"}</div>}
                                            {!submitted && !password && <div className="mandatory-req">{"Required!"}</div>}

                                        </div>
                                    </div>



                                    <div className="actions col-sm-12 text-center">
                                        {isLoading && 
                                        <div class="admin-loader">
                                            <div class="inner one"></div>
                                            <div class="inner two"></div>
                                            <div class="inner three"></div>
                                        </div>}
                                       {!isLoading && <button type="submit" className="action btn btn-primary" >Sign in</button>}
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

        </Fragment>
    )

}

export { Login }