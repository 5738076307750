import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Navbar, Nav } from "react-bootstrap";
import Logo from "../../Assets/images/logo.png";
import { SiDiscord } from "react-icons/si";
import { FaTwitter, FaInstagram } from "react-icons/fa";
import { purchaseTokens } from  "./../../Utils";
import "./Header.css";
import { Link, useLocation } from 'react-router-dom'
import { authenticate, unauthenticate, currentUser } from "@onflow/fcl";
import { babActions } from "../../store/actions";
import { isConnectWallet } from "../../helpers/authorizedData";
import PubSub from 'pubsub-js';
import { useNavigate } from 'react-router'

const purchase = async () => {
  console.log("new purchase code");
  await purchaseTokens(97229449,"0xcdae9ecedcf76fed","5.0")
}

const Header = (props) => {
      const location = useLocation();
      const navigate = useNavigate()
      const pathname = location.pathname

      const dispatch = useDispatch()
      const useraAddress = useSelector(state => state.babreducer.useraddress)
      const [expanded, setExpanded] = useState(false);
      const [address,setAddress] = useState('')
      const [show,setShow] = useState(false)
      const [days,setDays] = useState('')
      const [hours,setHours] = useState('')
      const [minutes,setMinutes] = useState('')
      const [seconds,setSeconds] = useState('')

      useEffect(() => {
            initialAddressCall()
      },[])

      const initialAddressCall = () => {
            dispatch(babActions.getUSERADDRESS())
      }

      useEffect(() => {
            if(useraAddress && useraAddress != ''){
                  console.log("Cool its worked",useraAddress)
                  dispatch(babActions.resetAddress())
                  setAddress(useraAddress)
            }else{
                  console.log("===Api error===",useraAddress)
            }
      },[useraAddress])
      
      const dapperConnect = () => {
      showLoader()
            authenticate().then(res => {
                  hideLoader()
                  console.log("=====Dapper response====",res)
                  if(res && res.addr && res.addr !== null){
                        initialAddressCall()
                  }else{
                        console.log("====Response Failed",res)
                  }
            }).catch(error => {
                  hideLoader()
                  console.log("=====Dapper error====",error)
            })
      }
      function showLoader() {
            PubSub.publish('msg', true);
      }
      
      function hideLoader() {
            PubSub.publish('msg', false);
      }
      const gotoMint = () => {
            navigate('/mint')
      }
      // initialAddressCall
      const timeCounter = () => {

            var futureDate = process.env.REACT_APP_NFTREVEAl_TIME
            var countDownDate = new Date(futureDate).getTime();
            var now = new Date().getTime();
            var distance = countDownDate - now;
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
            if (distance < 0) {
                setShow(false)
            }else{
                  setShow(true)
                  setDays(days)
                  setSeconds(seconds)
                  setHours(hours)
                  setMinutes(minutes)
            }
            // return <div className="babctimer">NFT Reveal <span>{days}</span> Days <span>{hours}</span>h <span>{minutes}</span>m <span>{seconds}</span>s</div>

    
        }
        React.useEffect(() => {
            console.log(`initializing interval`);
            const interval = setInterval(() => {
                  timeCounter()
            }, 1000);
          
            return () => {
              console.log(`clearing interval`);
              clearInterval(interval);
            };
          }, []);
      return(
            <header>
                  <div className="header_content">
                        <Container>
                              <div className="flex">
                                   
                                    <div className="logo">
                                          <a href={`${process.env.REACT_APP_BASENAME}/home`}>
                                                <img src={Logo} alt="" />
                                          </a>
                                    </div>
                                    <div className="menus">
                                          <Navbar expand="lg" expanded={expanded}>
                                                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")}/>
                                                <Navbar.Collapse id="basic-navbar-nav">
                                                      <Nav className="me-auto">
                                                            <Nav.Link as={Link} to={`/home`} className={pathname.includes('home') ? "active" : ''} onClick={() => setExpanded(false)}>Home</Nav.Link>
                                                            <Nav.Link as={Link} to={`/collection`} className={pathname.includes('collection') ? "active" : ''} onClick={() => setExpanded(false)}>Collection</Nav.Link>
                                                            <Nav.Link as={Link} to={`/mint`} className={pathname.includes('mint') ? "active" : ''} onClick={() => setExpanded(false)}>Mint</Nav.Link>
                                                            {/* <Nav.Link as={Link} to={`/bearcave`}  className={pathname.includes('bearcave') ? "active" : ''} onClick={() => setExpanded(false)}>Bear Cave</Nav.Link> */}
                                                            <Nav.Link as={Link} to={`/community`}  className={pathname.includes('community') ? "active" : ''} onClick={() => setExpanded(false)}>Community</Nav.Link>

                                                            {/* <Nav.Link as={Link} to={`/bears`}  className={pathname.includes('bears') ? "active" : ''}>Bears</Nav.Link> */}
                                                            {/* <Nav.Link href={`${process.env.REACT_APP_BASENAME}/about`}  className={pathname.includes('about') ? "active" : ''}>About</Nav.Link> */}

                                                      </Nav>
                                                </Navbar.Collapse>
                                          </Navbar>
                                    </div>
                                    <div className="buttons">
                                          <div className="social_media">
                                                <ul>
                                                      <li>
                                                            <a href="https://twitter.com/badazz_bears?s=21&t=NZEXVmWNZROom1NLfnZLxw" target={'_blank'}>
                                                                  <FaTwitter size={24} />
                                                            </a>
                                                      </li>
                                                      <li>
                                                            <a href="https://instagram.com/badazzbears?igshid=YmMyMTA2M2Y=" target={'_blank'}>
                                                                  <FaInstagram  size={24} />
                                                            </a>
                                                      </li>
                                                      <li>
                                                            <a href="https://discord.io/badazzbears" target={'_blank'}>
                                                                  <SiDiscord size={24}  />
                                                            </a>
                                                      </li>
                                                </ul>
                                          </div>
                                          
                                               {/* <a href={`${process.env.REACT_APP_BASENAME}/bearcave`}>Join Bear Cave</a> */}
                                                {/* {isConnectWallet() ? <a className="dapper_add">{address}</a> :  <a onClick={dapperConnect} >Connect Wallet</a>}  */}
                                                {pathname.includes('mint') ? '' : <div className="join_button"><a onClick={gotoMint}>Mint Now</a></div>}
                                                {/* <a onClick={purchase} >Purchase</a> */}
                                          
                                    </div>
                              </div>
                        </Container>
                        {/* {show ? <div className="babctimer">NFT reveal in <span>{days ? days : ''}</span>{days ? (days > 1) ? "Days " : "Day "  : ''}<span>{hours}</span>h <span>{minutes}</span>m <span>{seconds}</span>s</div> : ''} */}
                  </div>
            </header>
      );
}

export default Header;
