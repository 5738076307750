import React, { Component } from 'react';
import './Menu.css'
class Menu2 extends React.Component {
    render() {
        return (
            <main className="content-shift">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <p>Menu2</p>
                            </div>
                            </div>
                            </div>
            </main>
        )
    }
}
export {Menu2}