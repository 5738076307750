import React, { Component, Fragment } from 'react'

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }
   
    render() {
        return (
            <Fragment>

                <div className="aboutpage orangebg text-justify">
                    <div className='container'>
                    <div className='row'>
                                                <div className='col-md-8 offset-md-2'>
                <h1 className='mb-4'>Badazz Origins</h1>
                <p className='mb-5'>Badazz Bears is a NFT Project backed by Jim Jones based on original concepts from Jim’s Uncle.
                Jim’s Uncle spent much of his time in prison. The Bears were a point of connection and healing
                for Jim Jones and his Uncle. Prison art like the Bears is part of the long history of captive people
                finding ways to resist, survive and heal. Stories like these need to be documented on the
                blockchain. <br/><br/>
                
                The first mint in the Bear Cave will be for 1801 Bears as that was the address where Jim and his
                uncle grew up. There will be 9,005 Bears sold on Disrupt Art. A major focus of this project is to
                create a community fund for returning citizens to find jobs and fight for criminal justice reform.
                Disrupt Art and Badazz Bears will work with Organizations like the REFORM Alliance and the
                Alliance for Safety and Justice (ASJ).
                <br/><br/>
                The Fashion and accessories of the Bears are based on a period when music and culture
        reached a pinnacle, the 90s. The fashion was kooje sweaters, bright colors, and gold chains. The
        music was iconic 2Pac and Biggie, Snoop Dog, Wu Tang, DMX, Brittany and Michael Jackson.</p>
            <h2 className='mb-4'>GOALS</h2>
            <ol className='mb-5 numlist'>
                <li><span>1.</span> Build a Badazz Community</li>
<li><span>2.</span> Provide Access to in person and online events.</li>
<li><span>3.</span> Providing Resources to Returning Citizens.</li>
            </ol>
<h2 className='mb-4'>UTILITIES</h2>
<p>Badazz Bears will be minted on Disrupt Art, but will be able to be resold on the Rarible
marketplace for Flow. Bear Owners will have access to exclusive digital Behind-the-Scenes
Experiences with Jim Jones and drawings for chances to Meet Jim in-person at one of his events.
<br/><br/>
A Build-a-Bear style utility is planned for the second half of the project. Those in the Bear cave
will be given first mint access. Mint holders will receive honey as a mint pass where they can
personalize their Bear(s) to add MetaDrip 90s style Clothing & Accessories.</p>
                </div></div>
                </div></div>

            </Fragment>
        )
    }
}


export default About;