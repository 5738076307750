import React, { Component, Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from '../../../../store/actions';
import moment from 'moment'
import { Modal, Button } from "react-bootstrap";
import { configPath } from '../../../../config';

function FetchSlots() {
    const [slots, setSlots] = useState([]);
    const [slotCount,setSlotCount] = useState(0)
    const [openModal,setOpenModal] = useState(false)
    const [selectedItem,setSelectedItem] = useState([])
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const getSlots = useSelector(state => state.adminreducer.slots)
    const freezeSlotResponse = useSelector(state => state.adminreducer.freezeSlotRes)

    const gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    useEffect(() => {
        initialCall()
        console.log(getSlots)

    },[])
    const initialCall = () => {
        gotoTop()
        let obj = {}
        dispatch(adminActions.getSlots(obj,(configPath.pfpId)))
    }
    useEffect(() => {
        console.log(getSlots)

        if(getSlots && getSlots.statusCode === 200){
            dispatch(adminActions.resetGetSlot())
            if(getSlots && getSlots.data && getSlots.data.slots){
                // setSlotCount(getSlots.data && getSlots.data.count)
                setSlots(getSlots.data.slots)
            }
        }
    },[getSlots])
    console.log(getSlots)
   



    const editAccount = (items) => {
        if ((items && items.id)) {
            navigate(`/creatoradmin/slots/edit/${items.id}`)
        }
    }
    const freezeSlot = (items) => {
        if((items && items.status === 2)){
            return;
        }else{
            setOpenModal(true)
            setSelectedItem(items)
        }
        
    }

    const gotoaddslot = () => {
        navigate('/creatoradmin/slots/add')
    }
    const closeModal = () => {
        setOpenModal(false)

    }
    const handleFreeze = () => {
        if((selectedItem && selectedItem.id)){
            
            setOpenModal(false)
            dispatch(adminActions.freezeSlot(1,(selectedItem.id)))
            setSelectedItem([])
        }
    }
    useEffect(() => {
        if((freezeSlotResponse && freezeSlotResponse.statusCode === 200)){
            dispatch(adminActions.resetFreezeSlot())
            initialCall()

        }
    },[freezeSlotResponse])
    const AccountList = slots && slots.map((items) => {
        return <tr>
                <td><a >{items && items.name}</a> </td>
                <td><a >{items && items.mintCount}</a></td>
                <td><a >{items && items.startTime && moment(items.startTime).format('DD/MM/YYYY' )}</a></td>
                <td><a >{items && items.endTime && moment(items.endTime).format('DD/MM/YYYY')}</a></td>
                <td><a >{items && items.createdAt && moment(items.createdAt).format('DD/MM/YYYY hh:mm:ss A')}</a></td>
                <td>
                    <a className="btn btn-outline-primary btn-sm btn-action mr-2" type="button" onClick={() => { editAccount(items) }} data-toggle="tooltip" data-placement="bottom" title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></a>
                    <span onClick={() => { freezeSlot(items) }} >
                    {((items && items.status === 2)) ? <button className="btn btn-outline-primary btn-sm btn-action" type="button" disabled data-toggle="tooltip" data-placement="bottom" title="Frozen">                        
                        <i className="fa fa-lock" aria-hidden="true"></i></button> : <button className="btn btn-outline-primary btn-sm btn-action" type="button" data-toggle="tooltip" data-placement="bottom" title="Freeze"><i class="fa fa-unlock" aria-hidden="true"></i></button>
                        }
                        </span>
                </td>
            </tr>
    })
    return (
        <Fragment>
            <Modal show={openModal} onHide={(e) => closeModal(e, this)} size="lg" className="bab-popup" aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header >
                    <Modal.Title>Freeze Slot</Modal.Title>
                    <Button variant="outline-primary" onClick={closeModal}>
                        Close <i className="fa fa-times mr-0"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group my-5 text-center">
                        <h2 className='mr-5'>Are you sure you want to Freeze slot ? </h2>
                        <p className='text-center'>You cannot undo this action</p>
                        <div className='my-5 d-flex justify-content-between'>
                            <button type='button' className='btn btn-outline-primary mr-3' onClick={closeModal}>No</button>
                            <button type='button' className='btn btn-primary' onClick={handleFreeze}>Yes</button>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="smallilac px-3">
            <div className='ca-card mb-3 '>
                <div className="row admintitle align-items-center">
                    <div className="col-sm-6"><h2 className="font-weight-bold">Slots  </h2></div>
                    {slotCount < 1 && <div className="col-sm-6 text-right"><button className=" btn btn-outline-primary" type="button" onClick={gotoaddslot}><i class="fas fa-plus"></i> Add Slot</button>  </div>}
                    </div>
                </div>
                <div className='ca-card'>
                    <div className="table-responsive">
                        <table className="table table-bordered ca-table-custom">
                            <thead>
                                <tr>
                                    <th>SLOT NAME</th>
                                    <th>MINT COUNT</th>
                                    <th>START DATE</th>
                                    <th>END DATE</th>
                                    <th>CREATED AT</th>
                                    <th>ACTION</th>
                                </tr>
                            </thead>
                            <tbody>
                            {AccountList}
                            </tbody>

                        </table>
                        {(slots && slots.length > 0) ?  '': <div className="col-md-12 text-center">
                                        No slots
                                    </div>}
                    </div>
                </div>
            </div>

        </Fragment>
    )

}


export { FetchSlots }