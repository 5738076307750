import {config} from "@onflow/fcl"

let BASE_PATH = "https://disrupt-bab-api.disrupt.art";
let API_PATH = `${BASE_PATH}/api/v1`;

export const configPath = {
  baseUrl:BASE_PATH,
  apiUrl:API_PATH,
  imageAssets: ((process.env.REACT_APP_PUBLIC_ASSET_PATH && process.env.REACT_APP_PUBLIC_ASSET_PATH.toLowerCase()!=='null')?`../${process.env.REACT_APP_PUBLIC_ASSET_PATH}/assets/images`:"assets/images"),
  pfpId : 1,
  slotId : 1
 
}
console.log("Inside code")
console.log(process.env.REACT_APP_ACCESS_NODE)
config()
  .put("accessNode.api", process.env.REACT_APP_ACCESS_NODE) // Configure FCL's Access Node
  .put("acccessNode.restApi", process.env.REACT_APP_ACCESS_NODE)
  .put("discovery.wallet", process.env.REACT_APP_WALLET_DISCOVERY) // Configure FCL's Wallet Discovery mechanism
  .put("app.detail.title", "BadAZZBEAR") 
  .put("discovery.wallet.method", process.env.REACT_APP_DAPPER_WALLET_DISCOVERY_METHOD)
