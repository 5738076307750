import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { babActions } from "../store/actions";
import { Modal, Button } from "react-bootstrap";
function MintWaitList(props) {
    var timeout;

    const [email, setEmail] = useState('')
    const [submitted, setSubmitted] = useState(false)
    const [isEmailPage, setIsEmailPage] = useState(true)
    const [isEmailError,setIsEmailError] = useState('')
    const [isEmailFailed, setIsEmailFailed] = useState(false)
    const [isOtp,setIsOtp] = useState(false)
    const [otp,setOtp] = useState('')
    const [regEmail,setRegEmail] = useState('')
    const [openModal, setOpenModal] = useState(false)

    const dispatch = useDispatch() //dispatch action
    const regUser = useSelector(state => state.babreducer.r_user)
    const regUserFailed = useSelector(state => state.babreducer.r_user_fail)
    const verifyUser = useSelector(state => state.babreducer.v_user)
    const verifyUserFailed = useSelector(state => state.babreducer.v_user_fail)

    useEffect(() => {
        console.log(props)
    },[])
    const handleEmail = (e) => {
            setEmail(e.target.value.toLowerCase())

    }
    const checkemail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailReg.test(value)) {
            return true
        } else {
            return false
        }
    }
    useEffect(() => {
        if((regUser && regUser.statusCode) === 200){
            dispatch(babActions.resetRegisterUser())
            if(regUser && regUser.message && regUser.message.toLowerCase().includes('email verification code successfully sent')){
                setIsEmailPage(false)
                setIsOtp(true)
                setIsEmailFailed(false)
                setSubmitted(false)
                setRegEmail(email)
            }else if(regUser && regUser.data && regUser.data.user && regUser.data.user.verificationStatus == 1){
               saveLocalStorage(regUser.data.user && regUser.data.user.email)
                props.updatePage("true")
            }
        }
    },[regUser])
    useEffect(() => {
        if(regUserFailed && regUserFailed.length > 0){
            if(regUserFailed && regUserFailed.toLowerCase().includes("this email is still not verified")){
            dispatch(babActions.resetRegisterUserFailed())
            // setIsEmailError(regUserFailed)
                setIsEmailPage(false)
                setIsOtp(true)
                setIsEmailFailed(false)
                setSubmitted(false)
                setRegEmail(email)
            }else if(regUserFailed && regUserFailed.toLowerCase().includes("this email is not in waitlist")){
                dispatch(babActions.resetRegisterUserFailed())
                setIsEmailPage(true)
                // setIsEmailError("Currently Badazz Bears NFTs can be purchased by Bear cave waitlist members only! Please visit after some time.")
                setIsEmailError("You must be a Bear Cave Member to mint during this time. Please return on June 22nd 3PM EST for the Public Mint.")
                setOpenModal(true)
                setEmail('')
                setSubmitted(false)
            }else{
                dispatch(babActions.resetRegisterUserFailed())
                setIsEmailPage(true)
                setIsEmailError(regUserFailed)
                setOpenModal(true)
                setEmail('')
                setSubmitted(false)
            }
        }
    },[regUserFailed])
    useEffect(() => {
        if(verifyUserFailed && verifyUserFailed.length > 0){
            if(verifyUserFailed && verifyUserFailed.toLowerCase().includes("this verification code is invalid")){
                dispatch(babActions.resetVerifyFailUser())
                setIsEmailPage(false)
                setIsOtp(true)
                setIsEmailError(verifyUserFailed)
                setOpenModal(true)
                setOtp('')
                setSubmitted(false)
            }else{
                dispatch(babActions.resetVerifyFailUser())
                setIsEmailPage(false)
                setIsOtp(true)
                setIsEmailError(verifyUserFailed)
                setOpenModal(true)
                setOtp('')
                setSubmitted(false)
            }
        }
    },[verifyUserFailed])
    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true)
        if (email && checkemail(email)) {
            let obj = {
                email : email
            }
            dispatch(babActions.registerUser(JSON.stringify(obj)))
        }
    }
    const digitValidate = (s) => {
        var n = Number(s)
        var regex = /[.]/g
        
        if ((n <= 999999) && (n % 1 === 0) && !(regex.test(s))) {
            if (!s.match(/\d{0,6}\.\d{6}/)) {

                return true
            }else{
                return true
            }
        } 
        return false
    }
    const handleOtp = (e) => {
        if(digitValidate(e.target.value)){
            if((e.target.value).length <= 6){
                setOtp(e.target.value)
            }
            
        }
    }
    const submitOtp = (e) => {
        e.preventDefault()
        setSubmitted(true)
        if((otp && otp.length === 6)){
            let obj = {
                email : regEmail,
                verificationCode : otp
            }
            dispatch(babActions.verifyUser(JSON.stringify(obj)))
        console.log(otp)
        }
    }
    const closeAlert = () => {
        timeout = setTimeout(() => {
            setIsEmailError('')
        }, 15000)
    }
    const resetTimeout = () => {
        clearTimeout(timeout)
    }
    const hideAlert = () => {
        setIsEmailError('')
    }
    // useEffect(() => {
    //     if(isEmailError){
    //         resetTimeout()
    //         closeAlert()
    //     }
    // },[isEmailError])
    useEffect(() => {
        if((verifyUser && verifyUser.statusCode) === 200){
            dispatch(babActions.resetVerifyUser())
            saveLocalStorage(regEmail)
            props.updatePage("true")
        }
    },[verifyUser])
    const saveLocalStorage = (email) => {
        var now = new Date().getTime();
            localStorage.setItem("User",JSON.stringify(email))
            localStorage.setItem('setupTime', now)
    }
    const closeModal = () => {
        setOpenModal(false)

    }
    if(isEmailPage){
    return (
        <Fragment>
        <Modal show={openModal} onHide={(e) => closeModal(e, this)} size="md" className="bab-popup" aria-labelledby="contained-modal-title-vcenter"
                centered>               
                <Modal.Body className="d-flex flex-column align-items-center my-5 ">
                    <div className="form-group text-center">
                    <h1 className="text-white mb-3">We couldn't find you in bear cave</h1>
                    <h4 className="my-3 ">{isEmailError}</h4>                      
                    </div>
                    <Button variant="outline-primary" onClick={closeModal}>
                        Close
                    </Button>
                </Modal.Body> 
            </Modal>
        <div className="collection orangebg">
            <div className="container text-center pagenotfound py-5">
                <div class="container">
                    <form onSubmit={handleSubmit}>
                    <div class="row justify-content-center">
                        <div class="col-md-4 text-center">
                            <label className="font-weight-bold" htmlFor="email">Email</label>
                            <input className="form-control" id="email" type="email" value={email} onChange={handleEmail} placeholder="Enter email" />
                            {submitted && !email && <div className="mandatory">Email required</div>}
                            {submitted && email && !checkemail(email) && <div className="mandatory">Please enter valid email</div>}
                            
                            <div>
                                <button type="submit" className="btn btn-primary mt-3" >Submit</button>
                            </div>
                            
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </div>
        </Fragment>
    );
    }
    else if(isEmailFailed){
        return (
            <div className="collection orangebg">
            <div className="container text-center pagenotfound py-5">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-12 text-center">
                        <svg className="crosscus" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                 <circle className="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                 <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                                 <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                             </svg>
                            <h1>Oops!</h1>
                            <p className="mt-3">Sorry, The email was not found</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
    else if(isOtp){
        return (
            <Fragment>
                <Modal show={openModal} onHide={(e) => closeModal(e, this)} size="md" className="bab-popup" aria-labelledby="contained-modal-title-vcenter" centered>               
                <Modal.Body className="d-flex flex-column align-items-center my-5 ">
                    <div className="form-group text-center">
                    <h1 className="text-white mb-3">WARNING</h1>
                    <h4>{isEmailError}</h4>                                   
                    </div>
                    <Button variant="outline-primary" onClick={closeModal}>
                        Close 
                    </Button>
                </Modal.Body>
            </Modal>
            <div className="collection orangebg">
                <div className="container text-center pagenotfound py-5">
                    <div class="container">
                        <form onSubmit={submitOtp}>
                            <div class="row justify-content-center">
                                <div class="col-md-4 text-center">
                            
                            
                                <label className="font-weight-bold" htmlFor="otp">Kindly check your email for a 6 digit OTP code</label>
                                {/* <div class="digit-group">
                                    <input type="text" id="digit-1" name="digit-1" data-next="digit-2" />
                                    <input type="text" id="digit-2" name="digit-2" data-next="digit-3" data-previous="digit-1" />
                                    <input type="text" id="digit-3" name="digit-3" data-next="digit-4" data-previous="digit-2" />                                
                                    <input type="text" id="digit-4" name="digit-4" data-next="digit-5" data-previous="digit-3" />
                                    <input type="text" id="digit-5" name="digit-5" data-next="digit-6" data-previous="digit-4" />
                                    <input type="text" id="digit-6" name="digit-6" data-previous="digit-5" />
                                </div> */}


                                    
                                    <input className="form-control text-center font-weight-bold" id="otp" type="text" value={otp} onChange={handleOtp} placeholder="Enter OTP code" />
                                    {submitted && !otp && <div className="mandatory">OTP required</div>}
                                    {submitted && otp && (otp && otp.length != 6) && <div className="mandatory">Please enter valid OTP</div>}
                                    <div>
                                        <button type="submit" className="btn btn-primary mt-3">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
        </div>
        </Fragment>
        )
    }else{
        return ("")
    }
}
export { MintWaitList }