import { babConstants } from "../../constants";

const initialState = {
    allArts : [],
    frontAttr : [],
    r_user : [],
    v_user : [],
    r_user_fail : [],
    v_user_fail : [],
    useraddress : '',
    emailWallet : [],
    paymentIntRes : [],
    paymentIntFail : []
}
export function babreducer(state=initialState,action){
    switch(action.type){
        case babConstants.GET_ARTS:
            return {
                ...state,
                allArts : action.allArts,
            }
            case babConstants.GET_ATTRIBUTE_FILTERS:
                return{
                    ...state,
                    frontAttr : action.frontAttr,
                }
                case babConstants.REGISTER_USER:
                    return {
                        r_user : action.r_user
                    }
                    case babConstants.VERIFY_USER:
                        return {
                            v_user : action.v_user
                        }
                        case babConstants.REGISTER_USER_FAILED:
                            return {
                                r_user_fail : action.r_user_fail
                            }
                            case babConstants.VERIFY_USER_FAIL:
                                return {
                                    v_user_fail : action.v_user_fail
                                }
                                case babConstants.GET_USER_ADDRESS:
                                    return {
                                        useraddress : action.useraddress
                                    }
                                    case babConstants.EMAIL_WALLET_PURCHASE:
                                        return {
                                            emailWallet : action.emailWallet
                                        }
                                        case babConstants.PAYMENT_INTENT:
                                            return {
                                                paymentIntRes : action.paymentIntRes
                                            }
                                            case babConstants.PAYMENT_INTENT_FAILED:
                                                return {
                                                    paymentIntFail : action.paymentIntFail
                                                }
                                        default:
                                            return state
    }
}