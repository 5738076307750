import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkoutForm";
import { Modal, Button } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from "react-redux";

import './Payment.css'
import { babActions } from "../../store/actions";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(process.env.REACT_APP_LOADSTRIPE)

export default function Payment(props) {
  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch() //dispatch action

  const payRes = useSelector(state => state.babreducer.paymentIntRes)
  const [clientSecret, setClientSecret] = useState("");
  const [openModal, setOpenModal] = useState(false)
  const [totalArtAmount,setTotalArtAmount] = useState(0)
  const [nftAmount,setNftAmount] = useState(0)
  const [txnAmount,setTxnAmount] = useState(0)
  useEffect(() => {
    if((payRes && payRes.statusCode) === 200){
      
      dispatch(babActions.resetPaymentInt())
      if(payRes && payRes.data && payRes.data.clientSecret && payRes.data.clientSecret != null){
        setClientSecret(payRes.data.clientSecret)
        setTotalArtAmount(payRes.data && payRes.data.totalAmount)
        setNftAmount(payRes.data && payRes.data.baseAmount)
        setTxnAmount(payRes.data && payRes.data.transactionFee)
        setOpenModal(true)
      }
    }
  },[payRes])


  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };
  const checkmessage = (value) => {
    console.log("message", value)
  }
  const closeModal = () => {
    setOpenModal(false)
    navigate('/mint')
  }
  const gotoHome = () => {
    navigate('/')
}
  return (
    <div className="App">
      {clientSecret ?
        <div ><Modal show={openModal} onHide={(e) => closeModal(e, this)} size="lg" className="bab-popup orangebg1" aria-labelledby="contained-modal-title-vcenter"
          backdrop={"static"} centered>
          <Modal.Header >
            <Modal.Title>Payment</Modal.Title>
            <Button variant="outline-primary" onClick={closeModal}>
              Close <i className="fa fa-times mr-0"></i>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group form-div text-center ">   
              <table className="table table-bordered text-left babptable" >                
                <tr><td>NFT PRICE</td><td>${nftAmount}</td></tr>
                <tr><td>TRANSACTION FEE</td><td>${txnAmount}</td></tr>
                <tr><td>Total</td><td>${totalArtAmount}</td></tr>
              </table>              
              <Elements options={options} stripe={stripePromise} checkmessage={(val) => checkmessage(val)}>
                <CheckoutForm />
              </Elements>
            </div>

          </Modal.Body>
        </Modal>


        </div>
        : <div className="container text-center pagenotfound py-5">
        <div class="container">
    <div class="row justify-content-center">
        <div class="col-md-12 text-center">
        
            <h4 class="display-5 d-block">Sorry process cancelled</h4>
            <button className="btn btn-primary px-5 mt-3" type="button" onClick={gotoHome}>Back to Home</button>
        </div>
    </div>
</div>
        </div>}
    </div>
  );
}