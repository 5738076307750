import React, { Component, Fragment } from 'react';
import { Container, Modal, Button, Carousel } from "react-bootstrap";
import Banner from "../Assets/images/banner.png";
import Banner1 from "../Assets/images/banner1.png";
import BanText from "../Assets/images/ban_text.png";
import Img1 from "../Assets/images/sec1_img1.png";
import Img2 from "../Assets/images/sec1_img2.png";
import blkImg1 from "../Assets/images/blk_img1.png";
import blkImg2 from "../Assets/images/blk_img2.png";
import blkImg3 from "../Assets/images/blk_img3.png";
import blkImg4 from "../Assets/images/blk_img4.png";
import blkImg5 from "../Assets/images/blk_img5.png";
import { SiDiscord } from "react-icons/si";
import { FaTwitter, FaInstagram } from "react-icons/fa";
import BearImg1 from "../Assets/images/Jim-Jones.png";
import BearImg2 from "../Assets/images/Darklarcs.png";
import BearImg3 from "../Assets/images/Rob-Richardson.png";
import BearImg4 from "../Assets/images/Evan-Erpenbeck.png";
import BearImg5 from "../Assets/images/Jason-Martin.png";
import RD1 from "../Assets/images/road-map-1.png";
import RD2 from "../Assets/images/road-map-2.png";
import RD3 from "../Assets/images/road-map-3.png";


class Home extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  openModal: false
            }
            this.formRef = React.createRef();
      }
      componentDidMount() {
            this.setState({
                  openModal: false
            })
      }
      closeModal = () => {
            this.setState({
                  openModal: false
            })
      }
      handleSubmit = (e, form) => {
            e.preventDefault()
            this.setState({
                  openModal: false
            })
            if (this.formRef.current) {
                  this.formRef.current.action = `${process.env.REACT_APP_WAITIST_FORM_URL}`
                  this.formRef.current.method = "POST"
                  this.formRef.current.submit()
            }

      }
      render() {
            return (
                  <Fragment>
                        <Modal show={this.state.openModal} onHide={this.closeModal} size="lg" className="bab-popup orangebg1" aria-labelledby="contained-modal-title-vcenter"
                              centered>
                              <Modal.Header >
                                    <Modal.Title>BEARCAVE</Modal.Title>                                   
                                    <Button variant="outline-primary" onClick={this.closeModal}>
                                          Close <i className="fa fa-times mr-0"></i>
                                    </Button>
                              </Modal.Header>
                              <Modal.Body>
                                    <div className="form-group mt-5 text-center">

                                          <div id="mc_embed_signup">
                                                <form onSubmit={this.handleSubmit} ref={this.formRef} id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
                                                      <div id="mc_embed_signup_scroll" className='row'>
                                                            {/* <h2>Subscribe</h2> */}
                                                            {/* <div className="indicates-required col-sm-12"><span className="asterisk">*</span> indicates required</div> */}
                                                            <div className="mc-field-group form-group mb-4 col-sm-6">

                                                                  <input type="email" name="EMAIL" className="required email form-control" id="mce-EMAIL" placeholder='Email' required />
                                                            </div>
                                                            <div className="mc-field-group form-group mb-4 col-sm-6">
                                                                  <input type="text" name="FNAME" className="form-control" id="mce-FNAME" placeholder='Name' required />
                                                            </div>
                                                            <div className="mc-field-group form-group mb-4 col-sm-6">
                                                                  <input type="text" name="TWITTER" className="form-control" id="mce-TWITTER" placeholder='Twitter' />
                                                            </div>
                                                            <div className="mc-field-group form-group mb-4 col-sm-6">
                                                                  <input type="text" name="DISCORD" className="form-control" id="mce-DISCORD" placeholder='Discord' />
                                                            </div>
                                                            <div id="mce-responses" className="clear">
                                                                  <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
                                                                  <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                                                            </div>

                                                            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                                                                  <input type="text" name="b_ae99ac9a4fafd8609771595ff_fe021b9892" tabIndex="-1" />
                                                            </div>
                                                            <div className="form-group col-sm-12 mt-4">
                                                                  <input type="submit" value="Join Waitlist" name="subscribe" id="mc-embedded-subscribe" className="btn btn-primary" />
                                                            </div>
                                                      </div>
                                                </form>
                                          </div>
                                    </div>
                              </Modal.Body>
                        </Modal>
                        <div className="root_content">

                              <main>
                                    <div className="banner">
                                          <Carousel style={{ position: 'relative' }} fade={true}>
                                                <Carousel.Item>
                                                      <img src={Banner} alt="" />
                                                      <Carousel.Caption>
                                                      <div className="banner_content">
                                                            <Container>
                                                                  <img src={BanText} alt="" />
                                                                  <h2>Buy, Sell and  Trade your Badazz Bears</h2>
                                                            </Container>
                                                      </div>
                                                      </Carousel.Caption>
                                                </Carousel.Item>
                                                <Carousel.Item>
                                                      <img src={Banner1} alt="" />
                                                      <Carousel.Caption>
                                                      <div className="banner_content">
                                                            <Container>
                                                                  <img src={BanText} alt="" />
                                                                  <h2>Buy, Sell and  Trade your Badazz Bears</h2>
                                                            </Container>
                                                      </div>
                                                      </Carousel.Caption>
                                                </Carousel.Item>
                                          </Carousel>
                                    </div>
                                    <div className="section_one">
                                          <Container>
                                                <div className="flex">
                                                      <div className="text">
                                                            <h2>Welcome to the <span>Badazz Bears</span></h2>
                                                            <p>9,005 Badazz Bears are coming out of hibernation to bring you all the drip and hip-hop swag. Our Badazz community is setting the trends and changing outcomes for the Metaverse and beyond.</p>
                                                            <a href="https://discord.io/badazzbears" target={'_blank'}>Join Discord</a>
                                                      </div>
                                                      <div className="img_box">
                                                            <div className="box">
                                                                  <div className="top">
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                  </div>
                                                                  <div className="bottom">
                                                                        <div className="img">
                                                                              <img src={Img1} alt="" />
                                                                        </div>
                                                                        <div className="txt">
                                                                              <ul>
                                                                                    <li>
                                                                                          <span>#1804</span>
                                                                                          Mint
                                                                                    </li>
                                                                                    <li>
                                                                                          <span>Rare</span>
                                                                                          Rarity
                                                                                    </li>
                                                                                    <li>
                                                                                          <span>2.2</span>
                                                                                          Rarity Score
                                                                                    </li>
                                                                                    <li>
                                                                                          <span>19.99</span>
                                                                                          Sale Price
                                                                                    </li>
                                                                              </ul>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            <div className="box">
                                                                  <div className="top">
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                  </div>
                                                                  <div className="bottom">
                                                                        <div className="img">
                                                                              <img src={Img2} alt="" />
                                                                        </div>
                                                                        <div className="txt">
                                                                              <ul>
                                                                                    <li>
                                                                                          <span>#1805</span>
                                                                                          Mint
                                                                                    </li>
                                                                                    <li>
                                                                                          <span>Rare</span>
                                                                                          Rarity
                                                                                    </li>
                                                                                    <li>
                                                                                          <span>4.2</span>
                                                                                          Rarity Score
                                                                                    </li>
                                                                                    <li>
                                                                                          <span>25</span>
                                                                                          Sale Price
                                                                                    </li>
                                                                              </ul>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                          </Container>
                                    </div>
                                    <div className="section_two">
                                          <Container>
                                                <div className="flex">
                                                      <div className="blk">
                                                            <img src={blkImg1} alt="" />
                                                      </div>
                                                      <div className="blk">
                                                            <img src={blkImg2} alt="" />
                                                      </div>
                                                      <div className="blk">
                                                            <img src={blkImg3} alt="" />
                                                      </div>
                                                      <div className="blk">
                                                            <img src={blkImg4} alt="" />
                                                      </div>
                                                      <div className="blk">
                                                            <img src={blkImg5} alt="" />
                                                      </div>

                                                </div>
                                          </Container>
                                    </div>

                                    <div className='section_three thestory'>
                                          <Container>
                                                <div className='row'>
                                                      <div className='col-md-8 offset-md-2'>
                                                            <div className='head text-left'>
                                                                  <h1>The Story</h1>
                                                            </div>

                                                            <h5 className='mb-5'>
                                                                  Badazz Bears is a NFT Project backed by Jim Jones - an American rapper, artist, television star, producer, and entrepreneur - based on original concepts from his Uncle. Jim’s Uncle spent much of his time in prison and turned to Art as a point of connection and healing with his family.<br /><br />
                                                                  A major focus of this project is to empower communities and create awareness for criminal justice reform through support of organizations like the REFORM Alliance and the Alliance for Safety and Justice (ASJ).  The first mint in the Bear Cave will be for 1801 Bears synonymous with the address where Jim and his uncle grew up. There will be 9,005 Bears sold on Disrupt Art.<br /><br />
                                                                  The Fashion and accessories of the Bears resonate with hip hop culture - Coogi sweaters, bright colors, and self-expression influenced by Music Icons 2Pac, Biggie, Snoop Dog, Wu-Tang Clan, DMX, Missy Elliot, Da Brat, Outkast, and many others.</h5>


                                                            <h2 className='mb-3 mt-5'>GOALS</h2>
                                                            <ol className='mb-5 numlist'>
                                                                  <li><h5><span>1.</span> Build a Badazz Community</h5></li>
                                                                  <li><h5><span>2.</span> Provide Access to In-Person and Online Events.</h5></li>
                                                                  <li><h5><span>3.</span> Providing Resources to Returning Citizens.</h5></li>
                                                            </ol>
                                                            <h2 className='mb-3 mt-5'>UTILITIES</h2>
                                                            <h5>Badazz Bears will be minted through Disrupt Art with the Dapper Wallet, and will be able to be sold on the Rarible marketplace. Bear Owners will also have access to exclusive Behind-the-Scenes Experiences with Jim Jones and drawings for chances to Meet Jim in-person at one of his events.<br /><br />
                                                                  A Build-a-Bear system is planned for this project. Those in the Bear Cave will be given early access to mint on June 20, 2022. Our Badazz holders will be able to customize their Bear(s) to add 90s themed Traits & Accessories. Later in the project the community will even be able to help create new traits and leave their mark on this project.

                                                            </h5>


                                                      </div>
                                                </div>

                                          </Container>
                                    </div>

                                    <div className='section_three'>
                                          <Container>
                                                <div className='head'>
                                                      <h1>Our Roadmap</h1>
                                                </div>
                                                <div className='body'>
                                                      <div className='rows'>
                                                            <div className='blks'>
                                                                  <div className='h_content'>
                                                                        <h4>April 2022</h4>
                                                                  </div>
                                                            </div>
                                                            <div className='blks'>
                                                                  <div className='b_content'>
                                                                        <h5>Website and Bear Cave Launch</h5>
                                                                        <p>The site went live with sign-up for the Bear Cave (Presale List). The ﬁrst 5k people that join Discord will have the opportunity to participate in giveaways and promotional events.</p>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      <div className='rows'>
                                                            <div className='blks'>
                                                                  <div className='h_content'>
                                                                        <h4>June 20, 2022</h4>
                                                                  </div>
                                                                  <div className='h_img'>
                                                                        <img src={RD1} alt="" />
                                                                  </div>
                                                            </div>
                                                            <div className='blks'>
                                                                  <div className='b_content'>
                                                                        <h5>First Batch Private Mint </h5>
                                                                        <p>Collectors that signed up for the Bear Cave (Presale List) will have an opportunity to mint one of the first Badazz Bears before anyone else. 1,801 mints will be available for minting. Some will be reserved for Giveaways, VIPs, and Community Events. This is 20% of all total mints for Badazz Bears. The community will also be rewarded with giveaways of Flow tokens, Access to Badazz Bears 2.0 for Bear Owners, Exclusive Merch Access, and Bear Owner only events such as private empowerment sessions with Jim Jones and Friends.</p>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      <div className='rows'>
                                                            <div className='blks'>
                                                                  <div className='h_content'>
                                                                        <h4>June 22, 2022</h4>
                                                                  </div>
                                                            </div>
                                                            <div className='blks'>
                                                                  <div className='b_content'>
                                                                        <h5>First Batch Public Mint</h5>
                                                                        <p>The remaining mints from the first batch become available to the public. The first 2 Batches of OG Bears will be unique with special backgrounds and accessories exclusive to the first batch of Bears. Those traits will be marked with special OG Tags to show off their importance.</p>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      <div className='rows'>
                                                            <div className='blks'>
                                                                  <div className='h_content'>
                                                                        <h4>TBD</h4>
                                                                  </div>
                                                            </div>
                                                            <div className='blks'>
                                                                  <div className='b_content'>
                                                                        <h5>Second Batch Release</h5>
                                                                        <p>2,702 mints will be released for Private and Public minting. This is 30% of all total mints for Badazz Bears. Some will be reserved for Giveaways, Community Events, and Members of the Badazz Bears Team. The 2nd Batch of Bears will close out the release of the “OG Bears” which will be marked with Special OG Tags</p>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      <div className='rows'>
                                                            <div className='blks'>
                                                                  <div className='h_content'>
                                                                        <h4>TBD</h4>
                                                                  </div>
                                                                  <div className='h_img'>
                                                                        <img src={RD2} alt="" />
                                                                  </div>
                                                            </div>
                                                            <div className='blks'>
                                                                  <div className='b_content'>
                                                                        <h5>Third Batch Release</h5>
                                                                        <p>The final 4,502 mints will be released for Private and Public minting. This is 50% of all total mints for Badazz Bears. Some will be reserved for Giveaways, Community Events, and Members of the Badazz Bears Team. The 3rd Batch of Bears will be the final group of Bears minted and will complete the collection of 9,005 mints. The fun doesn’t stop here.</p>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      <div className='rows'>
                                                            <div className='blks'>
                                                                  <div className='h_content'>
                                                                        <h4>TBD</h4>
                                                                  </div>
                                                            </div>
                                                            <div className='blks'>
                                                                  <div className='b_content'>
                                                                        <h5>Build-a-Bear Utility Launch</h5>
                                                                        <p>The Ultimate goal is for collectors to be able to change out traits on their Bears, so that every Bear is influenced by their owner. We will build tools so that collectors can switch out traits and drip out their Bears whenever they want. We will plan out trait drops and community voting on new traits.</p>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      <div className='rows'>
                                                            <div className='blks'>
                                                                  <div className='h_content'>
                                                                        <h4>TBD</h4>
                                                                  </div>
                                                            </div>
                                                            <div className='blks'>
                                                                  <div className='b_content'>
                                                                        <h5>Badazz Bears 2.0 Announcement</h5>
                                                                        <p>Badazz Bears 2.0 will be officially announced with details on how the community can help build drip for the future of the Bears. Custom community traits and accessories will be developed for the Bears. Some community favorite traits will be retired from circulation to give more value to OG traits from the first 50% of the mints. Not to mention the announcement of how collectors will now be able to pick out traits and customize the look of their Bears.</p>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      <div className='rows'>
                                                            <div className='blks'>
                                                                  <div className='h_content'>
                                                                        <h4>TBD</h4>
                                                                  </div>
                                                                  <div className='h_img'>
                                                                        <img src={RD3} alt="" />
                                                                  </div>
                                                            </div>
                                                            <div className='blks'>
                                                                  <div className='b_content'>
                                                                        <h5>Badazz Bears 2.0 Release</h5>
                                                                        <p>The release of the community created drip for Badazz Bears. These custom traits and accessories chosen by our Badazz community will be available to equip, sell, and trade as this community sets the trends for the Metaverse and beyond.</p>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      <div className='rows'>
                                                            <div className='blks'>
                                                                  <div className='h_content'>
                                                                        <h4>TBD</h4>
                                                                  </div>
                                                            </div>
                                                            <div className='blks'>
                                                                  <div className='b_content'>
                                                                        <h5>The Future and Beyond</h5>
                                                                        <p>Badazz Bears doesn’t end when we are sold out. We are also planning on continuously supporting the community and our partners through the combined efforts of everyone in the community. We will have Merch Releases, NFT Giveaways, New Trait Drops, and much much more. Let’s Disrupt the Metaverse!</p>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                          </Container>
                                    </div>
                                    <div className="section_four">
                                          <Container>
                                                <h1>Join the community</h1>
                                                <p>Our Goal is to build a positive community where all members can feel welcome and talk about everything from NFTs to Sports</p>
                                                <ul>
                                                      <li>
                                                            <a href="https://twitter.com/badazz_bears?s=21&t=NZEXVmWNZROom1NLfnZLxw" target={'_blank'}>
                                                                  <FaTwitter />
                                                            </a>
                                                      </li>
                                                      <li>
                                                            <a href="https://instagram.com/badazzbears?igshid=YmMyMTA2M2Y=" target={'_blank'}>
                                                                  <FaInstagram />
                                                            </a>
                                                      </li>
                                                      <li>
                                                            <a href="https://discord.io/badazzbears" target={'_blank'}>
                                                                  <SiDiscord />
                                                            </a>
                                                      </li>
                                                </ul>
                                          </Container>
                                    </div>
                                    <div className='section_five'>
                                          <Container>
                                                <div className='head'>
                                                      <h2>The Badazz Behind the Bears</h2>
                                                </div>
                                                <div className='body'>
                                                      <div className='blks'>
                                                            <div className='img'>
                                                                  <img src={BearImg1} alt="" />
                                                            </div>
                                                            <div className='txt'>
                                                                  <h4>Jim Jones</h4>
                                                                  <p>Leader of the Bears & rapping Badazz</p>
                                                            </div>
                                                      </div>
                                                      <div className='blks'>
                                                            <div className='img'>
                                                                  <img src={BearImg2} alt="" />
                                                            </div>
                                                            <div className='txt'>
                                                                  <h4>Darklarcs</h4>
                                                                  <p>Artist behind the Bears & expert on iguanas</p>
                                                            </div>
                                                      </div>
                                                      <div className='blks'>
                                                            <div className='img'>
                                                                  <img src={BearImg3} alt="" />
                                                            </div>
                                                            <div className='txt'>
                                                                  <h4>Rob Richardson</h4>
                                                                  <p>Full-time Disruptor & CEO of Disrupt Art</p>
                                                            </div>
                                                      </div>
                                                      <div className='blks'>
                                                            <div className='img'>
                                                                  <img src={BearImg4} alt="" />
                                                            </div>
                                                            <div className='txt'>
                                                                  <h4>Evan Erpenbeck</h4>
                                                                  <p>Disrupt Art’s Creative Director & hockey addict</p>
                                                            </div>
                                                      </div>
                                                      <div className='blks'>
                                                            <div className='img'>
                                                                  <img src={BearImg5} alt="" />
                                                            </div>
                                                            <div className='txt'>
                                                                  <h4>Jason Martin</h4>
                                                                  <p>Coding Master & CTO of Disrupt Art</p>
                                                            </div>
                                                      </div>
                                                </div>
                                          </Container>
                                    </div>
                              </main>

                        </div>
                  </Fragment>
            )
      }
}



export default Home;