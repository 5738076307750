import React, {useRef} from "react";

function VideoPreview(props) {
  
        const videoRef = useRef(null);
    
   
    const onVideoClickHandler = (e) => {
        e.preventDefault();
        const video = videoRef.current;
        if (video.paused) {
            video.play();
        } else {
            video.pause();
        }
        e.stopPropagation();
    }

        return (

            <video
                muted={props.mute}
                loop
                playsInline
                controls={props.showControls}
                className={props.className}
                width={props.width}
                ref={videoRef}
                onClick={onVideoClickHandler}
                autoPlay={props.play}
                height={props.height}
            >
                <source src={props.source} type={props.mimeType}/>
                Your browser does not support HTML video.
            </video>

        )
        }

export { VideoPreview }