export const getUserAddress = () => {
    let useraddress = ''
    if (window.sessionStorage.getItem("CURRENT_USER"))
      useraddress = JSON.parse(window.sessionStorage.getItem("CURRENT_USER"))["addr"];

   return useraddress
}

export const getMaxMintCount = () => {
   return 1801
}