import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import '../Menu.css'
import { adminActions } from '../../../../store/actions';
import { configPath } from '../../../../config';
import { Modal, Button } from "react-bootstrap";
import { setupExists } from '../../../../Utils/disrupt';
import PubSub from 'pubsub-js';
import { getAverageNftSize, getFlowAccountStorageDetails } from '../../../../Utils';
import Img6 from "../../../../Assets/images/logo192.png";

function BearTransaction(props) {
    const customStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)'
        },
        content: {
            position: 'absolute',
            top: '150px',
            left: '440px',
            right: '240px',
            bottom: '50px',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px'
        }
    };

    const navigate = useNavigate() // page redirection 
    const dispatch = useDispatch() //dispatch action

    //define states
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [submitted, setSubmitted] = useState(false)
    const [slotType, setSlotType] = useState(1)
    const [options, setOptions] = useState([{ slot: "Select", value: 0 }])
    const [slots, setSlots] = useState([]);
    const [openModal, setOpenModal] = useState(false)
    const [availableCount, setAvailableCount] = useState(0)
    const [openSendTransactionPopup, setOpenSendTransactionPopup] = useState(false)
    const [txnError, setTxnError] = useState("Unexpected error has occurred. Please try again later")
    const [isVerificationGoing, setIsVerificationGoing] = useState(false)
    const [errorMes, setErrorMes] = useState('')
    const [openErrorModal, setOpenErrorModal] = useState(false)
    const [confirmationCode, setConfirmationCode] = useState('')
    const [openSuccessModal, setOpenSuccessModal] = useState(false)
    const [successMsg, setSuccessMsg] = useState('')
    const [title,setTitle] = useState('')

    //get states from store
    const validateTransactionSuccess = useSelector(state => state.adminreducer.validateTransaction)
    const validationTransactionFailed = useSelector(state => state.adminreducer.validationFailed);
    const sendBearSuccessfully = useSelector(state => state.adminreducer.sendbearSuccess)
    const sendBearFailed = useSelector(state => state.adminreducer.sendBearFailed)
    const verificationSuccess = useSelector(state => state.adminreducer.transactionVerified)
    const verificationCodeFailed = useSelector(state => state.adminreducer.verificationFailed)

    const getSlots = useSelector(state => state.adminreducer.slots)

    function showLoader() {
        PubSub.publish('msg', true);
    }

    function hideLoader() {
        PubSub.publish('msg', false);
    }
    //componentDidMount()
    useEffect(() => {
        setOptions(options)

        let obj = {}
        dispatch(adminActions.getSlots(obj, (configPath.pfpId)))
    }, [])

    useEffect(() => {
        //oned nfts response
        if (validateTransactionSuccess && validateTransactionSuccess.statusCode === 200) {
            dispatch(adminActions.resetValidationSuccess())
            if (validateTransactionSuccess &&
                validateTransactionSuccess.data &&
                validateTransactionSuccess.data.count === 0 &&
                validateTransactionSuccess.data.arts &&
                validateTransactionSuccess.data.arts.length === 0) {
                hideLoader()
                setOpenSendTransactionPopup(true)
            } else {
                if (validateTransactionSuccess && validateTransactionSuccess.data && validateTransactionSuccess.data.count) {
                    setAvailableCount(validateTransactionSuccess.data.count)
                    let obj = {
                        email,
                        walletAddress: address,
                    }
                    dispatch(adminActions.verifyBearTransferCode((JSON.stringify(obj)), (configPath.pfpId), slotType))

                } else {
                    hideLoader()
                    return false;
                }
            }
        }
    }, [validateTransactionSuccess])
    useEffect(() => {
        if (validationTransactionFailed && validationTransactionFailed.length > 0) {
            hideLoader()
            dispatch(adminActions.resetValidationFailed())
            setErrorMes(validationTransactionFailed)
            setOpenErrorModal(true)
        }
    }, [validationTransactionFailed])
    useEffect(() => {
        if (sendBearSuccessfully && sendBearSuccessfully.statusCode === 200) {
            dispatch(adminActions.resetSendBearSuccess())
            if(sendBearSuccessfully && sendBearSuccessfully.data && sendBearSuccessfully.data.transaction && sendBearSuccessfully.data.transaction.transactionId){
                setSuccessMsg(sendBearSuccessfully.data.transaction.transactionId)
            }
            setOpenSuccessModal(true)
        }
    }, [sendBearSuccessfully])
    useEffect(() => {
        if (sendBearFailed && sendBearFailed.length > 0) {
            dispatch(adminActions.resetSendBearFailed())
            if (sendBearFailed && sendBearFailed.toLowerCase().includes('confirmation code')) {
                if(sendBearFailed && sendBearFailed.toLowerCase().includes('expired')){
                    setErrorMes(`Transaction failed : Time out !! Please try again`)
                    setOpenErrorModal(true)
                }else{
                    setErrorMes("Transaction failed : Invalid transaction")
                    setOpenErrorModal(true)
                }
            }else{
                setErrorMes(sendBearFailed)
                setOpenErrorModal(true)
            }
        }
    }, [sendBearFailed])
    useEffect(() => {
        if (verificationSuccess && verificationSuccess.statusCode === 200) {
            dispatch(adminActions.resetBearVerification())
            hideLoader()
            if (verificationSuccess &&
                verificationSuccess.data &&
                verificationSuccess.data.confirmationCode
            ) {
                setOpenModal(true)
                setConfirmationCode(verificationSuccess.data.confirmationCode)
            } else {
                setErrorMes("Something went wrong, please try again later")
                setOpenErrorModal(true)
            }
        }
    }, [verificationSuccess])
    useEffect(() => {
        if (verificationCodeFailed && verificationCodeFailed.length > 0) {
            dispatch(adminActions.resetBearVerificationFailed())
            hideLoader()
            setErrorMes(verificationCodeFailed)
            setOpenErrorModal(true)
        }
    }, [verificationCodeFailed])
    const checkemail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailReg.test(value)) {
            return true
        } else {
            return false
        }
    }
    const onEmailChange = (e) => {
        if(e.target.value){
            setEmail(e.target.value.toLowerCase())
        }else{
            setEmail(e.target.value)
        }
    }
    const onAddressChange = (e) => {
        setAddress(e.target.value)
    }
    const handleSlotDropDown = (e) => {
        setSlotType(e.target.value)

    }
    const isValidAddress = (value) => {
        const regex = /^0x[0-9a-f]{3,50}$/
        if(value && regex.test(value)){
            return true
        }else{
            return false
        }
    }
    useEffect(() => {
        let array = []
        if (getSlots && getSlots.statusCode === 200) {
            dispatch(adminActions.resetGetSlot())
            if (getSlots && getSlots.data && getSlots.data.slots) {
                setSlots(getSlots.data.slots)
                getSlots.data.slots && getSlots.data.slots.map(item => {
                    let obj = {
                        slot: item && item.name,
                        value: item && item.id
                    }
                    array.push(obj)
                })
                setOptions(array)
            }
        }
    }, [getSlots])
    const slotDropdown = options.map((filType) => {
        return <option value={filType.value} key={filType.value}>{filType.slot}</option>
    });
    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        if (email) {
            if (checkemail(email)) {
                if (address && address != '') {
                    // if(isValidAddress(address)){
                        checkSetup()
                    // }else{
                    //     toast.error("Please enter valid wallet address")
                    // }
                    
                }
            } else {
                toast.error("Please enter proper email address")
            }
        }

    }
    const closeModal = () => {
        setOpenModal(false)
    }
    const reject = () => {
        setOpenModal(false)
    }
    const approve = () => {
        setOpenModal(false)
        let obj = {
            email,
            walletAddress: address,
            confirmationCode: confirmationCode,
        }
        dispatch(adminActions.sendBearToAddress((JSON.stringify(obj)), (configPath.pfpId), (slotType)))
    }
    const approveTransaction = () => {
        setOpenSendTransactionPopup(false)
        let obj = {
            email,
            walletAddress: address,
        }
        dispatch(adminActions.sendBearToAddress((JSON.stringify(obj)), (configPath.pfpId), (slotType)))

    }
    const rejectTransaction = () => {
        setOpenSendTransactionPopup(false)
    }
    const checkSetup = async () => {
        if (address && address !== null && address !== undefined && address != '') {
            showLoader()
            try {
                const checkingSetup = await setupExists(address)
                if (checkingSetup && checkingSetup.status === 200) {
                    if (checkingSetup && checkingSetup.data && checkingSetup.data.Value === "0") {
                        hideLoader()
                        //if not setup, create account setup
                        setErrorMes(`Disrupt setup not found for this wallet address. Please ask the user to sign in/sign up and connect wallet on Disrupt.art.`)
                        setOpenErrorModal(true)
                    } else {
                        const storageSpace = await getFlowAccountStorageDetails(address)
                        const requiredSpace = await getAverageNftSize()
                        if (storageSpace && storageSpace.status === 200) {
                            if (storageSpace.data && storageSpace.data.Value && storageSpace.data.Value.storageCapacity && storageSpace.data.Value.storageUsed) {
                                const storageCapacity = storageSpace.data.Value.storageCapacity
                                const storageUsed = storageSpace.data.Value.storageUsed
                                const availableSpace = Number(storageCapacity) - Number(storageUsed)
                                if (availableSpace >= requiredSpace) {
                                    let obj = {
                                        email,
                                        walletAddress: address,
                                    }
                                    dispatch(adminActions.validateBearTransaction(obj, (configPath.pfpId), slotType))
                                } else {
                                    hideLoader()
                                    setErrorMes(`Insufficient storage. Please ask to increase flow balance to increase storage space`)
                                    setOpenErrorModal(true)
                                }
                            }
                            else {
                                hideLoader()
                                setErrorMes("Oops! Something went wrong, Please try again later")
                                setOpenErrorModal(true)
                            }

                        } else {
                            hideLoader()
                            if (storageSpace && storageSpace.status === 400) {
                                if (storageSpace && storageSpace.data && storageSpace.data.Error) {
                                    setErrorMes(getError(storageSpace))
                                    setOpenErrorModal(true)
                                } else {
                                    setErrorMes(txnError)
                                    setOpenErrorModal(true)
                                }
                            } else {
                                setErrorMes(txnError)
                                setOpenErrorModal(true)
                            }
                        }

                    }
                } else {
                    hideLoader()
                    if ((checkingSetup && checkingSetup.status === 400)) {
                        if ((checkingSetup && checkingSetup.data && checkingSetup.data.message)) {
                            setErrorMes(getError(checkingSetup))
                            setOpenErrorModal(true)
                        } else {
                            setErrorMes(txnError)
                            setOpenErrorModal(true)
                        }
                    } else {
                        setErrorMes(txnError)
                        setOpenErrorModal(true)
                    }
                }
            } catch (e) {
                hideLoader()
                setErrorMes(`Unexpected error occured : ${e}`)
                setOpenErrorModal(true)
            }

        } else {
            hideLoader()
            toast.error("Please provide proper wallet address")
        }
    }
    const getError = (txnResponse) => {
        const error = txnResponse.data.Error
        let mes = 'Transaction failed : ';
        const splitError = error.split(':');
        if (
            splitError[2] &&
            splitError[2].includes("Session expired")
        ) {
            mes += "Session Expired.Please reconnect your wallet account";
        } else if (splitError[1]) {
            mes += splitError[1]
        } else {
            mes += splitError[0];
        }
        console.log(splitError[2], splitError[1], splitError[0])
        return (mes)
    }
    const closeErrorModal = () => {
        setOpenErrorModal(false)
    }
    const closeSuccessModal = () => {
        setOpenSuccessModal(false)
        setEmail('')
        setAddress('')
        setSubmitted(false)
        setConfirmationCode('')
        setOpenErrorModal(false)
        setErrorMes('')
        setSlotType(1)
        setOpenModal(false)
        setAvailableCount(0)
        setSuccessMsg('')
    }
    const handleCopy = (value) => {
        const el = document.createElement('textarea');
        el.value = value;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setTitle('Copied')
        
        setTimeout(() => {
            setTitle("Copy")
        }, 1000);
    }
    return (
        <Fragment>

            {isVerificationGoing &&
                <div className="show-content">
                    <div className="child-show purchasebox">
                        <p className="text-white">Processing</p>
                        <div className='loader'></div>

                    </div>
                </div>
            }
            <Modal show={openSendTransactionPopup} onHide={(e) => rejectTransaction(e, this)} className="bab-popup" aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Body>
                    <div className="modal-body d-flex flex-column align-items-center">
                        <div className="form-group my-5 text-center">
                            <div className="loginpopcls ca-popu-body">
                                <div className="custom-ui text-left p-0 ">
                                    <div className=''>
                                        <h5 className="text-center mb-4">Proceed to transfer bear NFT?</h5>

                                        <div className="custom-btn text-center">
                                            <button onClick={rejectTransaction} className="btn btn-outline-primary px-3 mr-3">No</button>
                                            <button onClick={approveTransaction} className="btn btn-primary px-3">Yes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={openErrorModal} onHide={(e) => closeErrorModal(e, this)} className="bab-popup" aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Body>
                    <div className="modal-body d-flex flex-column align-items-center">
                        <div className="form-group my-4 text-center">
                            <h4>{errorMes}</h4>
                        </div>
                        <Button variant="outline-primary" onClick={closeErrorModal}>
                            Close
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={openModal} onHide={(e) => closeModal(e, this)} size="lg" className="bab-popup" aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Body>
                    <div className="modal-body d-flex flex-column align-items-center">
                        <div className="form-group text-center">
                            <div className="loginpopcls ca-popu-body">
                                <div className="custom-ui text-left p-0 ">
                                    <div className="form-group mt-2  mb-4 text-center">
                                        <h4 htmlFor="scode ">Either wallet address ({address}) or email ({email}) already have <b className='art-count-num'>{availableCount}</b> Bear NFT associated with it.</h4>
                                    </div>
                                    <div className=''>
                                        <h5 className="text-center mb-4">Proceed to transfer bear NFT?</h5>

                                        <div className="custom-btn text-center">
                                            <button onClick={reject} className="btn btn-outline-primary px-3 mr-3">No</button>
                                            <button onClick={approve} className="btn btn-primary px-3">Yes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={openSuccessModal} size="lg" onHide={(e) => closeSuccessModal(e, this)} className="bab-popup babmsgpopu" aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Body>
                <div className="modal-body d-flex flex-column align-items-center">
                <div className="form-group my-2 text-center">
                {/* <img src={Img6} alt="" class="mw-100 mb-2 noimgfound" /> */}
                        <div class="success-checkmark">
                            <div class="check-icon">
                                <span class="icon-line line-tip"></span>
                                <span class="icon-line line-long"></span>
                                <div class="icon-circle"></div>
                                <div class="icon-fix"></div>
                            </div>
                            </div>
                            <h4 className='mt-2 mb-4'>Woohoo! Successfully transferred art to wallet address ({address}) <br/> Transaction id <b style={{fontSize:"17px"}}>{successMsg}</b> <br/> <i style={{fontSize:"14px"}}>Note transaction id for your future reference.</i></h4>
                        </div>
                        <Button variant="outline-primary" onClick={closeSuccessModal}>
                            Close
                        </Button>
                    </div>
                    
                </Modal.Body>
            </Modal>

            <div className="smallilac px-3">
                <div className='ca-card mb-3 '>
                    <div className="row admintitle align-items-center">
                        <div className="col-sm-12 mb-3 ">
                            <h2 className="font-weight-bold">Transfer Bears
                            </h2>
                            <i><i className="fa fa-info-circle" aria-hidden="true"></i>Transfer random bears to the recipients from the available bears.</i>

                        </div>
                        <div className="col-sm-8 text-right">

                        </div>

                    </div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className='ca-card mb-3 '>
                       
                        <div className="row justify-content-center">

                            <div className='col-md-4'>
                            <div className="form-group ">
                                    <label htmlFor="slot " className="font-weight-bold" >&nbsp;Choose slot*</label>
                                    <select className="form-control" value={slotType} onChange={handleSlotDropDown}>
                                    {slotDropdown}
                                </select>
                                </div>
                                <div className="form-group ">
                                    <label htmlFor="email " className="font-weight-bold" >Email Address&nbsp;*</label>
                                    <input type="email" className={"form-control"} id="email" aria-describedby="email" placeholder="Enter email address" value={email} onChange={onEmailChange} />
                                    {submitted && !email && <div className="mandatory small">{"Email required"}</div>}
                                    {submitted && email && !checkemail(email) && <div className="mandatory">Please enter valid email</div>}

                                </div>

                                <div className="form-group ">
                                    <label htmlFor="address" className="font-weight-bold" >Wallet Address&nbsp;*</label>
                                    <input type="address" className={"form-control"} id="address" aria-describedby="address" placeholder="Enter wallet address" value={address} onChange={onAddressChange} />
                                    {submitted && !address && <div className="mandatory small">{"Wallet address required"}</div>}
                                </div>
                                <div className="form-group text-center mt-5">
                                    <button type="submit" className="btn btn-primary px-5">Transfer</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>

            </div>

        </Fragment>
    )

}

export { BearTransaction }