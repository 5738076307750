import { configPath } from "../config"
import jwtDecode from "jwt-decode";
import { refreshToken } from "../helpers/authorizedData";
import { adminActions } from "../store/actions";
import { adminServices } from "./admin.services";
import PubSub from 'pubsub-js';

export const fetchService = {
    fetchResource,
    getBlob
}

/**
 * @type {Promise<{
 * res: Response,
 * resBody: Promise<any>
 * }>}
 */
let isRefreshInProgress = null;

/**
 * Fetches the requested resource from the network.
 * 
 * This function is a modified form of the `window.fetch` function
 * with the additional functionality of refreshing the user's
 * _access JWT_ if & when it is expired before fetching the resource requested.
 * @param {RequestInfo} resourceURL The URL of the resource to be fetched.
 * @param {RequestInit} options The options to be applied to the request
 * @returns A promise containing the response for the requested resource.
 */
async function fetchResource(resourceURL, options) {

    // Check if JWT is present and is upto expire
    if (options && options.headers['Authorization']) {

        const bearerToken = options.headers['Authorization'].split(' ');
        const jwt = bearerToken[1];

        /**
         * @constant
         * @type {any}
         */
        const decodedJWT = jwtDecode(jwt);
        const now = Date.now();

        // Set the expire time 5 seconds  before the 
        // JWT expire time.
        // [Note] JWT's expire time is represented in
        // UNIX epoch time in seconds. hence, we
        // must convert it to milliseconds before
        // processing.
        const expTime = (decodedJWT.exp * 1000) - 5000;
        if (
            now >= expTime
        ) {
            // Check if refresh JWT has expired. if so we 
            // logout the user
            /**
            * @constant
            * @type {any}
            */
            // const decodedRefreshJWT = jwtDecode(
            //     JSON.stringify(refreshToken())
            // );

            // if (
            //     now >= (decodedRefreshJWT.exp * 1000)
            // ) {
            //     userAction.clearWebLoginInfo();
            //     console.log('%c Refresh Token expired. user logged out ', 'background:#0275d8; color: #fff');

            //     return new Promise((resolve, reject) => reject("Token expired,rejected"))
            // }

            // console.log('%c JWT expired ', 'background:#0275d8; color: #fff');

            // When the JWT is expired, we initiate the 
            // JWT refresh process.
            // 1. Check if JWT refresh is already in progress.
            // 2. If not we create a promise `isRefreshInProgress` that resolves
            //    when the refresh JWT request completes.
            // 3. If JWT refresh is already in progress then 
            //    wait till the promise `isRefreshInProgress` is resolved.
            // 4. Finally we update the new access token.

            if (!isRefreshInProgress) {
                isRefreshInProgress = new Promise(
                    resolve => refreshJWT()
                        .then(
                            response => resolve(
                                {
                                    res: response.clone(),
                                    resBody: response.clone().json()
                                }
                            )
                        )
                        .catch(err => {
                            try {
                                PubSub.publish('msg',false)
                                if (err instanceof TypeError) {
                                    throw err
                                }else{
                                    Promise.reject(err)
                                }
                            }
                            catch(err) {
                                PubSub.publish('msg',false)
                            console.log(err)
                          
                            }
                        })
                );
            }
            const refreshResponse = await isRefreshInProgress;

            // Set isRefreshInProgress to null after
            // the JWT refresh is complete.
            isRefreshInProgress = null;
            if (refreshResponse.res.status === 200) {

                // When the JWT refresh action is completed
                // successfully, we update the latest accessJWT
                // in local storage. Then we retry the previous request

                const refreshResponseBody = await refreshResponse.resBody;
                const updatedAccessJWt = updateAccessJWT(refreshResponseBody);
                if (
                    updatedAccessJWt
                ) {
                    options.headers['Authorization'] = 'Bearer ' + updatedAccessJWt;
                    // console.log('%c Access JWT updated ', 'background:#0275d8; color: #fff');
                    // console.log(webLoginDetails);
                }
            } else {

                if (
                    refreshResponse.res.status === 403 ||
                    refreshResponse.res.status === 401
                ) {
                    console.log(refreshResponse)

                    localStorage.removeItem('bab_adminDetail')
                    // console.log('%c Refresh Token invalidated. user logged out ', 'background:#0275d8; color: #fff');
                }
                return new Promise((resolve, reject) => resolve(refreshResponse));
            }
        }
        // console.log('%c Access JWT updated ', 'background:#0275d8; color: #fff');
    }

    // Fetch the response
    return fetch(resourceURL, options);
}

/**
 * Get the blob from the given URL
 * @param {string} url URL of asset to get blob
 */
async function getBlob(url) {
    const response = await fetch(url, { method: 'GET' });
    const blob = await response.blob();
    return blob;
}

function refreshJWT() {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(refreshToken())
    };
    return fetch(`${configPath.apiUrl}/admin-auth/refresh`, requestOptions)
}

function updateAccessJWT(refreshResponseBody) {
    const webLoginDetails = JSON.parse(localStorage.getItem('bab_adminDetail'));
    if (
        webLoginDetails &&
        webLoginDetails.data &&
        webLoginDetails.data.accessToken &&
        refreshResponseBody &&
        refreshResponseBody.data &&
        refreshResponseBody.data.accessToken
    ) {

        // Update the new access JWT in the local storage
        webLoginDetails.data.accessToken = refreshResponseBody.data.accessToken;
        const userDetail = JSON.stringify(webLoginDetails);
        localStorage.setItem("bab_adminDetail", userDetail);
        return webLoginDetails.data.accessToken;
    }
    return null;
}
