import { configureStore } from '@reduxjs/toolkit'
import {adminreducer} from './reducers/admin.reducer'
import {babreducer} from './reducers/bab.reducer'
import thunkMiddleware from 'redux-thunk'
import {createLogger} from 'redux-logger'
const loggerMiddleware = createLogger()

export const store = configureStore({
    reducer:{
    adminreducer,
    babreducer
},middleware : [thunkMiddleware,loggerMiddleware]})