import React, { Component, Fragment } from 'react'
import history from "../helpers/history";
import Img1  from "../Assets/images/sec1_img1.png";
import Img2  from "../Assets/images/sec1_img2.png";
import Img3  from "../Assets/images/sec1_img3.png";
import Img4  from "../Assets/images/sec1_img4.png";
import Img5  from "../Assets/images/sec1_img5.png";
import Img6  from "../Assets/images/sec1_img6.png";
import blkImg1 from "../Assets/images/blk_img1.png";
class Collection extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Fragment>

                <div className="collection orangebg">
                    <div className="container-fluid">
                        <div className="bb-page-title">
                            <h1>Bears Collection Preview</h1>
                        </div>
                        <div className='bb-lists'>
                        <div className="row">
                            <div className="col-md-3">
                                <div className='bb-list'>
                                    <div className='bb-list-top'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <div className="bb-list-img">
                                        <img src={Img3}/>
                                    </div>
                                    {/* <div className="bb-list-detail">
                                        <div className="row">
                                            <div className="col-3" >
                                                <p>#1805 <span>Mint</span></p>

                                            </div>
                                            <div className="col-3">
                                                <p>
                                                    Rare <span>Rarity</span>
                                                </p>
                                            </div>
                                            <div className="col-3">
                                                <p>22
                                                    <span> Rarity scrore</span></p>
                                            </div>
                                            <div className="col-3">
                                                <p>19.99 <span >Sale price</span></p>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className='bb-list'>
                                    <div className='bb-list-top'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <div className="bb-list-img">
                                        <img src={Img4}/>
                                    </div>
                                    {/* <div className="bb-list-detail">
                                        <div className="row">
                                            <div className="col-3" >
                                                <p>#1805 <span>Mint</span></p>

                                            </div>
                                            <div className="col-3">
                                                <p>
                                                    Rare <span>Rarity</span>
                                                </p>
                                            </div>
                                            <div className="col-3">
                                                <p>22
                                                    <span> Rarity scrore</span></p>
                                            </div>
                                            <div className="col-3">
                                                 <p className='bb-list-notsale'>Not for sale</p>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className='bb-list'>
                                    <div className='bb-list-top'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <div className="bb-list-img">
                                        <img src={Img5}/>
                                    </div>
                                    {/* <div className="bb-list-detail">
                                        <div className="row">
                                            <div className="col-3" >
                                                <p>#1805 <span>Mint</span></p>

                                            </div>
                                            <div className="col-3">
                                                <p>
                                                    Rare <span>Rarity</span>
                                                </p>
                                            </div>
                                            <div className="col-3">
                                                <p>22
                                                    <span> Rarity scrore</span></p>
                                            </div>
                                            <div className="col-3">
                                                <p>19.99 <span >Sale price</span></p>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className='bb-list'>
                                    <div className='bb-list-top'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <div className="bb-list-img">
                                        <img src={Img6}/>
                                        <div className="bb-list-cart">
                                            <i class="fas fa-shopping-cart"></i>
                                        </div>
                                    </div>
                                    {/* <div className="bb-list-detail">
                                        <div className="row">
                                            <div className="col-3" >
                                                <p>#1805 <span>Mint</span></p>

                                            </div>
                                            <div className="col-3">
                                                <p>
                                                    Rare <span>Rarity</span>
                                                </p>
                                            </div>
                                            <div className="col-3">
                                                <p>22
                                                    <span> Rarity scrore</span></p>
                                            </div>
                                            <div className="col-3">
                                                 <p className='bb-list-notsale'>Not for sale</p>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}


export default Collection;