import React, { Fragment, useState } from "react";
import { purchaseTokenss } from "./../Utils";
import { Modal, Button } from "react-bootstrap";


function PurchaseCheck() {


    const [purRes, setPurRes] = useState('')
    const [openErrorModal, setOpenErrorModal] = useState(false)

    const raiseModalWithRes = (res) => {
        if ((res && res.status) === 200) {
            console.log("Response---------", res)
            if ((res && res.data && res.data.transaction && res.data.transaction.errorMessage)) {
                setPurRes(JSON.stringify(res))
                setOpenErrorModal(true)
            } else {
                setOpenErrorModal(true)
                setPurRes(JSON.stringify(res))
            }
        } else {
            setPurRes(JSON.stringify(res))
            setOpenErrorModal(true)
        }
    }
    const handlePurchase1 = async () => {
        const res = await purchaseTokenss(322651682, "0x0feb0de15fff8a89", "150.0")
        raiseModalWithRes(res)
    }

    const handlePurchase2 = async () => {
        const res = await purchaseTokenss(322659463, "0x0feb0de15fff8a89", "150.0")
        raiseModalWithRes(res)
    }

    const handlePurchase3 = async () => {
        const res = await purchaseTokenss(322663523, "0x0feb0de15fff8a89", "150.0")
        raiseModalWithRes(res)
    }

    const closeErrorModal = () => {
        setOpenErrorModal(false)
    }

    return (
        <Fragment>
            <Modal show={openErrorModal} onHide={(e) => closeErrorModal(e, this)} size="lg" className="bab-popup" aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Body>
                    <div className="modal-body d-flex flex-column align-items-center">
                        <div className="form-group my-5 text-center">
                            <h4>{purRes}</h4>
                        </div>
                        <Button variant="outline-primary" onClick={closeErrorModal}>
                            Close
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="collection orangebg">
                <div className="container-fluid">
                    <div class="bab-speakers-list">
                        <div class="row">
                            <div class="col-sm-6 col-md-3">
                                <div class="bab-speaker">
                                    <div class="bab-propic"><img src={"/assets/img/David-Bianchi.png"} alt="" class="mw-100" /></div>
                                    <div class="bab-prodetail"><button className="btn btn-outline-primary" type="button" onClick={handlePurchase1}>Purchase</button></div>

                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3">
                                <div class="bab-speaker">
                                    <div class="bab-propic"><img src={"/assets/img/David-Bianchi.png"} alt="" class="mw-100" /></div>
                                    <div class="bab-prodetail"><button className="btn btn-outline-primary" type="button" onClick={handlePurchase2}>Purchase</button></div>

                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3">
                                <div class="bab-speaker">
                                    <div class="bab-propic"><img src={"/assets/img/David-Bianchi.png"} alt="" class="mw-100" /></div>
                                    <div class="bab-prodetail"><button className="btn btn-outline-primary" type="button" onClick={handlePurchase3}>Purchase</button></div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );
}
export { PurchaseCheck }
