import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { adminActions } from '../../../store/actions';


function WaitlistEmail() {

    const dispatch = useDispatch() //dispatch action
    const emailAdded = useSelector(state => state.adminreducer.addedEmail)
   const emailAddFailed = useSelector(state =>state.adminreducer.addEmailFailed)
    const waitlistCount = useSelector(state => state.adminreducer.emailCount)

    const [email, setEmail] = useState('')
    const [submitted,setSubmitted] = useState(false)
    const [count,setCount] = useState(0)

    useEffect(() => {
        dispatch(adminActions.getWaitlistCount())
    },[])
    useEffect(() => {
        if((waitlistCount && waitlistCount.statusCode) === 200){
            dispatch(adminActions.resetemailCount())
            if(waitlistCount && waitlistCount.data && waitlistCount.data.count){
                setCount(waitlistCount.data.count)
            }
        }
    },[waitlistCount])
    useEffect(() => {
        if((emailAdded && emailAdded.statusCode) === 201){
            dispatch(adminActions.resetAddEmailWaitlist())
            toast.success(emailAdded && emailAdded.message)
            setEmail('')
            setSubmitted(false)
            dispatch(adminActions.getWaitlistCount())

        }
    }, [emailAdded])
    useEffect(() => {
        if(emailAddFailed && emailAddFailed.length > 0){
            dispatch(adminActions.resetAddEmailFailed())
            toast.error(emailAddFailed)
        }
    },[emailAddFailed])
    const handleEmail = (e) => {
        setEmail(e.target.value.toLowerCase())

    }
    const checkemail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailReg.test(value)) {
            return true
        } else {
            return false
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true)
        if (email && checkemail(email)) {
            let obj = {
                email : email
            }
            dispatch(adminActions.addEmailtoWaitlist(JSON.stringify(obj)))
        }
    }
    return (
        <Fragment>
            <div className="smallilac px-3">
                
                <div className='ca-card mb-3 '>
                    <div className="row admintitle align-items-center">
                        <div className="col-md-4"><h2 className="font-weight-bold">Waitlist Email ( {count} ) </h2></div>
                        
                    </div>
                </div>
                <div className="ca-card mb-3">
                    <div className="card-body">

                    <form onSubmit={handleSubmit}>
                    <div class="row justify-content-center">
                        <div class="col-md-4 text-center">
                            <label className="font-weight-bold" htmlFor="email">Email</label>
                            <input className="form-control" id="email" type="email" value={email} onChange={handleEmail} placeholder="Enter email" />
                            {submitted && !email && <div className="mandatory">Email required</div>}
                            {submitted && email && !checkemail(email) && <div className="mandatory">Please enter valid email</div>}
                            
                            <div>
                                <button type="submit" className="btn btn-primary mt-3" >Submit</button>
                            </div>
                            
                        </div>
                    </div>
                    </form>
                    </div>
                </div>


            </div>

        </Fragment>
    )

}


export { WaitlistEmail }