import React, { Component, Fragment } from 'react'
import Img1 from "../Assets/images/sec1_img1.png";
import Img2 from "../Assets/images/sec1_img2.png";
class WaitingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            discord: '',
            email: '',
            telegram: ''
        }
    }
    handlenameChange = (e) => {
        this.setState({ name: e.target.value })
    }
    handleDiscordChange = (e) => {
        this.setState({ discord: e.target.value })
    }
    handleEmailChange = (e) => {
        this.setState({ email: e.target.value })
    }
    handleTeleChange = (e) => {
        this.setState({ telegram: e.target.value })
    }
    handleSubmit = (e) => {
        e.preventDefault();

    }
    render() {
        return (
            <Fragment>

                <div className="waitinglist orangebg">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-md-4">
                                <div className='waitinglist-cont'>
                                    <p className="waitinglist-p mb-3">Presale Waiting List</p>
                                    <h1 className="waitinglist-h mb-3">Bear Cave</h1>
                                    <p className="mb-4">By joining the Bear Cave (Presale List) you will have an opportunity to mint a Bear before anyone else on June 20, 2022. 1,801 mints will be available in the first batch. Some will be reserved for Giveaways, VIPs, and Community Events.
</p>
                                    <div id="mc_embed_signup">
                                        <form action="https://disruptionnow.us14.list-manage.com/subscribe/post?u=ae99ac9a4fafd8609771595ff&amp;id=fe021b9892" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
                                            <div id="mc_embed_signup_scroll" className='row'>
                                                {/* <h2>Subscribe</h2> */}
                                                {/* <div className="indicates-required col-sm-12"><span className="asterisk">*</span> indicates required</div> */}
                                                <div className="mc-field-group form-group mb-4 col-sm-6">
                                                    {/* <label htmlFor="mce-EMAIL">Email <span className="asterisk">*</span>
                                                    </label> */}
                                                    <input type="email" name="EMAIL" className="required email form-control" id="mce-EMAIL" placeholder='Email' required/>
                                                </div>
                                                <div className="mc-field-group form-group mb-4 col-sm-6">
                                                    {/* <label htmlFor="mce-FNAME">Name </label> */}
                                                    <input type="text"  name="FNAME" className="form-control" id="mce-FNAME"  placeholder='Name' required/>
                                                </div>
                                                <div className="mc-field-group form-group mb-4 col-sm-6">
                                                    {/* <label htmlFor="mce-TELEGRAM">Telegram </label> */}
                                                    <input type="text"  name="TWITTER" className="form-control" id="mce-TWITTER"  placeholder='Twitter'/>
                                                </div>
                                                <div className="mc-field-group form-group mb-4 col-sm-6">
                                                    {/* <label htmlFor="mce-DISCORD">Discord </label> */}
                                                    <input type="text"  name="DISCORD" className="form-control" id="mce-DISCORD"  placeholder='Discord'/>
                                                </div>
                                                <div id="mce-responses" className="clear">
                                                    <div className="response" id="mce-error-response" style={{display:"none"}}></div>
                                                    <div className="response" id="mce-success-response" style={{display:'none'}}></div>
                                                </div>
                                                
                                                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                                                    <input type="text" name="b_ae99ac9a4fafd8609771595ff_fe021b9892" tabIndex="-1"  />
                                                </div>
                                                <div className="form-group col-sm-12">
                                                    <input type="submit" value="Join Waitlist" name="subscribe" id="mc-embedded-subscribe" className="btn btn-primary btn-block" />
                                                </div>
                                            </div>
                                        </form>                                       
                                    </div>
                                  
                                    {/* <div className="row">
                                        <div className="form-group mb-4 col-sm-6">
                                            <input type="text" className="form-control" id="fullName" aria-describedby="fullName" placeholder="Name" value={this.state.name} onChange={this.handlenameChange} />
                                        </div>
                                        <div className="form-group mb-4 col-sm-6">
                                            <input type="text" className="form-control" id="discord" aria-describedby="discord" placeholder="Discord" value={this.state.discord} onChange={this.handleDiscordChange} />
                                        </div>
                                        <div className="form-group mb-4 col-sm-6">
                                            <input type="email" className="form-control" id="email" aria-describedby="email" placeholder="Email" value={this.state.email} onChange={this.handleEmailChange} />
                                        </div>
                                        <div className="form-group mb-4 col-sm-6">
                                            <input type="text" className="form-control" id="telegram" aria-describedby="telegram" placeholder="Telegram" value={this.state.telegram} onChange={this.handleTeleChange} />
                                        </div>

                                        <div className="col-md-12 text-center ">
                                            <button type="button" className="btn btn-primary btn-block" onClick={this.handleSubmit}>Join the Bear Cave</button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='bb-list rotateimg340'>
                                    <div className='bb-list-top'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <div className="bb-list-img">
                                        <img src={Img2} />
                                    </div>
                                    <div className="bb-list-detail">
                                        <div className="row">
                                            <div className="col-3" >
                                                <p>#2615 <span>Mint</span></p>

                                            </div>
                                            <div className="col-3">
                                                <p>
                                                    Rare <span>Rarity</span>
                                                </p>
                                            </div>
                                            <div className="col-3">
                                                <p>55
                                                    <span> Rarity scrore</span></p>
                                            </div>
                                            <div className="col-3">
                                                <p>35.99 <span >Sale price</span></p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='bb-list rotateimg340'>
                                    <div className='bb-list-top'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <div className="bb-list-img">
                                        <img src={Img1} />
                                    </div>
                                    <div className="bb-list-detail">
                                        <div className="row">
                                            <div className="col-3" >
                                                <p>#1805 <span>Mint</span></p>

                                            </div>
                                            <div className="col-3">
                                                <p>
                                                    Rare <span>Rarity</span>
                                                </p>
                                            </div>
                                            <div className="col-3">
                                                <p>22
                                                    <span> Rarity scrore</span></p>
                                            </div>
                                            <div className="col-3">
                                                <p>19.99 <span >Sale price</span></p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </Fragment>
        )
    }
}


export default WaitingList;