import React, { Component, Fragment, useEffect, useState } from 'react'
import Modal from 'react-modal'

function ProcessBlock () {
    const customStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)'
          },
          content: {
            position: 'absolute',
            top: '150px',
            left: '440px',
            right: '240px',
            bottom: '50px',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px'
          }
    };
            const [srange,setSrange] = useState('')
            const [erange,setErange] = useState ('')
            const [submitted,setSubmitted] = useState (false)
            const [scode,setCode] = useState ('')
            const [openModal,setOpenModal] = useState (false)
            const [isValid,setIsValid] = useState (false)
     
    useEffect(() => {
        const realCode = window.btoa(process.env.REACT_APP_VALIDATION_CODE)
        if ((scode) == (window.atob(realCode))) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    },[scode])
    const handleChange = (e) => {
        if (((e.target.value) >= 0) && ((e.target.value) % 1 === 0)) {
            setSrange(e.target.value)
        } else {
            setSrange('')

        }
    }
    const handleEndChange = (e) => {
        if (((e.target.value) >= 0) && ((e.target.value) % 1 === 0)) {
            setErange(e.target.value)
        } else {
            setErange(''
            )
        }
    }
    const handlesecretCode = (e) => {
        setCode(e.target.value)
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        if (srange) {
            if (erange) {
                if (Number(erange) > Number(srange)) {

                   setOpenModal(true)

                } else {
                }
            } else {

            }
        } else {

        }

    }
    const handleOk = (obj) => {
        setOpenModal(false)
        setCode('')
        setIsValid(false)
        setSrange('')
        setErange('')
        setSubmitted(false)

    }
   
    const closeModal = () => {
        setOpenModal( false)
        setCode('')
        setIsValid(false)
    }
    const reject = () => {
        setOpenModal( false)
        setCode('')
        setIsValid(false)    
    }
    const approve = () => {
       
        let obj = {
            from: Number(srange),
            to: Number(erange)
        }
        handleOk(obj)
    }
    useEffect(() => {
        Modal.setAppElement('body')
    },[])

        return (
            <Fragment>
                <Modal
                    isOpen={openModal}
                    style={customStyles}
                    ariaHideApp={false}
                    className="creatorrequ ca-popu"
                    contentLabel="Example Modal">
                    <div className='ca-popu-header'>
                        <button onClick={closeModal} className="btn btn-outline-primary mb-3 clsbtn">X</button>
                    </div>    
                    
                    <div className="loginpopcls ca-popu-body">
                        <div className="custom-ui text-left p-0 ">                            
                                <div className="form-group mt-3  mb-5 text-center">
                                    <label htmlFor="scode ">Enter your code</label>
                                    <input className="form-control" type="password" value={scode} onChange={handlesecretCode} name="scode" id="scode" placeholder="Enter Code Here" />
                                </div>                               
                                <div className=''>
                                <h5 className="text-center mb-4">Are you sure to do this?</h5>
                                
                                    <div className="custom-btn text-center">
                                        <button onClick={reject} className="btn btn-outline-primary px-3 mr-3">No</button>
                                        <button onClick={approve} className="btn btn-primary px-3" disabled={!isValid}>Yes</button>
                                    </div>   
                                </div>                         
                        </div>
                    </div>
                    

                </Modal>
                
                <div className="smallilac px-3">
                <div className='ca-card mb-3 '>
                    <div className="admintitle"><h2 className="font-weight-bold">Process Block</h2></div>
                </div>
                    <div className="ca-card mb-3">
                        <div className="card-body">

                            <div className="row justify-content-center">
                                <div className="col-md-4" >
                                    <form onSubmit={handleSubmit}>

                                        <div className="form-group">
                                            <label htmlFor="srange" className="process-label">Starting Range</label>
                                            <input className="form-control" type="text" value={srange} onChange={handleChange} name="srange" id="srange" placeholder="Enter Starting Range Here" />
                                            {submitted && !srange && <div className="mandatory">Starting range required</div>}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="erange" className="process-label">Ending Range</label>
                                            <input className="form-control" type="text" value={erange} onChange={handleEndChange} id="erange" name="erange" placeholder="Enter Ending Range Here" />
                                            {submitted && !erange && <div className="mandatory">Ending range required</div>}
                                        </div>

                                        <div className="form-group text-center mt-5">
                                            <button type="submit" className="btn btn-primary px-5">Submit</button>
                                        </div>

                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>



                </div>
            </Fragment>


        )
    
}


export { ProcessBlock }
