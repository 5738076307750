
import React, { Component, Fragment } from 'react'
import { Link, NavLink } from 'react-router-dom'
import './Sidebar.css'

function Sidebar(props) {
      
            const pathname = window.location.pathname
            const { open } = props
            return (
                  <Fragment>
                        <div className={open ? "sidebar showsidebar" : 'sidebar'}>
                              <ul>
                                    <li className={pathname.includes('slots') ? "highLight" : ''}>
                                          <Link to="/creatoradmin/slots"><i className="far fa-map"></i> Slot</Link>
                                    </li>                                    
                                    <li className={pathname.includes('dashboard') ? "highLight" : ''}>
                                          <Link to="/creatoradmin/dashboard"><i className="fas fa-columns" aria-hidden="true"></i> Dashboard</Link>
                                    </li>
                                    <li className={pathname.includes('artslist') ? "highLight" : ''}>
                                          <Link to="/creatoradmin/artslist"><i className="fa fa-image" aria-hidden="true"></i> Arts</Link>
                                    </li>
                                    {/* <li className={pathname.includes('events') ? "highLight" : ''}>
                                          <Link to="/creatoradmin/events"><i className="far fa-calendar-alt" aria-hidden="true"></i> Events <span>(Beta)</span></Link>
                                    </li>
                                    <li className={pathname.includes('eventgalleryList') ? "highLight" : ''}>
                                          <Link to="/creatoradmin/eventgalleryList"><i className="fa fa-file-image-o" aria-hidden="true"></i> Event Gallery <span>(Beta)</span></Link>
                                    </li> */}
                                    <li className={pathname.includes('process') ? "highLight" : ''}>
                                          <Link to="/creatoradmin/processblock"><i className="fa fa-cubes" aria-hidden="true"></i> Process Block</Link>
                                    </li> 
                                    <li className={pathname.includes('attributes') ? "highLight" : ''}>
                                          <Link to="/creatoradmin/attributes"><i className="fa fa-address-book" aria-hidden="true"></i>Attributes</Link>
                                    </li>
                                    <li className={pathname.includes('settings') ? "highLight" : ''}>
                                          <Link to="/creatoradmin/settings"><i className="fa fa-cog" aria-hidden="true"></i>Settings</Link>
                                    </li>
                                    <li className={pathname.includes('gallerylist') ? "highLight" : ''}>
                                          <Link to="/creatoradmin/gallerylist"><i className="fa fa-picture-o" aria-hidden="true"></i>Gallery</Link>
                                    </li>
                                    <li className={pathname.includes('waitlistemail') ? "highLight" : ''}>
                                          <Link to="/creatoradmin/waitlistemail"><i className="fa fa-envelope-o" aria-hidden="true"></i>Waitlist Email</Link>
                                    </li>   
                                    <li className={pathname.includes('purchased') ? "highLight" : ''}>
                                          <Link to="/creatoradmin/purchased-arts"><i class="fa fa-usd" aria-hidden="true"></i>Purchased Arts</Link>
                                    </li>
                                    <li className={pathname.includes('transfer') ? "highLight" : ''}>
                                          <Link to="/creatoradmin/transfer"><i class="fa fa-exchange" aria-hidden="true"></i>Transfer Bears <span>(Beta)</span></Link>
                                    </li>                                
                              </ul>
                        </div>
                  </Fragment>
            )
      
}

export { Sidebar }