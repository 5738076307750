import React, { Component, Fragment } from 'react'
import history from '../helpers/history';
import Img1 from "../Assets/images/sec1_img1.png";

class ComingSoon extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Fragment>
                <div className="cominsoon orangebg">
                    <div className='container text-center'>
                        <div className='row'>
                            <div className='col-md-6 offset-md-3'>
                                <img src={Img1} />
                                <h1>Coming soon</h1>
                                <p>You will have an opportunity to be a part of this first batch of Bears. 1,805 Mints will be dropped in the first batch. Some will be reserved for Giveaways, VIPs, and Community Events.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}


export default ComingSoon;