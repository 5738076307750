
import React, { Component, Fragment, useEffect } from 'react'
import Logo from "../../../Assets/images/logo.png";
import { useNavigate } from 'react-router';
import './Header.css'
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from '../../../store/actions';
import PubSub from 'pubsub-js';

function AdminHeader(props) {
    let navigate = useNavigate();
    let dispatch = useDispatch()

    const user = useSelector(state => state.adminreducer.isLoggedOut)

   const logout = () => {
    PubSub.publish('msg', true);
       dispatch(adminActions.adminLogout())
   }
   useEffect(() => {
       if(user){
           PubSub.publish('msg',false)
           
           navigate('/creatoradmin')
       }
   })
     const {openSidebar} = props
     const opensidebar = () => {
         openSidebar()
     }
        return(
            <Fragment>
                <div className="header">
                <span className='ca-toggle' onClick={opensidebar}><i className="fa fa-bars"></i></span>
                    <a onClick={logout} className="logo"><img src={Logo} alt="" /></a>
                    <div className="header-right d-flex flex-row">                       
                        <a className="p-4 mobhide ca-logout" onClick={logout}><i className="fas fa-sign-out-alt mr-1"></i> <span>Logout</span></a>                        
                    </div>                    
                </div>
            </Fragment>
        )
    
    
}

export {AdminHeader}